import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CurrentTenantService } from 'src/app/Services/admin-currentTenant';
import { SuperAdminServices } from 'src/app/Services/super-admin-services';
import { TenantServices } from 'src/app/Services/tenant-services';
import { UserService } from 'src/app/Services/user-services';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
	selector: 'app-tenant-list',
	templateUrl: './tenant-list.component.html',
	styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit {
	columns: Partial<Column>[] = [
		{
			iconColumn: true,
			elementAttribute: "positionOpenIcon",
			columnWidth: '2%',

		},
		{
			iconColumn: true,
			elementAttribute: "positionDeleteIcon",
			columnWidth: "2%",
		},
		{
			iconColumn: true,
			elementAttribute: "positionEditIcon",
			columnWidth: "2%",
		},
		{
			label: 'Name',
			elementAttribute: 'name',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'Email',
			elementAttribute: 'email',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'Address',
			elementAttribute: 'address',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		}
	];

	tenantUsersColumns: Partial<Column>[] = [
		{
			iconColumn: true,
			elementAttribute: "positionOpenIcon",
			columnWidth: "2%",
		},
		{
			iconColumn: true,
			elementAttribute: "positionDeleteIcon",
			columnWidth: "2%",
		},
		{
			iconColumn: true,
			elementAttribute: "positionEditIcon",
			columnWidth: "2%",
		},
		{
			label: 'User Name',
			elementAttribute: 'userName',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'First Name',
			elementAttribute: 'firstName',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'Last Name',
			elementAttribute: 'lastName',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'Role',
			elementAttribute: 'roleNames',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		},
		{
			label: 'Status',
			elementAttribute: 'isActive',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
			formatFn: (isActive:any) => {
				if (isActive) {
					return "Active";
				} else {
					return "Disabled";
				}
			},
		},
		{
			label: 'Date Added',
			elementAttribute: 'dateAdded',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			formatFn: (date: Date) => {
				let dateString = this.datePipe.transform(date, "MMM d, y");
				if (dateString) {
					return dateString;
				} else {
					return "";
				}
			},
			columnWidth: '200px',
		},
		{
			label: 'Last Logon',
			elementAttribute: 'lastLogon',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			formatFn: (date: Date) => {
				let dateString = this.datePipe.transform(date, "MMM d, y");
				if (dateString) {
					return dateString;
				} else {
					return "Never";
				}
			},
			columnWidth: '200px',
		},
		{
			label: 'Numbers Of Logons',
			elementAttribute: 'numbersOfLogons',
			columnClass: 'matters-TypeWidth',
			isLink: false,
			columnWidth: '200px',
		}
	];

	faStar = faStar;
	faPlus = faPlus;
	heading = 'Tenants';
	subheading = 'Tenants Inside System';
	tenantUsersHeading = 'Tenant Users';
	tenantUsersSubHeading = 'Users Inside the tenant';
	icon = 'pe-7s-document icon-gradient bg-premium-dark';
	customPageSizeOptions = globalConstants.customPageSizeOptions;
	data: any;
	tenantUsersData: any;
	showTenants: boolean = false;
	showTenantUsers: boolean = false;
	isLoading: boolean = false;
	currentTenant: string;
	event: any = {} as any;
	userDetailPopup: boolean = false;
	openConfirmation = false;
	Title = "Cannot Delete Administrator";
	ConfirmationMessage: string;
	constructor(private tenantService: TenantServices, private datePipe: DatePipe,
		private router: Router, private route: ActivatedRoute, private userService: UserService, private currentTenantService: CurrentTenantService,
		private superAdminService: SuperAdminServices) { }

	ngOnInit(): void {
		this.isLoading = true;
		this.currentTenant = this.currentTenantService.currentTenant;
		if (this.currentTenant) {
			this.ShowTenantUsers({ id: this.currentTenant });
		}
		else {
			this.showTenants = true;
			this.tenantService.getAllTenants().subscribe(res => {
				this.data = res;
				this.isLoading = false;
			})
		}
	}
	ShowTenantUsers(event) {
		this.isLoading = true;
		this.showTenants = false;
		this.showTenantUsers = true;
		this.currentTenant = event.id;
		this.tenantService.getAllTenantUsers(this.currentTenant).subscribe(res => {
			this.currentTenantService.currentTenant = this.currentTenant;
			this.tenantUsersData = res;
			this.isLoading = false;
		})
	}

	ShowTenants() {
		this.showTenantUsers = false;
		this.showTenants = true;
		this.tenantUsersData = null;
		if (!this.data) {
			this.isLoading = true;
			this.tenantService.getAllTenants().subscribe(res => {
				this.data = res;
				this.isLoading = false;
			})
		}
	}

	getEditUrl(event: any) {
		this.currentTenantService.currentTenant = this.currentTenant;
		this.router.navigate([`/edit-tenant-user/${event.id}`], {
			relativeTo: this.route,
			queryParams: { tenantId: this.currentTenant },
			queryParamsHandling: 'merge',
		});
	}
	closeUserDetail(event: boolean) {
		this.userDetailPopup = event;
	}
	getTenantEditUrl(event: any) {
		this.router.navigate(["/edit-tenant/" + event.id]);
	}
	showUserDetail(data: any) {
		this.event = data;
		this.closeUserDetail(true);
	}
	onDelete() {
	}

	onReject() {
		this.openConfirmation = false;
	}
	onConfirm(closeBoth) {

	}
	onDeleteUser(event) {
		Swal.fire({
			title: "Are you sure want to remove?",
			text: "You will not be able to recover this file!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, keep it",
		}).then((result) => {
			if (result.value) {
				this.isLoading = true
				this.superAdminService
					.deleteUser(event.id, this.currentTenant)
					.subscribe((response) => {
						if (response && response.success) {
							this.isLoading = false

							Swal.fire("Deleted!", "Selected record has been deleted.", "success");
							this.tenantService.getAllTenantUsers(this.currentTenant).subscribe(res => {
								this.currentTenantService.currentTenant = this.currentTenant;
								this.tenantUsersData = res;
							})
						}
						else if(response && !response.success && response.data === 0){
							this.isLoading = false;
							Swal.fire("Disabled", "User is already disabled for this Tenant", "error");
						}
						else if (response && !response.success && response.data === 1) {
							this.isLoading = false
							this.ConfirmationMessage = response.message;
							this.openConfirmation = true;
						}
					});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your record is safe :)", "error");
			}
		});
	}
}
