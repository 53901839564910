import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "./Services/user-services";
import {
  CognitoUserInterface,
  AuthState,
} from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { LoginServices } from "./Services/LoginServices";
import { TenantServices } from "./Services/tenant-services";
import { ApplicationTimeZone } from "./Models/application-timezone";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "Atlas Web Portal";
  username: string;
  email: string;
  isLoading = false;
  groups: any;
  tenant: any;
  constructor(
    private userServices: UserService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private loginService: LoginServices,
    private tenantServices: TenantServices,
  ) {

  }
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  loggedIn = false;
  signup = false;
  superAdminLogin = false;
  invitePage = false;
  noActiveTenant = false;

  ngOnInit() {
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get("code");
    // this.isLoading = true;
    // if (code == null) {
    //   // If the code parameter is present, use it to sign the user in to Cognito
    // }-
    this.isLoading = true;
    let invite = this.router.url.includes("invite");
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        localStorage.setItem("accessToken", user.signInUserSession.accessToken.jwtToken)
        let userId = localStorage.getItem('userId');
        let urlSignup = this.router.url == ("/Signup");
        let urlSuperAdmin = this.router.url == "/atlasadmin";
        let invite = this.router.url.includes("invite");
        if (!userId && !urlSignup && !urlSuperAdmin && !this.getSuperAdmin && !invite) {
          this.loginService.Login(user.signInUserSession.accessToken.payload['sub']).subscribe((loginResponse) => {
            localStorage.setItem("token", loginResponse.token);
            localStorage.setItem("userId", loginResponse.user.id);
            localStorage.setItem("user", loginResponse.user);
            localStorage.setItem("timeZone", JSON.stringify(loginResponse.timeZone));
            if (loginResponse.tenant.length == 0) {
              this.noActiveTenant = true;
              this.email = loginResponse.user.email;
              this.username = loginResponse.user.name;
              this.isLoading = false;
            }
            else if (loginResponse.isTenant && loginResponse.tenant != null) {
              localStorage.setItem("Name", loginResponse.user.name);
              localStorage.setItem("roles", loginResponse.roles);
              localStorage.setItem("permissionObject", JSON.stringify(loginResponse.permission));
              localStorage.setItem("permission", loginResponse.permission.map(permission => permission.type));
              localStorage.setItem("Tenant", loginResponse.tenant);
              localStorage.setItem("profile", loginResponse.profile);
              this.isLoading = false;
              this.loggedIn = true;
            }
            else {
              this.tenant = loginResponse.tenant;
              localStorage.setItem("AllTenants", JSON.stringify(loginResponse.tenant));
              this.isLoading = false;
            }
          })
        }
        else if (urlSignup) {
          this.signup = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else if (urlSuperAdmin) {
          this.superAdminLogin = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else if (invite) {
          this.invitePage = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else {
          this.loggedIn = true;
          this.isLoading = false;
        }

      })
      .catch((e) => {
        let urlSignup = this.router.url.includes("/Signup");
        let urlSuperAdmin = this.router.url == "/atlasadmin";
        let invite = this.router.url.includes("/invite");
        if (urlSignup) {
          this.signup = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else if (urlSuperAdmin) {
          this.superAdminLogin = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else if (invite) {
          this.invitePage = true;
          this.loggedIn = false;
          this.isLoading = false;
        }
        else {
          Auth.signOut({ global: true });
          localStorage.clear();
          Auth.federatedSignIn()
            .then(() => {
            })
            .catch((error) => {
              console.log("Error signing in: ", error);
            })
        }
        ;
      });
  }
  fetchTenant() {
    this.tenant = this.tenantServices.getAll().subscribe((data) => {
      this.tenant = data;
      this.isLoading = false;
    });
  }
  getUTCTImeZone(): ApplicationTimeZone {
    return {
      alias: "UTC",
      displayName: "(UTC) Coordinated Universal Time",
      offset: 0,
      daylightSavings: false,
      abbreviation: 'UTC',
      zoneId: 'UTC',
      id: 1
    }
  }
  onClickTenant(tenant: any) {
    this.isLoading = true;
    const roleName = localStorage.getItem("roles");
    const TenantSaveDTO =
    {
      Id: tenant.id,
      RoleName: roleName
    };
    this.tenantServices.saveCurrentTenant(TenantSaveDTO).subscribe((data) => {
      localStorage.setItem("permissionObject", JSON.stringify(data.permission));
      localStorage.setItem("permission", data.permission.map(permission => permission.type));
      localStorage.setItem("Tenant", tenant.name);
      localStorage.setItem("profile", data.profile);
      localStorage.setItem("Name", data.user.name);
      localStorage.setItem("roles", data.roles);
      localStorage.setItem("timeZone", JSON.stringify(data.timeZone));
      this.loggedIn = true;
      this.isLoading = false;
    });
  }
  get isTenant() {
    return !!localStorage.getItem("Tenant")
  }
  get getSuperAdmin() {
    let roles = localStorage.getItem("roles");
    if (roles == "SuperAdmin") {
      return true;
    }
    else {
      return false;
    }

  }
}
