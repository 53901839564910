import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalControlTypeService } from 'src/app/Services/internal-Control-Type.service';
import { NotificationService } from 'src/app/Services/notification.service';

@Component({
  selector: 'app-create-error-control-type',
  templateUrl: './create-error-control-type.component.html',
  styleUrls: ['./create-error-control-type.component.sass']
})
export class CreateErrorControlTypeComponent {


  heading = 'Error Types';
  subheading = 'Error Types of Control inside the system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  errorControlTypeForm: UntypedFormGroup;
  errorControlTypeId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private internalControlTypeService: InternalControlTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location
  ) {

    this.errorControlTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]]

    });


    this.route.params.subscribe(qp => {
      let errorControlTypeid = qp.id;
      this.errorControlTypeId = errorControlTypeid;

      if (this.errorControlTypeId) {
        this.internalControlTypeService.get(this.errorControlTypeId).subscribe(data => {
          this.errorControlTypeForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }


  MoveBack() {
    this.locationService.back();
  }

  createErrorControlType() {
    if (this.errorControlTypeForm.invalid) {
      return
    }
    else if (this.errorControlTypeId) {
      this.internalControlTypeService.update({
        name: this.errorControlTypeForm.get('name').value,
        id: this.errorControlTypeId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Error Control Type')
        this.router.navigate(['/Dashboard/Setup/internalControlName']);
      },
        error => {
          this.notify.showError('Error while updating', 'Error Control Type')
          this.router.navigate(['/Dashboard/Setup/internalControlName']);
        }
      )
    }
    else {
      this.internalControlTypeService.create({
        name: this.errorControlTypeForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Error Control Type')
        this.router.navigate(['/Dashboard/Setup/internalControlName'])
      },
        error => {
          this.notify.showError('Error while creating', 'Error Control Type')
          this.router.navigate(['/Dashboard/Setup/internalControlName'])
        }
      )
    }

  }


}
