<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button *ngIf="route | hasPermission:'canCreate'"  type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success"
                routerLink="/Audit/Question">
                <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>
<div class="mt-3 mr-auto mb-3 p-2" style="cursor: pointer;background-color: white;display:inline-block;float:right">
    <span (click)="showfilterfun()">
        <mat-icon>filter_alt</mat-icon>
    </span>
    <span (click)="refresh()">
        <mat-icon>refresh</mat-icon>
    </span>
</div>
<div>
    <app-atlas-table [columns]="columns" [dataSource]="data" [customColumnWidths]="true" [hasDeleteIcon]="true"
        [hasPreviewIcon]="true" [onEdit]="getEditUrl.bind(this)" [onDelete]="onDelete.bind(this)"
        [hasServerPaging]="true" [hasPaginator]="false" [onOpen]="onOpenDetails.bind(this)"
        [pageChange]="pageChangeHandler.bind(this)" [totalItems]="totalItems"
        [customPageSizeOptions]="customPageSizeOptions">



    </app-atlas-table>
</div>
<app-question-details *ngIf="openDetails" [event]="Question" [onClose]="onCloseDetail.bind(this)"
    style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-question-details>
<app-question-filter *ngIf="showfilter" [onClose]="onClose.bind(this)" (addedfileters)="applyFilter($event)">
</app-question-filter>



<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>

<app-question-control-details class="controlDetails" *ngIf="openControlDetails" [event]="controlDetails"
    [onClose]="onCloseDetail.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-question-control-details>