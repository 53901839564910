import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification.service';
import { ScfDomainsService } from 'src/app/Services/scfDomains.service';

@Component({
  selector: "app-create-edit-scf-domains",
  templateUrl: "./create-edit-scf-domains.component.html",

})
export class CreateEditScfDomainsComponent {
  scfDomainForm: UntypedFormGroup;
  scfDomainId: number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private scfdomainService: ScfDomainsService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location
  ) {

    this.scfDomainForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      scfIdentifier: ['', [Validators.required]],
      principalIntent: ['', [Validators.required]],
      spdPrincipal: ['', [Validators.required]]
    });

    this.route.params.subscribe((qp) => {
      let scfDomainid = qp.id;
      this.scfDomainId = scfDomainid;

      if (this.scfDomainId) {
        this.scfdomainService.get(this.scfDomainId).subscribe((data) => {
          this.scfDomainForm.patchValue({
            name: data.name,
            scfIdentifier: data.scfIdentifier,
            principalIntent: data.principalIntent,
            spdPrincipal: data.spdPrincipal,
          });
        });
      }
    });
  }

  MoveBack() {
    this.locationService.back();
  }


  createscfDomain() {
    if (this.scfDomainForm.invalid) {
      return;
    } else if (this.scfDomainId) {
      this.scfdomainService
        .update({
          name: this.scfDomainForm.get("name").value,
          id: this.scfDomainId,
          principalIntent: this.scfDomainForm.get("principalIntent").value,
          scfIdentifier: this.scfDomainForm.get("scfIdentifier").value,
          spdPrincipal: this.scfDomainForm.get("spdPrincipal").value,
        })
        .subscribe(
          (data) => {
            this.notify.showInfo("Updated Successfully", "Scf Domain");
            this.router.navigate(["/Dashboard/Setup/ScfDomains"]);
          },
          (error) => {
            this.notify.showError("Error while updating", "Scf Domain");
            this.router.navigate(["/Dashboard/Setup/ScfDomains"]);
          }
        );
    } else {
      this.scfdomainService
        .create({
          name: this.scfDomainForm.get("name").value,
          principalIntent: this.scfDomainForm.get("principalIntent").value,
          scfIdentifier: this.scfDomainForm.get("scfIdentifier").value,
          spdPrincipal: this.scfDomainForm.get("spdPrincipal").value,
        })
        .subscribe(
          (data) => {
            this.notify.showSuccess("Created Successfully", "Scf Domain");
            this.router.navigate(["/Dashboard/Setup/ScfDomains"]);
          },
          (error) => {
            this.notify.showError("Error while creating", "Scf Domain");
            this.router.navigate(["/Dashboard/Setup/ScfDomains"]);
          }
        );
    }

  }
}
