import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/Services/notification.service";
import { RolesServices } from "src/app/Services/roles-services";

@Component({
  selector: "app-create-permissions",
  templateUrl: "./create-permissions.component.html",
  styleUrls: ["./create-permissions.component.sass"],
})
export class CreatePermissionsComponent  {
  PermissionsForm: UntypedFormGroup;
  roles: [];
  menus: [];
  roleId: string;
  isLoading = false;
  selectedMenu = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private roleServices: RolesServices,
    private notify: NotificationService,
    private locationService: Location
  ) {
    this.PermissionsForm = this.formBuilder.group({
      roleId: ["", [Validators.required]],
    });
    this.isLoading = true;
    this.fetchRoles();
    this.getAllMenus();
  }

  
  MoveBack() {  
    this.locationService.back();
  }

  fetchRoles() {
    this.isLoading = true;
    this.roleServices.getAllRoles().subscribe((data) => {
      this.isLoading = false;
      this.roles = data;
    });
  }
  roleChange(event) {
    this.isLoading = true;
    this.roleServices.getRoleMenu(event.value).subscribe((data) => {
      this.roleId = event.id;
      this.isLoading = false;
      this.selectedMenu = data;
    });
  }
  getAllMenus() {
    this.roleServices.getAllMenus().subscribe((data) => {
      this.menus = data;
    });
  }
   // Function to check if a permission is selected
   isSelected(menuId: string, permission: string): boolean {
    const menu = this.selectedMenu.find(menu => menu.menuId === menuId);
    // if((permission == "canAccess" || permission == "canRead") && menu != null){
      if((permission == "canAccess") && menu != null){
      return true;
    }
    return menu ? menu[permission] : false;
  }
  
  // Function to toggle permission state
  togglePermission(event: any, menuId: string, permission: string): void {
    const checked = event.target.checked;
    const menuIndex = this.selectedMenu.findIndex(menu => menu.menuId === menuId);
    if (menuIndex !== -1) {
      this.selectedMenu[menuIndex][permission] = checked;
    } else {
      // Add new menu with permission if not found
      this.selectedMenu.push({ menuId, [permission]: checked });
    }
  }

  AddPermissions() {
    if (this.selectedMenu.length == 0 && this.PermissionsForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.roleServices
      .saveRoleMenus({
        roleId: this.PermissionsForm.get("roleId").value,
        rolePermissions: this.selectedMenu,
      })
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.notify.showSuccess("Granted Successfully", "Permission");
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Permission");
        }
      );
  }
}
