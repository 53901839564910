export class AuditingControlsInputDTO {
  /**
   *
   */
  constructor() {
    this.AuditingAnswers = [];
  }
  ControlId: string;
  AuditingId: string;
  SuggestedAction: string;
  DocumentationScore: number;
  Observation: string;
  IdentifiedCriticality: number;
  ImplementationScore: number;

  AuditingAnswers: AuditingAnswerDTO[];
}

export class AuditingAnswerDTO {
  ControlId: string;
  AuditingId: string;
  ActualSuggestedAction: string;
  CustomerId: string;
  QuestionId: string;
  Answer: string;
  State: string;
}

export class GetAuditingDataDTO {
  constructor() {
    this.DocIds = [];
  }
  ControlId: string;
  AuditingId: string;
  CustomerId: string;
  DocIds: string[];
}

export class AddQuestionFromAuditModel {
  controlId: string;
  questionId: string;
  documentId: string;
  chapterId: string;
  redirectOption: boolean;
}


export class ControlSortedDTO {
  controlTypes: boolean = false;
  chapters: boolean = false;
  primaryObjectives: boolean = false;
  controlFunctions: boolean = false;
  suggestedControls: boolean = false;
  sCOs: boolean = false;
  scfDomains: boolean = false;
  cisLevels: boolean= false;
  cisAssetTypes: boolean = false;
  controlsViewModel: [] =[];
}


export class AuditingControlAttachment{

  fileId : string =''
  controlId : string =''
  AuditingId : string =''
}