import {
  NgReduxModule,
  NgRedux,
  DevToolsExtension,
} from "@angular-redux/store";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { ChartsModule } from "ng2-charts";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "primeng/api";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuditModule } from "./Audit/Audit.module";
import { InterceptorService } from "./Common/intercepter/intercepter.service";
import { ChartjsComponent } from "./DemoPages/Charts/chartjs/chartjs.component";
import { BarChartComponent } from "./DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component";
import { BubbleChartComponent } from "./DemoPages/Charts/chartjs/examples/bubble-chart/bubble-chart.component";
import { DoughnutChartComponent } from "./DemoPages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component";
import { DynamicChartComponent } from "./DemoPages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component";
import { LineChartComponent } from "./DemoPages/Charts/chartjs/examples/line-chart/line-chart.component";
import { PieChartComponent } from "./DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.component";
import { PolarAreaChartComponent } from "./DemoPages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component";
import { RadarChartComponent } from "./DemoPages/Charts/chartjs/examples/radar-chart/radar-chart.component";
import { ScatterChartComponent } from "./DemoPages/Charts/chartjs/examples/scatter-chart/scatter-chart.component";
import { AccordionsComponent } from "./DemoPages/Components/accordions/accordions.component";
import { CarouselComponent } from "./DemoPages/Components/carousel/carousel.component";
import { ModalsComponent } from "./DemoPages/Components/modals/modals.component";
import { PaginationComponent } from "./DemoPages/Components/pagination/pagination.component";
import { ProgressBarComponent } from "./DemoPages/Components/progress-bar/progress-bar.component";
import { TabsComponent } from "./DemoPages/Components/tabs/tabs.component";
import { TooltipsPopoversComponent } from "./DemoPages/Components/tooltips-popovers/tooltips-popovers.component";
import { AnalyticsComponent } from "./DemoPages/Dashboards/analytics/analytics.component";
import { StandardComponent } from "./DemoPages/Elements/Buttons/standard/standard.component";
import { CardsComponent } from "./DemoPages/Elements/cards/cards.component";
import { DropdownsComponent } from "./DemoPages/Elements/dropdowns/dropdowns.component";
import { IconsComponent } from "./DemoPages/Elements/icons/icons.component";
import { ListGroupsComponent } from "./DemoPages/Elements/list-groups/list-groups.component";
import { TimelineComponent } from "./DemoPages/Elements/timeline/timeline.component";
import { LayoutComponent } from "./DemoPages/Forms/Elements/layout/layout.component";
import { RegularComponent } from "./DemoPages/Tables/regular/regular.component";
import { TablesMainComponent } from "./DemoPages/Tables/tables-main/tables-main.component";
import { ForgotPasswordBoxedComponent } from "./DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component";
import { LoginBoxedComponent } from "./DemoPages/UserPages/login-boxed/login-boxed.component";
import { RegisterBoxedComponent } from "./DemoPages/UserPages/register-boxed/register-boxed.component";
import { ChartBoxes3Component } from "./DemoPages/Widgets/chart-boxes3/chart-boxes3.component";
import { DocumentationModule } from "./documentation/documentation.module";
import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { FooterComponent } from "./Layout/Components/footer/footer.component";
import { SearchBoxComponent } from "./Layout/Components/header/elements/search-box/search-box.component";
import { UserBoxComponent } from "./Layout/Components/header/elements/user-box/user-box.component";
import { HeaderComponent } from "./Layout/Components/header/header.component";
import { PageTitleComponent } from "./Layout/Components/page-title/page-title.component";
import { LogoComponent } from "./Layout/Components/sidebar/elements/logo/logo.component";
import { SidebarComponent } from "./Layout/Components/sidebar/sidebar.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";
import { SettingsModule } from "./settings/settings.module";
import { SetupModule } from "./Setup/setup.module";
import { ArchitectUIState, rootReducer } from "./ThemeOptions/store";
import { ConfigActions } from "./ThemeOptions/store/config.actions";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { TenantComponent } from "./settings/tenant/tenant.component";
import { CreateEditTenantComponent } from "./settings/create-edit-tenant/create-edit-tenant.component";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxCaptchaModule } from 'ngx-captcha';
import { SuperAdminGuard } from "./Guard/superadmin-guard";
import { UserAcceptanceComponent } from "./settings/users/user-acceptance/user-acceptance.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    // LAYOUT
    TenantComponent,
    CreateEditTenantComponent,
    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,
    UserAcceptanceComponent,
    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DynamicChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuditModule,
    DocumentationModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    SharedModule,
    MatProgressSpinnerModule,
    SettingsModule,
    SetupModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

    ChartsModule,

    //Mat-datepicker

    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    // Toaster
    ToastrModule.forRoot(),
    // primeng treetable

    // Rich text editor
    /* configure App with AmplifyAuthenticatorModule */
    AmplifyAuthenticatorModule,
    MatStepperModule,
    // Google Captcha Verification
    NgxCaptchaModule,

  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    ConfigActions,
    DatePipe,
    SuperAdminGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : (f) => f]
    );
  }
}
