<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <!-- <div class="logo-src"></div> -->
    <img src="../../../../assets/images/Atlas_Button.png" width="50" height="50" alt="" />
    <h2 class="ms-2 mt-3">Atlas</h2>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content nav-heading-scroll">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list mt-4">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel> -->
              <ngb-panel id="Menu-123123-fasdfasdfa23432">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link mt-1" [class.active-item]="isActiveDashboard()"
                    (click)="navigate()">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M3.33325 4.16668C3.33325 3.70644 3.70635 3.33334 4.16659 3.33334H15.8333C16.2935 3.33334 16.6666 3.70644 16.6666 4.16668V5.83334C16.6666 6.29358 16.2935 6.66668 15.8333 6.66668H4.16659C3.70635 6.66668 3.33325 6.29358 3.33325 5.83334V4.16668Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M3.33325 10.8333C3.33325 10.3731 3.70635 10 4.16659 10H9.16659C9.62682 10 9.99992 10.3731 9.99992 10.8333V15.8333C9.99992 16.2936 9.62682 16.6667 9.16659 16.6667H4.16659C3.70635 16.6667 3.33325 16.2936 3.33325 15.8333V10.8333Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M13.3333 10.8333C13.3333 10.3731 13.7063 10 14.1666 10H15.8333C16.2935 10 16.6666 10.3731 16.6666 10.8333V15.8333C16.6666 16.2936 16.2935 16.6667 15.8333 16.6667H14.1666C13.7063 16.6667 13.3333 16.2936 13.3333 15.8333V10.8333Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </i>
                    <span class="vsm-title">
                      Dashboard
                    </span>
                  </button>
                </ng-template>
              </ngb-panel>
              <ngb-panel *ngFor="let menu of MenuItems" id="Menu{{menu.menuId}}">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link mt-1 " *ngIf="menu.menus.length > 0 && !menu.route">
                    <i [innerHTML]="sanitizeIcon(menu.icon)">
                    </i>
                    <span class="vsm-title">
                      {{menu.name}}
                    </span>
                    <i class="vsm-arrow-icon">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16658 7.5L9.99992 13.3333L15.8333 7.5" stroke="white" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </i>
                  </button>
                  <button class="vsm-link mt-1 " *ngIf="menu.menus.length == 0 && menu.route" [class.active-item]="isActive(menu.route)"
                    (click)="navigateToLink(menu.route)">
                    <i [innerHTML]="sanitizeIcon(menu.icon)">
                    </i>
                    <span class="vsm-title">
                      {{menu.name}}
                    </span>
                  </button>
                </ng-template>

                <ng-container>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let submenu of menu.menus">
                          <a routerLinkActive="active-item" routerLink="{{submenu.route}}" class="vsm-link"><span
                              class="vsm-title">
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="4" cy="4" r="4" [attr.fill]="getFillColor(submenu.route)" />

                              </svg>
                              <span class="ms-2"> {{submenu.name}}</span>
                            </span></a>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                </ng-container>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>