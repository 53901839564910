import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification.service';
import { RolesServices } from 'src/app/Services/roles-services';

@Component({
  selector: 'app-create-edit-roles',
  templateUrl: './create-edit-roles.component.html',
  styleUrls: ['./create-edit-roles.component.sass']
})
export class CreateEditRolesComponent  {
  roleForm:UntypedFormGroup;
  roleid:number;
  constructor(private formBuilder:UntypedFormBuilder,
    private roleSerivces:RolesServices,
    private router:Router,
    private route:ActivatedRoute,
    private locationService: Location,
    private notify: NotificationService,
    ) {
      this.route.params.subscribe(qp=>{
        let roleId=qp.id;
        this.roleid=roleId;
        this.roleForm = this.formBuilder.group({
          name: ['', [Validators.required]],
        });
        if (roleId) {
              this.roleSerivces.get(roleId).subscribe(data=>{
                this.roleForm.patchValue({
                      name:data.name
                })
              })
        }
      })
   }
  

  MoveBack(){
    this.locationService.back();
  }
  
  sendRole(){
    if (!this.roleForm.invalid) {
      if (this.roleid) {
        this.roleSerivces.update({
          name:this.roleForm.get('name').value,
          id:this.roleid
        }).subscribe(data=>{
          this.notify.showInfo("Updated Successfully", "Role");
          this.router.navigate(['/Settings/Roles'])
        },
        (error) => {
          this.notify.showError("Error while updating", "Role");
          this.router.navigate(['/Settings/Roles']);
        })
      }
      else{
        this.roleSerivces.create({
          name:this.roleForm.get('name').value
        }).subscribe(data=>{
          
          this.notify.showSuccess("Created Successfully", "Role");
          this.router.navigate(['/Settings/Roles'])
        },
        (error) => {
          this.notify.showError("Error while creating", "Role");
          this.router.navigate(['/Settings/Roles']);
        })
      }
    }
    
  }
}
