import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private backButtonUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public title$: Observable<string> = this.titleSubject.asObservable();
  public backButton$: Observable<string> = this.backButtonUrl.asObservable();

  constructor() {}

  /**
   * Sets the custom title and updates the observable.
   * @param newTitle - The new title for the application.
   */
  setTitle(newTitle: string): void {
    this.titleSubject.next(newTitle); // Emit the new title via the observable
  }
  setBackButtonUrl(url: string): void {
    this.backButtonUrl.next(url); // Emit the new title via the observable
  }

  /**
   * Gets the current custom title as an observable for dynamic updates.
   * @returns An observable for the current title.
   */
  getTitleObservable(): Observable<string> {
    return this.title$;
  }
  getBackButtonUrlObservable(): Observable<string> {
    return this.backButton$;
  }
}
