<ng-container (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="onClose(false)">
        <div class="pop-up-box ps-3 pe-3 pb-4" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header  pt-4 ">
                <h1>Question Details</h1>
                <span class="spacer"></span>
                <svg (click)="onClose(false)" width="10" height="10" viewBox="0 0 10 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <hr class="HR">
            <div class="container ps-0 pe-0">
                <div class="row">
                    <div>
                        <label class="label-text">Title</label>
                        <p class="value-text" *ngIf="questionDetails">{{questionDetails.question}}</p>
                    </div>
                    <div>
                        <label class="label-text">Description</label>
                        <p class="value-text" [innerHTML]="description"> </p>
                    </div>
                    <div>
                        <label class="label-text">Predefined Suggested Action</label>
                        <p class="value-text" [innerHTML]="predefinedSuggestAction"> </p>
                    </div>
                </div>
    
                <div class="row" *ngIf="questionDetails">
                    <div>
                        <label class="label-text">CIS Asset Types</label>
                        <p *ngFor="let tag of questionDetails.cisAssetTypeIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div>
                        <label class="label-text">Control Types</label>
                        <p *ngFor="let tag of questionDetails.controlTypeIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div>
                        <label class="label-text">Control Functions</label>
                        <p *ngFor="let tag of questionDetails.controlFunctionIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div class="Col-Lg-12 col-lg-12">
                        <label class="label-text">CIS Levels</label>
                        <p *ngFor="let tag of questionDetails.cisLevelIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div class="Col-Lg-12 col-lg-12">
                        <label class="label-text">Primary Objectives</label>
                        <p *ngFor="let tag of questionDetails.primaryObjectiveIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div class="Col-Lg-12 col-lg-12">
                        <label class="label-text">SCF Domains</label>
                        <p *ngFor="let tag of questionDetails.scfDomainIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div class="Col-Lg-12 col-lg-12">
                        <label class="label-text">Suggested Controls</label>
                        <p *ngFor="let tag of questionDetails.suggestedControlIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                    <div class="Col-Lg-12 col-lg-12">
                        <label class="label-text">Suggested Control Ownership</label>
                        <p *ngFor="let tag of questionDetails.scoIds" class="tag value-text">{{tag.value}}</p>
                    </div>
                </div>
            </div>
            <!-- <button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button> -->
        </div>
    </div>
</ng-container>