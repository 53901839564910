<!-- <div id="file-container" class="row">
    <div *ngIf="!visibleDropBox" style="margin-left: 170px; width: 120px; margin-top: 13px;">
        <button type="button" class="form-control" style="color: black;" mat-raised-button (click)="fileInput.click()">Attach File</button>
        <input hidden (change)="onFileSelected($event)" #fileInput id="file" type="file" multiple>
    </div>
    <div *ngIf="visibleDropBox" style="margin-left: 145px; margin-top: 1%; width: 100%;">
        <form class="file-upload">
        <input type="file" name="dropbox" multiple (change)="onFileSelected($event)">
        <p>Drop your files here</p>
        </form>
    </div>
</div>-->
<div id="file-container" class="row">
    <div >
        <div class="file-container-row">
            <div>
                <ng-content></ng-content>
            </div>
         
        </div>
        <input hidden (change)="onFileSelected($event)" #fileInput id="file" type="file" multiple>
    </div>
    <div style=" width: 100%;">
       
        <form class="file-upload">
            <div style="display: flex; justify-content: center;">
                <button type="button" class="form-control" style="color: black; width:150px;" mat-raised-button
                        (click)="fileInput.click()">
                    Attach File
                </button>
            </div>
            <input type="file" name="dropbox" multiple (change)="onFileSelected($event)">

            <p class="mt-5 file-Upload-p">Drop your files here</p>
        </form>
    </div>
</div>
