import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TenantServices } from "src/app/Services/tenant-services";
import { UserService } from "src/app/Services/user-services";
import { ReCaptcha2Component } from 'ngx-captcha';
import { NotificationService } from "src/app/Services/notification.service";
import { markFormGroupTouched } from "src/app/Helpers/FormHelper";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-edit-tenant",
  templateUrl: "./create-edit-tenant.component.html",
  styleUrls: ["./create-edit-tenant.component.scss"],
})
export class CreateEditTenantComponent implements OnInit {
  passwordFormGroup: UntypedFormGroup;
  emailFormGroup: UntypedFormGroup;
  signupFormGroup: UntypedFormGroup;
  isLoading = false;
  captchaResolved: boolean = false;
  submitted = false;
  userEmail: string;
  displayPasswordValidation: boolean;
  userId: string | null = null;
  token: string | null = null;
  checkPassword: boolean;
  password: string;
  cpassword: string;
  showEmailForm: boolean = false;
  showTenantAdminForm: boolean = false;
  showPasswordForm: boolean = false;
  isLinear = true;
  createOrJoin: boolean = false;
  duplicateTenant: boolean = false;
  invitationList: any;
  joinExistingPage: boolean = false;
  dupplicationErrorMessage: any;
  showNamePopup: boolean = false;
  tenantId: string = null;
  invitationId: string = null;
  tenantName: string = null;
  siteKey: string = 'LeEpWUmAAAAAEhSHJdsAPvaZQKAsbTVr6UvB_NN';

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tenantServices: TenantServices,
    private route: ActivatedRoute,
    private locationService: Location,
    private userService: UserService,
    private notify: NotificationService,
    private router: Router,
  ) { }
  ngOnInit() {
    this.passwordFormGroup = this.formBuilder.group({
      confirmPassword: ["", [Validators.required]],
      password: ["", [Validators.required]],
    })
    this.emailFormGroup = this.formBuilder.group({
      userEmail: ["", [Validators.required], [this.emailValidator]],
      recaptcha: ['', Validators.required],
    });
    this.signupFormGroup = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      tenantName: ["", [Validators.required]],
      tenantEmail: ["", [Validators.required], [this.emailValidator]],
    });
    this.route.queryParams.subscribe((qp) => {
      this.userId = qp.userId;
      this.token = qp.token;
      this.isLoading = true;
      if (!this.userId && !this.token) {
        this.isLoading = false;
        this.showEmailForm = true;
      }
      else {
        this.tenantServices
          .userVerification({
            id: this.userId,
            token: this.token
          })
          .subscribe((data) => {
            if (data) {
              this.userEmail = data.email;
              this.userId = data.userId;
              this.invitationList = data.invitationList;
              this.showPasswordForm = true;
              this.isLoading = false;
            }
          }, error => {
            this.notify.showError("User is not existed or failed to verify so Signup again", "Unable to verify");
            this.showEmailForm = true;
            this.router.navigateByUrl("/Signup");
            this.isLoading = false;
          });
      }
    });
  }
  MoveToCreateOrJoin() {
    this.createOrJoin = true;
    this.showPasswordForm = false;
  }
  joinExistingTenant() {
    this.createOrJoin = false;
    this.joinExistingPage = true;
  }
  createNewTenant() {
    this.joinExistingPage = false;
    this.createOrJoin = false;
    this.showTenantAdminForm = true;
  }
  backToJoin() {
    this.joinExistingPage = false;
    this.showTenantAdminForm = false;
    this.createOrJoin = true;
  }
  emailValidator(control: AbstractControl): Promise<any> | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return Promise.resolve({ invalidEmail: true });
    }
    return Promise.resolve(null);
  }

  MoveBack() {
    const logoutUrl = environment.logoutUrl;
    window.location.href = logoutUrl;
  }
  acceptTenant(tenantId?, invitationId?, tenantName?) {
    if (!this.signupFormGroup.get("firstName").value || !this.signupFormGroup.get("firstName").value) {
      this.showNamePopup = true;
      this.invitationId = invitationId;
      this.tenantName = tenantName;
      this.tenantId = tenantId;
      this.signupFormGroup.get('tenantName').clearValidators();
      this.signupFormGroup.get('tenantName').updateValueAndValidity();
      this.signupFormGroup.get('tenantEmail').clearValidators();
      this.signupFormGroup.get('tenantEmail').updateValueAndValidity();
      return
    }
    else {
      this.showNamePopup = false;
    }
    if (this.passwordFormGroup.invalid) {
      this.joinExistingPage = false;
      this.showTenantAdminForm = false;
      this.createOrJoin = false;
      this.showPasswordForm = true;
    }

    this.submitted = true;
    this.isLoading = true;

    this.tenantServices
      .JoinTenant({
        id: tenantId ? tenantId : this.tenantId,
        userEmail: this.userEmail,
        userId: this.userId,
        invitationId: invitationId ? invitationId : this.invitationId,
        firstName: this.signupFormGroup.get("firstName").value,
        lastName: this.signupFormGroup.get("lastName").value,
        password: this.passwordFormGroup.get("password").value,
        tenantName: tenantName ? tenantName : this.tenantName,
      })
      .subscribe((data) => {
        if (data?.message) {
          let errorMessage = data.message.split('.');
          this.errorMessage = errorMessage[0];
          if (this.errorMessage == "User Already existed in tenant") {
            this.duplicateTenant = true;
            this.dupplicationErrorMessage = `You are already part of that tenant, please contact the tenant
              owner and ask the tenant owner to grant you access. When the access is granted you will see
              the tenant’s that you have access to in the right corner at the menu.`
          }
          else if (this.errorMessage == "User exists") {
            this.duplicateTenant = true;
            this.dupplicationErrorMessage = `User with this email is already exits, try to login if you face any problem contact tenant owner.`
          }
          this.isLoading = false;
          this.submitted = false;

        }
        else {
          if (data.invitationList.length > 0) {
            this.isLoading = false;
            this.submitted = false;
            this.notify.showSuccess("You successfly joined the tenant", "Successfuly joined tenant");
            this.invitationList = data.invitationList;
          }
          else {
            this.notify.showSuccess("You successfly joined the tenant", "Successfuly joined tenant");
            const logoutUrl = environment.logoutUrl;
            window.location.href = logoutUrl;
          }
        }
      }, error => {
      });
  }
  errorMessage;
  sucessEmailSent = false;
  submit() {
    this.submitted = true;
    if (this.emailFormGroup.invalid) {
      markFormGroupTouched(this.emailFormGroup)
      this.submitted = false;
    } else {
      this.isLoading = true;
      this.tenantServices
        .signup({
          email: this.emailFormGroup.get("userEmail").value,
        })
        .subscribe((data) => {
          this.notify.showSuccess("Check your email for verification", "User Created");
          this.isLoading = false;
          this.submitted = false;
          this.sucessEmailSent = true;
          this.emailFormGroup.reset();
          // }, 1000);
        }, error => {
          this.notify.showError("Unexpected Error, Try Again Later", "Error");
          this.isLoading = false;
        });
    }
  }
  submitSignup() {
    this.submitted = true;
    if (this.passwordFormGroup.invalid || this.signupFormGroup.invalid) {
      markFormGroupTouched(this.signupFormGroup)
    } else {
      this.isLoading = true;
      this.tenantServices
        .CreateTenant({
          firstName: this.signupFormGroup.get("firstName").value,
          lastName: this.signupFormGroup.get("lastName").value,
          Name: this.signupFormGroup.get("tenantName").value,
          Email: this.signupFormGroup.get("tenantEmail").value,
          userEmail: this.userEmail,
          userId: this.userId,
          password: this.passwordFormGroup.get("password").value,
        })
        .subscribe((data) => {
          if (data && data.message) {
            let errorMessage = data.message.split('.');
            this.errorMessage = errorMessage[0];
            if (this.errorMessage == "Tenant with same name already exists") {
              this.duplicateTenant = true;
              this.dupplicationErrorMessage = `You are already part of that tenant, please contact the tenant
              owner and ask the tenant owner to grant you access. When the access is granted you will see
              the tenant’s that you have access to in the right corner at the menu.`
            }
            this.isLoading = false;
            this.submitted = false;
          }
          else {
            this.isLoading = false;
            const logoutUrl = environment.logoutUrl;
            window.location.href = logoutUrl;

          }
        }, error => {
        });
    }
  }
  checkCaptcha(captchaResponse: string) {
    this.captchaResolved = captchaResponse.length > 0
  }
  setValidation() {
    this.displayPasswordValidation = true;
  }

  // Logic to check if the password meets each bullet point's criteria
  isPasswordLengthValid() {
    const password = this.passwordFormGroup.controls.password.value;
    return password.length >= 14;
  }

  isPasswordSpecialCharactersValid() {
    const password = this.passwordFormGroup.controls.password.value;
    return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
  }

  isPasswordCaseValid() {
    const password = this.passwordFormGroup.controls.password.value;
    return /[a-z]/.test(password) && /[A-Z]/.test(password);
  }

  isPasswordNumericValid() {
    const password = this.passwordFormGroup.controls.password.value;
    return /\d/.test(password);
  } checkValidation(event: any) {
    // Logic to validate the password on focusout
  }

  // Logic to check if all criteria are met for enabling the Next button
  areAllCriteriaMet() {
    return this.isPasswordLengthValid() && this.isPasswordSpecialCharactersValid() &&
      this.isPasswordCaseValid() && this.isPasswordNumericValid() &&
      this.passwordFormGroup.get('password').value === this.passwordFormGroup.get('confirmPassword').value;
  }

  ComparePassword(event) {
    this.cpassword = event.target.value;
    if (this.password !== this.cpassword) {
      this.checkPassword = true;
      return this.notify.showError(
        "",
        "Comfirm Password Does't Match With Password"
      );
    } else {
      this.checkPassword = false;
    }
  }

  showValidation() {
    this.displayPasswordValidation = true;
  }
}