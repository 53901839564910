import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ControlDocumentsServices } from "src/app/Services/control-documents.services";
import { NotificationService } from "src/app/Services/notification.service";
import { CommonFunction } from "src/app/Shared/commen/commen-function";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { globalConstants, AppConstant } from "src/constants/global-constants";
import Swal from "sweetalert2";
import { CategoriesControlDocumentsServices } from "src/app/Services/categories-control-documentsServices";
import { DatePipe } from "@angular/common";
import { DateHelper } from "src/app/Common/Helpers/date.helper";
import { ApplicationTimeZone } from "src/app/Models/application-timezone";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { FilterType } from "src/app/Shared/commen/Enums";
import { FilterService, getEnumKeyByValue } from "src/app/Shared/Services/FilterService";
import { PageEvent } from "@angular/material/paginator";
import { UserTableSettingType } from "src/app/Shared/commen/Enums";
import { applyUserTableSettings } from "src/app/Helpers/UserTableSettingHelper";
import { TitleService } from "src/app/Services/title.service";

@Component({
  selector: "app-control-documents-list",
  templateUrl: "./control-documents-list.component.html",
  providers:[HasPermissionPipe]

})
export class ControlDocumentsListComponent implements OnInit {
  heading = "Control Documents";
  subheading = "Control Documents inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  faPlus = faPlus;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  isLoading = false;
  submitForm = false;
  filterTypeEnum: FilterType = FilterType.ControlDocuments;
  categoriesControlDocumentList = [];
  cisLevelId: any;
  openDetails = false;
  public openControlDetails = false;
  data = [];
  displayData = [];
  activeTab: string = "all";
  addCat: boolean;
  appConstant: any;
  selectedControlDocument: any;
  showRevisionHistory: boolean = false;
  controlDocumentId: any;
  showComparisonPopup: boolean = false;
  columns: Partial<Column>[] = [];
  currentRoute: any;
  totalItems: number;
  settingType: UserTableSettingType = UserTableSettingType.ControlDocuments;
  isTableSettingPopupOpen: boolean = false;
  filterListner: any;
  filterQuery: any = {};
  pageNumber: number = globalConstants.defaultPageNumber;
  searchListner: any;
  statusListner: any;
  favSaveListner: any;
  pageSize: number = globalConstants.defaultPageSize;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private controlDocumentsServices: ControlDocumentsServices,
    private dateHelper: DateHelper,
    private categoriesControlDocumentsServices: CategoriesControlDocumentsServices,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private hasPermissionPipe: HasPermissionPipe,
    private filterService: FilterService,
    private titleService: TitleService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle("Control Documents");
    this.appConstant = AppConstant;
    this.filterListner = this.filterService.ControlDocumentFilterChangeSubject.subscribe(data => {
      if (data) {
        this.filterQuery = data;
        this.onFilter(this.filterQuery)
      }
    })
    this.searchListner = this.filterService.ControlDocumentSearchChangeSubject.subscribe(data => {
      if (data.isChanged) {
        this.filterQuery = { ...this.filterQuery, keyWords: data.keyWords };
        this.onFilter(this.filterQuery)
      }
    })
    this.statusListner = this.filterService.ControlDocumentStatuChangeSubject.subscribe(data => {
      if (data) {
        this.onchangeStatusTab(data)
      }
      if (data == 0) {
        this.onchangeStatusTab(0)
      }   
    })
    this.favSaveListner = this.filterService.SaveFavoriteView.subscribe(data => {
      if (data.name && data.type) {
        this.saveFavoriteView(data.name, data.type);
      }
    })
  }
  saveFavoriteView(name, type) {
    this.filterService.saveFavoriteView(this.filterQuery, name, getEnumKeyByValue(FilterType, FilterType.ControlDocumentsView)).subscribe(data => {
      this.filterService.CloseSignalFavorite.next(true);
    })
  }
  onFilter(filters) {
    this.isLoading = true;
    this.filterQuery.pageNumber = this.pageNumber = globalConstants.defaultPageNumber;
    this.getAll()
    this.onchangeStatusTab(this.filterQuery?.status ? this.filterQuery.status : 0, false);
  }
  // curr = formatNumber(100,this.locale,
  //   '2.1-5')
  ngOnInit(): void {
    this.currentRoute = this.route.snapshot;
    this.initColumns();
    this.columns = applyUserTableSettings(this.columns, UserTableSettingType.ControlDocuments);
    this.fetchDefaultConfigurations();

  }
  getUTCTImeZone(): ApplicationTimeZone {
    return {
      alias: "UTC",
      displayName: "(UTC) Coordinated Universal Time",
      offset: 0,
      daylightSavings: false,
      abbreviation: 'UTC',
      zoneId: 'UTC',
      id: 1
    }
  }
  favoriteViews = [];
  ngOnDestroy() {
    if (this.filterListner) {
      this.filterListner.unsubscribe();
    }
    if (this.searchListner) {
      this.searchListner.unsubscribe();
    }
    if (this.statusListner) {
      this.statusListner.unsubscribe();
    }
    if (this.favSaveListner) {
      this.favSaveListner.unsubscribe();
    }
    this.filterService.ControlDocumentFilterChangeSubject.next(null);
    this.filterService.ControlDocumentStatuChangeSubject.next(null);
    this.filterService.ControlDocumentSearchChangeSubject.next({ isChanged: false, keyWords: null });
    this.filterService.SaveFavoriteView.next({ name: null, type: null });
    this.filterService.CloseSignalFavorite.next(false);
    this.filterService.updateFilters.next(null);
    this.filterService.clearFilterData(FilterType.ControlDocuments).subscribe(data => {
    })
    this.titleService.setTitle("");
  }
  fetchDefaultConfigurations() {
    this.isLoading = true;
    this.filterService.getFavoriteViews(FilterType.ControlDocumentsView).subscribe(data => {
      this.favoriteViews = data;
      var foundSelected = this.favoriteViews.find(d => d.isCurrent == true);
      if (foundSelected) {
        this.filterQuery = foundSelected.filters;
        this.filterService.updateFilters.next(this.filterQuery);
        this.filterService.save(this.filterQuery, FilterType[FilterType.ControlDocuments]).subscribe(data => { });
      }
      this.getAllCats();
      this.onchangeStatusTab(this.filterQuery?.status ? this.filterQuery.status : 0, false);
    })
  }
  onchangeStatusTab(statusId: number = 0, applyFilter= true) {
    if (statusId == 0) {
        this.activeTab = "all";
       this.filterQuery = {...this.filterQuery, status: 0}

    }
    else if (statusId == this.appConstant.controlDocumentStatusConstants.Draft) {
      this.activeTab = "draft";
      this.filterQuery = {...this.filterQuery, status: 1}
    } else if (
      statusId == this.appConstant.controlDocumentStatusConstants.Minor
    ) {
      this.activeTab = "minor";
      this.filterQuery = {...this.filterQuery, status: 2}
    } else if (
      statusId == this.appConstant.controlDocumentStatusConstants.Major
    ) {
      this.activeTab = "major";
      this.filterQuery = {...this.filterQuery, status: 3}
    }
    if (applyFilter) {
      this.onFilter(this.filterQuery);
      this.filterService.save(this.filterQuery,FilterType[FilterType.ControlDocuments]).subscribe(data=>{})
    }
  }

  initColumns() {
    this.columns = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
      hideFromTableSettings :true,
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.currentRoute, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hideFromTableSettings :true,
      hidden: !this.hasPermissionPipe.transform(this.currentRoute, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.currentRoute, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hideFromTableSettings :true,
      hidden: !this.hasPermissionPipe.transform(this.currentRoute, 'canUpdate')
    },
    {
      label: "Name",
      elementAttribute: "controlDocumentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Version",
      elementAttribute: "version",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Date/Time",
      elementAttribute: "createdDate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      formatFn: (date: Date) => {

        let dateString = this.datePipe.transform(date, "MMM d, y, h:mm:ss a");
        if (dateString) {
          return dateString;
        } else {
          return "";
        }
      },
    },
    {
      label: "Document Owner",
      elementAttribute: "documentOwner",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Department",
      elementAttribute: "departmentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Updated By",
      elementAttribute: "lastUpdatedBy",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Categorie",
      elementAttribute: "categoriesControlDocumentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Status",
      elementAttribute: "status",
      columnClass: "matters-TypeWidth",
      isLink: false,
      isBadge : true,
      columnWidth: "200px",
    },
    {
      label: "Description",
      elementAttribute: "descriptionHTMLFormate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      isHtml: true,
      columnWidth: "200px",
    },
  ];
}
  getEditUrl(event: any) {
    this.router.navigate([
      "/Documentation/ControlDocumentsCreate/" + event.controlDocumentId,
    ]);
  }
  onCloseDetail(event) {
    this.openDetails = false;
    this.openControlDetails = false;

  }
  
  onClose(event) {
    this.openDetails = false;
    this.showRevisionHistory = false;
    this.showComparisonPopup = false;
    console.log(event)
  }
  onDelete(event) {
    this.cisLevelId = event.controlDocumentId;
    let controlDocumentObj = {
      id: event.controlDocumentId,
      status: event.status,
      version: event.version,
    };
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        // call the delete api here
        this.isLoading = true;
        this.controlDocumentsServices
          .deleteControldocumentAuditTrailByControldocument(controlDocumentObj)
          .subscribe(
            (response) => {
              this.isLoading = false;
              this.getAll();
            },
            (error: any) => {
              //Message
              this.notify.showError(error?.message, "Control Documents");
            }
          );
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
  onOpenDetails(event) {
    this.controlDocumentId = event.controlDocumentId
    this.showRevisionHistory = true
  }
  getAllCats() {
    this.isLoading = true;
    this.categoriesControlDocumentList = [];
    this.categoriesControlDocumentsServices.getAll().subscribe((data) => {
      this.isLoading = false;
      if (data) {
        this.categoriesControlDocumentList = data;
        this.getAll();
      }
    });
  }
  getAll() {
    this.isLoading = true;
    this.filterQuery = {
      ...this.filterQuery,
      pagingParamsDTO: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
    }
    this.controlDocumentsServices.getAllControlDocuments(this.filterQuery).subscribe((res) => {
      this.isLoading = false;
      if (res) {
        this.totalItems = res.count;
        res.data.forEach((element) => {
          element.version = CommonFunction.fixedControlDocumentVersion(
            CommonFunction.getControlDocumentStatusIdByName(element.status),
            element.version
          );
          element.categoriesControlDocumentName =
            this.categoriesControlDocumentList.find(
              (e) =>
                e.id ==
                JSON.parse(element?.newValue)?.CategoriesControlDocumentId
            )?.name ?? "";
        });
        let controlDocuments = [];
        res.data.map(d => {
          d.createdDate = this.dateHelper.getCreatedOnFromUTCToUser(d.createdDate)
          controlDocuments.push(d);
        })
        this.data = controlDocuments;
      }
    });
  }
  pageChangeHandler(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.previousPageIndex < event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.pageIndex + 1;
    } else if (event.previousPageIndex > event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.previousPageIndex;
    }
    this.isLoading = true;
    this.filterQuery = { ...this.filterQuery, pageNumber: this.pageNumber, pageSize: this.pageSize }
    this.getAll();
  }

  tableSettingPopup(): void {
    this.isTableSettingPopupOpen = true;
  }
  onCloseTableSettingPopup(){
    this.isTableSettingPopupOpen = false;
  }
  onSaveSettings(){
    this.columns = applyUserTableSettings(this.columns, UserTableSettingType.ControlDocuments);
  }
}
