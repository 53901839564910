<div>
    <h3 class="pl4 heading-text-fav">Favorite Views</h3>
    <div class="favorite-filters-list">
        <div *ngFor="let filter of favoriteFilters; let i = index" class="ps-4 favorite-filter-item"
            [class.active]="i === activeFilterIndex">
            <span class="filter-text selected" (click)="selectFilter(filter)" *ngIf="filter.isCurrent">{{
                filter.viewName }}</span>
            <span class="filter-text" *ngIf="!filter.isCurrent" (click)="selectFilter(filter)">{{ filter.viewName
                }}</span>
            <span *ngIf="!filter.isCurrent && filter.id" (click)="deleteFilter(filter); $event.stopPropagation()"
                class="material-icons delete-icon">delete</span>
        </div>
    </div>

    <div class="buttons-container-create-new button mt-2">
        <button *ngIf="!addingNewFilter" (click)="$event.stopPropagation(); isSaveOpen = true"
            class="ms-2 me-2 btn btn-table-filters-Apply">
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            Create New</button>
    </div>
</div>
<ng-container *ngIf="isSaveOpen" (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeFilterPopup();">
        <div class="pop-up-box" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header pt-3 pb-2">
                <h1>Filters</h1>
                <span class="spacer"></span>
                <svg (click)="closeFilterPopup()" class="cursor-pointer cross-button" width="10" height="10"
                    viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <hr class="HR">
            <ng-container>
                <div class="mt-2">
                    <mat-form-field appearance="fill" class="form-field-audit" style="width: 100%;">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="View Name" [(ngModel)]="favName" style="width: 100%;">
                    </mat-form-field>

                    <div class="buttons-container  mb-2 mt-3">
                        <button class="btn btn-table-filters-clear" mat-button
                            (click)="$event.stopPropagation(); closeFilterPopup()" [disabled]="loading"
                            type="button">Cancel</button>
                        <button class="btn  btn-table-filters-Apply" mat-button [disabled]="loading" type="button"
                            (click)="$event.stopPropagation(); save()">Save</button>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</ng-container>