import { FilterType } from './Enums';

export const FilterFormConfig = {
  [FilterType.Audits]: {
    status: null,
    startDateTo: null,
    startDateFrom: null,
    endDateTo: null,
    endDateFrom: null,
    documentIds: null,
    customerIds: null
  },
};