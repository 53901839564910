import { Location } from "@angular/common";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NotificationService } from "src/app/Services/notification.service";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TimeZonesServices } from "src/app/Services/timezoneService";
import { UserService } from "src/app/Services/user-services";

@Component({
  selector: "app-timezone",
  templateUrl: "./timezone.component.html",
  styleUrls: ["./timezone.component.scss"],
})
export class TimezoneComponent {
  chooseManually = false;
  Auto = true;
  selectedTimeZone: any = "";
  timezones: any[] = [];
  isLoading = false;
  UserId: any;
  firstName: any;
  lastName: any;
  userDetails: any;
  resetPasswordPopup = false;
  passwordForm: UntypedFormGroup;
  displayPasswordValidation: boolean;
  checkPassword: boolean;
  password: string;
  cpassword: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private timeZoneService: TimeZonesServices,
    private router: Router,
    private locationService: Location,
    private notify: NotificationService,
    private userService: UserService
  ) {
    this.isLoading = true;
    this.timeZoneService.getAll().subscribe((data) => {
      this.timezones = data;
      this.getUserTimeZone();
    });
    this.passwordForm = this.formBuilder.group({
      confirmPassword: ["", [Validators.required]],
      password: ["", [Validators.required]],
      oldPassword: ["", [Validators.required]],
    })
    this.userService.getUserId().subscribe(res => {
      this.UserId = res.id;
      this.userService.getUserFirstAndLastName(this.UserId).subscribe(res => {
        this.userDetails = res;
        this.firstName = res.firstName;
        this.lastName = res.lastName;
        this.isLoading = false;
      },
        () => { this.isLoading = false; })
    },
      () => { this.isLoading = false; })

  }
  getUserTimeZone() {
    this.timeZoneService.get().subscribe((data2) => {
      if (!data2) {
        this.Auto = true;
        this.chooseManually = false;
      } else {
        this.selectedTimeZone = data2.id;
        this.chooseManually = true;
        this.Auto = false;
      }
    });
  }

  MoveBack() {
    this.locationService.back();
  }

  saveTimeZone() {
    let body = {
      autoDetected: this.Auto,
      timezoneId: this.selectedTimeZone,
    };
    this.isLoading = true;
    this.timeZoneService.save(body).subscribe((data) => {
      localStorage.setItem("timeZone", JSON.stringify(data));
      this.isLoading = false;
      this.notify.showSuccess("TimeZone changed Successfully", "Success");
    });
  }

  selection(event) {
    if (event == "auto" && this.Auto) {
      this.Auto = true;
      this.chooseManually = false;
      this.selectedTimeZone = 0;
      this.saveTimeZone();
    } else if (event == "manual" && this.chooseManually) {
      this.chooseManually = true;
      this.Auto = false;
    }
  }
  isAutoDetectChecked(): boolean {
    return true;
  }
  passwordPopup(event) {
    this.resetPasswordPopup = event;
  }
  onSubmit(registrationForm: any) {
    this.isLoading = true;
    if (registrationForm.valid) {
      this.userDetails.firstName = this.firstName;
      this.userDetails.lastName = this.lastName;
      this.userDetails.userId = this.UserId;
      this.userService.UpdateUserFirstAndLastName(this.userDetails).subscribe(res => {
        this.isLoading = false;
        this.notify.showInfo("First Name and Last Name updated successfully", "Updated");
      },
        error => {
          this.isLoading = false;
          this.notify.showError("Failed", "Update")
        })
    } else {
      console.log("Form is invalid. Please fill all required fields.");
    }
  }
  onResetPassword(passwordForm: any) {
    this.isLoading = true;
    if (passwordForm.valid) {
      this.userService.resetPassword({
        confirmPassword: this.passwordForm.get("confirmPassword").value,
        password: this.passwordForm.get("password").value,
        oldPassword: this.passwordForm.get("oldPassword").value,
      }).subscribe(res => {
        this.isLoading = false;
        this.passwordForm.reset();
        this.displayPasswordValidation = false;
        this.passwordPopup(false);
        this.notify.showInfo("Password reset successfully", "Password reset");
      },
        error => {
          this.isLoading = false;
          this.notify.showError("Failed", "Current password is not correct")
        })
    } else {
      console.log("Form is invalid. Please fill all required fields.");
    }
  }
  setValidation() {
    this.displayPasswordValidation = true;
  }

  // Logic to check if the password meets each bullet point's criteria
  isPasswordLengthValid() {
    const password = this.passwordForm.controls.password.value;
    return password.length >= 12;
  }

  isPasswordSpecialCharactersValid() {
    const password = this.passwordForm.controls.password.value;
    return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+/.test(password);
  }

  isPasswordCaseValid() {
    const password = this.passwordForm.controls.password.value;
    return /[a-z]/.test(password) && /[A-Z]/.test(password);
  }

  isPasswordNumericValid() {
    const password = this.passwordForm.controls.password.value;
    return /\d/.test(password);
  } checkValidation(event: any) {
    // Logic to validate the password on focusout
  }

  moveBack() {
    // Logic to move back
  }

  // Logic to check if all criteria are met for enabling the Next button
  areAllCriteriaMet() {
    return this.isPasswordLengthValid() && this.isPasswordSpecialCharactersValid() &&
      this.isPasswordCaseValid() && this.isPasswordNumericValid() && this.passwordForm.get('oldPassword').value !== '' &&
      this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value;
  }

  ComparePassword(event) {
    this.cpassword = event.target.value;
    if (this.password !== this.cpassword) {
      this.checkPassword = true;
      return this.notify.showError(
        "",
        "Comfirm Password Does't Match With Password"
      );
    } else {
      this.checkPassword = false;
    }
  }

  showValidation() {
    this.displayPasswordValidation = true;
  }
}
