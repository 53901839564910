import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlTypeService } from 'src/app/Services/control-type.service';
import { NotificationService } from 'src/app/Services/notification.service';

@Component({
  selector: 'app-create-control-types',
  templateUrl: './create-control-types.component.html',
  styleUrls: ['./create-control-types.component.sass']
})
export class CreateControlTypesComponent {
  heading = 'Control Types';
  subheading = 'Control Types that you will be adding inside system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  controlTypeForm: UntypedFormGroup;
  controlTypeId: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private controlTypeService: ControlTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private locationService: Location,
    private notify: NotificationService) {

    this.controlTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],



    });


    this.route.params.subscribe(qp => {
      let controlTypeId = qp.id;
      this.controlTypeId = controlTypeId;

      if (this.controlTypeId) {
        this.controlTypeService.get(this.controlTypeId).subscribe(data => {
          this.controlTypeForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }

  MoveBack() {
    this.locationService.back();
  }

  createDocumentType() {
    if (this.controlTypeForm.invalid) {
      return
    }
    else if (this.controlTypeId) {
      this.controlTypeService.update({
        name: this.controlTypeForm.get('name').value,
        id: this.controlTypeId,
      }).subscribe(data => {
        this.notify.showInfo('Updated successfully', 'Control Type')
        this.router.navigate(['/Dashboard/Setup/controlTypes']);
      },
        error => {
          this.notify.showError('Error while updateing', 'Control Type')
          this.router.navigate(['/Dashboard/Setup/controlTypes']);
        }
      )
    }
    else {
      this.controlTypeService.create({
        name: this.controlTypeForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created successfully', 'Control Type')
        this.router.navigate(['/Dashboard/Setup/controlTypes'])
      },
        error => {
          this.notify.showError('Error while creating', 'Control Type')
          this.router.navigate(['/Dashboard/Setup/controlTypes']);
        })
    }

  }

}
