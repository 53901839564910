import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CategoriesControlDocumentsServices {
  constructor(private http: HttpClient) {}
  catregoriesBasePath = environment.apiUrl + "CategoriesControlDocument";
  getAll(): any {
    return this.http.get<any>(this.catregoriesBasePath + "/GetAll");
  }
 
  delete(Id): any {
    return this.http.post<any>(this.catregoriesBasePath + "/Delete/" + Id, {});
  }
  get(id): any {
    return this.http.get<any>(this.catregoriesBasePath + "/Get/" + id);
  }

  create(body): any {
    return this.http.post<any>(this.catregoriesBasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.catregoriesBasePath + "/Update", body);
  }
}
