<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Tenant</h5>
                <form [formGroup]="signupFormGroup">
                    <div class="position-relative mb-3">
                        <label for="tenantName" class="form-label">Tenant Name</label>
                        <input name="tenantName" id="tenantName" formControlName="tenantName" type="text"
                            placeholder="Tenant Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('tenantName').invalid &&
                                    signupFormGroup.get('tenantName').touched }" required>
                    </div>
                    <div class="position-relative mb-3">
                        <label for="tenantEmail" class="form-label">Tenant Email</label>
                        <input name="tenantEmail" id="tenantEmail" formControlName="tenantEmail" type="email"
                            placeholder="Tenant Email" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('tenantEmail').invalid &&
                                signupFormGroup.get('tenantEmail').touched}" required>
                        <small *ngIf="signupFormGroup.get('tenantEmail').hasError('invalidEmail')"
                            class="text text-danger">
                            Please Provide Proper Email As it is Compulsory
                        </small>

                    </div>

                    <div class="button-container">
                        <button class="ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()"
                            matStepperPrevious>Cancel</button>
                        <button type="button" class="btn btn-success btn-atlas" [disabled]="isLoading || signupFormGroup.invalid"
                            mat-button (click)="EditTenant()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>