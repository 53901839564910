import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  constructor(private http: HttpClient) {}

  questionbasePath = environment.apiUrl + "Question";

  getAll(body): any {
    return this.http.post<any>(this.questionbasePath + "/GetAll",body);
  }
  deleteLinkedQuestion(Id) {
    return this.http.post<any>(
      this.questionbasePath + "/DeleteLinkedQuesiton/" + Id,
      {}
    );
  }
  getAllDrafted(): any {
    return this.http.get<any>(this.questionbasePath + "/GetAllDrafted");
  }
  drafterdToActive(body): any {
    return this.http.post<any>(this.questionbasePath + "/DraftToActive", body);
  }

  get(id): any {
    return this.http.get<any>(this.questionbasePath + "/Get/" + id);
  }

  create(body): any {
    return this.http.post<any>(this.questionbasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.questionbasePath + "/Update", body);
  }

  delete(id): any {
    return this.http.delete<any>(this.questionbasePath + "/Delete/" + id);
  }

  getQuestionId() {
    return this.http.post<any>(this.questionbasePath + "/Draft", 0);
  }

  linkQuestion(body) {
    return this.http.post<any>(
      this.questionbasePath + "/CreateQuestionLinked",
      body
    );
  }
  deleteLinkQuestion(body) {
    return this.http.post<any>(
      this.questionbasePath + "/DeleteQuestionLinked",
      body
    );
  }

  ApplyFilters(questionfilters: any): any {
    return this.http.post<any>(
      this.questionbasePath + "/ApplyFilters",
      questionfilters
    );
  }
}
