<div [ngClass]="{
	'marginLeft': addMargin && !globals.toggleSidebar}
" [class]="'Pop-Up-Box pop-up-box'">
	<div class="Pop-Up-Header">
		<span (click)="onClose(false)" class="material-icons">highlight_off</span>
		<h1>Audit Details</h1>
		<!-- <div class="Pop-Up-Edit-Button">
			<button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button>
		</div> -->
	</div>

	<hr class="HR">

	<div class="container">
        <div class="Col-Lg-12 col-lg-12">
			<h4><strong>Audit</strong></h4>
			<p>{{auditDetails.name}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>Customer</strong></h4>
			<p>{{auditDetails.customer}}</p>
		</div>
		
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>Documents</strong></h4>
            <p *ngFor="let doc of auditDetails.document" class="tag">{{doc}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>Status</strong></h4>
			<p>{{auditDetails.status}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>Progress</strong></h4>
			<p>{{auditDetails.progress}}</p>

		</div>
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>Start Date</strong></h4>
			<p>{{auditDetails.startDate}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
			<h4><strong>End Date</strong></h4>
			<p>{{auditDetails.endDate}}</p>

		</div>
	</div>
</div>