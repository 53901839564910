// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


 export const environment = {
   production: false,
   clientId: '4i9lq45h34011vp7j0vi2fb5i2',
   userPoolId: "eu-north-1_d1TPPrCk9",
   domain: "atlaslive.auth.eu-north-1.amazoncognito.com",
   logoutUrl: `https://atlaslive.auth.eu-north-1.amazoncognito.com/logout?client_id=4i9lq45h34011vp7j0vi2fb5i2&logout_uri=https://atlas.kiskane.se`,
   applicationUrl: `https://atlas.kiskane.se`
 };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
