<div class="mb-2 d-flex">
    <div></div>
    <span class="spacer"></span>
    <button *ngIf="route | hasPermission:'canCreate'"  type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Audit/Create-Maturity">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Create New
    </button>
</div>
  <div>
    <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                     [onEdit]="getEditUrl.bind(this)"
                  [onDelete]="onDelete.bind(this)"
                        >
    </app-atlas-table>
  </div>