import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "src/app/Services/notification.service";
import { TenantServices } from "src/app/Services/tenant-services";
import { SuperAdminServices } from "src/app/Services/super-admin-services";
import { CurrentTenantService } from "src/app/Services/admin-currentTenant";
@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss']
})
export class CreateTenantComponent implements OnInit {
  tenantId: number;
  signupFormGroup: UntypedFormGroup;
  hasNumeric: boolean = false;
  isLoading = false;
  currentTenant: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location,
    private tenantServices: TenantServices,
    private superAdminService: SuperAdminServices,
    private currentTenantService: CurrentTenantService,
  ) {
    this.route.params.subscribe((qp) => {
      this.tenantId = qp.id;
      this.signupFormGroup = this.formBuilder.group({
        tenantName: ["", [Validators.required]],
        tenantEmail: ["", [Validators.required], [this.emailValidator]],
      });
      if (this.tenantId) {
        this.isLoading = true;
        this.superAdminService.getTenant(this.tenantId).subscribe((data) => {
          this.isLoading = false;
          this.signupFormGroup.patchValue({
            tenantEmail: data.email,
            tenantName: data.name
          });
        });
      }
    });
  }
  ngOnInit(): void {
    console.log('====== CreateTenantComponent =======',)

  }
  MoveBack() {
    this.locationService.back();
    this.currentTenantService.currentTenant = null;
  }
  emailValidator(control: AbstractControl): Promise<any> | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return Promise.resolve({ invalidEmail: true });
    }
    return Promise.resolve(null);
  }

  EditTenant() {
    if (this.signupFormGroup.invalid) {
      return
    }
    this.isLoading = true;
    if (this.tenantId) {
      this.tenantServices
        .update({
          email: this.signupFormGroup.get("tenantEmail").value,
          id: this.tenantId,
          name: this.signupFormGroup.get("tenantName").value
        })
        .subscribe((data) => {
          this.notify.showInfo("Updated Successfully", "Tenant");
          this.isLoading = false;

          this.locationService.back();
        },
          (error) => {
            this.notify.showError("Error while updating", "Tenant");
          });
    }
    else {
      this.superAdminService
        .createTenant({
          email: this.signupFormGroup.get("tenantEmail").value,
          id: this.tenantId,
          name: this.signupFormGroup.get("tenantName").value
        })
        .subscribe((data) => {
          this.notify.showInfo("Created Successfully", "Tenant");
          this.locationService.back();
          this.isLoading = false;

        },
          (error) => {
            this.notify.showError("Error while creating", "Tenant");
          });
    }
  }
}