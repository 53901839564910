import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Amplify } from "aws-amplify";
import aws_exports from "./app/aws-exports";
import { Auth } from "aws-amplify";
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfdXRRRmReUU1xWkI=');

Amplify.configure(aws_exports);

const oauth = {
  domain: environment.domain,
  scope: ["openid", "aws.cognito.signin.user.admin", "profile"],
  redirectSignIn: environment.applicationUrl,
  redirectSignOut: environment.applicationUrl,
  userPoolWebClientId: environment.clientId,
  userPoolId: environment.userPoolId,
  region: "eu-north-1",
  responseType: "code",
  Options: {
    AdvancedSecurityDataCollectionFlag: false,
  },
};
Auth.configure({
  oauth: oauth,
});
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
