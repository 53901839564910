<!-- <ng-container >
    <app-atlas-table  [columns]="columns" [dataSource]="controls"
    [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
    [hasPreviewIcon]="true"
    [hasDeleteIcon]="false"
    [onOpen]="OpenControlDetails.bind(this)"
        >
</app-atlas-table>
</ng-container> -->

<div class="row" *ngIf="!isLoading">
    <div class=" mb-2">
        <strong class="heading-control">Control's List</strong>
    </div>
    <form [formGroup]="questionsControlFormGroup" class="container ps-4 pe-4">
        <div formArrayName="controlsArray">
            <div class="row" *ngFor="let control of controls let i = index" formGroupName="{{i}}">
                <button class="collapsiblebtn mb-1 mt-2" (click)="collapse(control.id)">
                    <svg class="svg-Width" width="24" height="24" (click)="$event.stopPropagation(); openConctrolDetails(control)" fill="#2D577C" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>details-solid</title> <path d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6ZM19,22H9a1,1,0,0,1,0-2H19a1,1,0,0,1,0,2Zm8-4H9a1,1,0,0,1,0-2H27a1,1,0,0,1,0,2Zm0-4H9a1,1,0,0,1,0-2H27a1,1,0,0,1,0,2Z" class="clr-i-solid clr-i-solid-path-1"></path> <rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect> </g></svg>
                    <span class="controlsHeading ms-2"> {{control.documentName}} - {{control.chapter}} -
                        {{control.title}}
                    </span>
                    <span class="spacer"></span>

                    <!-- <mat-icon class="arrowIcon" style="font-size: 16px;"
                                        (click)="$event.stopPropagation(); openConctrolDetails(control)">open_in_new
                                    </mat-icon> -->
                    <svg class="svg-Width" *ngIf="getArrowIcon(control.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.6485 15.2486C17.1799 15.7173 16.4201 15.7173 15.9515 15.2486L12 11.2972L8.04853 15.2486C7.5799 15.7173 6.8201 15.7173 6.35147 15.2486C5.88284 14.78 5.88284 14.0202 6.35147 13.5516L11.1515 8.75157C11.6201 8.28294 12.3799 8.28294 12.8485 8.75157L17.6485 13.5516C18.1172 14.0202 18.1172 14.78 17.6485 15.2486Z"
                            fill="#2D577C" />
                    </svg>
                    <svg class="svg-Width" *ngIf="!getArrowIcon(control.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.6485 8.75137C17.1799 8.28275 16.4201 8.28275 15.9515 8.75137L12 12.7028L8.04853 8.75137C7.5799 8.28275 6.8201 8.28275 6.35147 8.75137C5.88284 9.22 5.88284 9.9798 6.35147 10.4484L11.1515 15.2484C11.6201 15.7171 12.3799 15.7171 12.8485 15.2484L17.6485 10.4484C18.1172 9.9798 18.1172 9.22 17.6485 8.75137Z"
                            fill="#2D577C" />
                    </svg>
                </button>
                <div class="collapsible" [id]="control.id">
                    <input formControlName="controlId" hidden>
                    <mat-tab-group>
                        <mat-tab label="Control Information"> 
                            <div class="mt-3">
                                <p class="controlsHeading">Control Information</p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Chapter</label>
                                <p class="information-text">{{control.chapter}}</p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Chapter Description</label>
                                <p class="mb-0 information-text" [innerHTML]="control.chapterDescription"></p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Document</label>
                                <p class="information-text">{{control.document}}</p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">SCF Domain</label>
                                <p class="information-text">{{''+ getScfDomain(control.scfDomainIds)}}</p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Description</label>
                                <p class="mb-0 information-text" [innerHTML]="control.description"></p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Objective</label>
                                <p class="mb-0 information-text" [innerHTML]="control.objective"></p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Auditing Guidelines</label>
                                <p class="mb-0 information-text" [innerHTML]="control.auditingGuideLines"></p>
                            </div>
                            <div class="d-flex pt-3 pb-2 information-box">
                                <label for="" class="information-label">Other Information</label>
                                <p class="mb-0 information-text" [innerHTML]="control.otherInformation"></p>
                            </div>
                        </mat-tab>
                        <mat-tab label="Questions">
                            <div class="d-flex mt-3">
                                <span class="audit-label">Questions</span>
                                <span class="spacer"></span>
                                <div  *ngIf="!auditingStatus">
                                    <button class="btn btn-audit" (click)="openAddQuestionPopUp(i)"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" fill="#2D577C"/>
                                      </svg> Add Question</button>
                                </div>
                            </div>
                         
                            <div formArrayName="questions">
                                <div class="row mb-2 pb-2 ms-2 me-2 mt-3 pt-3 question-box" *ngFor="let question of control.questionsViewModels let j=index"
                                    formGroupName="{{j}}">
                                    <div class="d-flex">
                                        <span class="audit-question-label">Q# {{j}}. {{question.question}} </span>
                                        <input formControlName="questionId" name="questionId" id="questionId" hidden>
                                        <span class="spacer"></span>
                                        <i (click)="ShowQuestionDetails(question.id)">
                                            <mat-icon class="detail-icons me-2" matTooltip="Question Details">reorder</mat-icon>
                                        </i>
                                        <div *ngIf="!auditingStatus" class="fill-edit-icon" (click)="openEditQuestionPopUp(i,j)">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_76_2000)">
                                                    <path
                                                        d="M15.2353 0.765303C14.7821 0.312767 14.1678 0.0585938 13.5273 0.0585938C12.8869 0.0585938 12.2726 0.312767 11.8193 0.765303L0.976677 11.608C0.666178 11.9167 0.419985 12.284 0.252342 12.6885C0.0846994 13.093 -0.00106532 13.5268 9.98748e-06 13.9646V15.3333C9.98748e-06 15.5101 0.0702479 15.6797 0.195272 15.8047C0.320296 15.9297 0.489866 16 0.666677 16H2.03534C2.47319 16.0012 2.90692 15.9156 3.31145 15.748C3.71597 15.5805 4.08325 15.3344 4.39201 15.024L15.2353 4.18064C15.6877 3.72743 15.9417 3.11328 15.9417 2.47297C15.9417 1.83266 15.6877 1.21851 15.2353 0.765303ZM3.44934 14.0813C3.07335 14.4548 2.56532 14.6651 2.03534 14.6666H1.33334V13.9646C1.33267 13.7019 1.38411 13.4417 1.4847 13.1989C1.58529 12.9562 1.73302 12.7359 1.91934 12.5506L10.148 4.32197L11.6813 5.8553L3.44934 14.0813ZM14.292 3.23797L12.6213 4.9093L11.088 3.3793L12.7593 1.70797C12.86 1.60751 12.9795 1.52786 13.111 1.47358C13.2424 1.41929 13.3833 1.39143 13.5255 1.39158C13.6678 1.39174 13.8086 1.41991 13.9399 1.47448C14.0712 1.52905 14.1905 1.60896 14.291 1.70964C14.3915 1.81032 14.4711 1.9298 14.5254 2.06126C14.5797 2.19272 14.6076 2.33359 14.6074 2.47581C14.6072 2.61804 14.5791 2.75885 14.5245 2.89019C14.4699 3.02153 14.39 3.14084 14.2893 3.2413L14.292 3.23797Z"
                                                        fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_76_2000">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-12">
                                            <textarea (keyup)="autoGrowTextZone($event)" class="form-control answer-input" placeholder="Write Answer"
                                                name="Answer" formControlName="questionAnswer" rows="3"></textarea>
                                    </div>
                                    <div class="d-flex mt-3 audit-radio-box">
                                        <span class="radio-label">State</span>
                                        <mat-radio-group formControlName="state" class="ms-5">
                                            <mat-radio-button  class="ms-5" value="Active">Active</mat-radio-button>
                                            <mat-radio-button  class="ms-5" value="NotAvailable">N/A</mat-radio-button>
                                            <mat-radio-button  class="ms-5" value="Done">Done</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="mt-3" *ngIf="question.predefinedSuggestAction">
                                        <span class="audit-label">Predefined Suggested Actions</span>
                                        <div class="position-relative  col-md-12 mt-2">
                                            <p class="card-text" [innerHTML]="question.predefinedSuggestAction"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                  
                        </mat-tab>
                        <mat-tab label="Author Feedback">
                            <ng-template matTabContent >
                                <div class="audit-label mt-3">Author Feedback</div>
                                <div class="d-flex mt-3 audit-radio-box">
                                    <span class="radio-label authorlabel">Documentation Score</span>
                                    <mat-radio-group formControlName="documentScoure" class="ms-5">
                                        <mat-radio-button  class="ms-5" [value]="-1">None</mat-radio-button>
                                        <mat-radio-button *ngFor="let score of scoreList"  class="ms-5" [value]="score.value">
                                            {{score.value + ' ('+ score.name + ')'}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="d-flex mt-3 audit-radio-box">
                                    <span class="radio-label authorlabel">Implementation Score</span>
                                    <mat-radio-group formControlName="implementationScore" class="ms-5">
                                        <mat-radio-button  class="ms-5" [value]="-1">None</mat-radio-button>
                                        <mat-radio-button *ngFor="let score of scoreList"  class="ms-5" [value]="score.value">
                                            {{score.value + ' ('+ score.name + ')'}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="d-flex mt-3 audit-radio-box">
                                    <span class="radio-label authorlabel">Identified Criticality</span>
                                    <mat-radio-group formControlName="identifiedCriticality" class="ms-5">
                                        <mat-radio-button *ngFor="let score of identifiedScoreList"  class="ms-5" [value]="score.value">
                                            {{score}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <p class="text-center col-sm-12 col-md-6 col-lg-6 mt-5">
                                        <app-atlas-files-list [filesList]="control.attachments" [visible]="true"
                                        [visibleView]="false" [visibleDownload]="true" [visibleLink]="false"
                                        [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
                                        [isDeletable]="isDeletable.bind(this)" [formGroupData]="documentForm"
                                        (click)="bindControlId(control.id)"></app-atlas-files-list>
                                        <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"
                                            (click)="bindControlId(control.id)"></app-atlas-file-upload>
                                    </p>
                                    <div class="col-md-3"></div>
                                </div>
                                
                                <div class="row scoureArea mb-2 p-2">
                                    <!-- <div class=" col-md-3">
                                        <mat-form-field appearance="fill" style="width: 100%;">
                                            <mat-label>Identified Criticality</mat-label>
                                            <mat-select formControlName="identifiedCriticality">
                                                <mat-option *ngFor="let score of identifiedScoreList" [value]="score">
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
             -->
                                    <div class="col-md-3">
           
                                    </div>
                                </div>
                                <div class="row scoureArea mb-2">
                                    <div class="col-md-12">
                                        <div class="position-relative mb-3 col-md-12">
                                            <mat-label class="audit-label mb-2">Observations</mat-label>
                                            <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
                                                [descriptionHTMLFormate]="getFormControlValues('observation', i)"
                                                [onChange]="predefinedObservationActionChange.bind(this, 'observation', i)"
                                                [placeholder]="'Observations'">
                                            </app-atlas-html-editor>
                                        </div>
                                    </div>
                                </div>
                                <div class="row scoureArea mb-2">
                                    <div class="col-md-12">
                                        <div class="position-relative mb-3 col-md-12">
                                            <mat-label class="audit-label mb-2">Suggested Action</mat-label>
                                            <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
                                                [descriptionHTMLFormate]="getFormControlValues('suggestedAction', i)"
                                                [onChange]="predefinedObservationActionChange.bind(this, 'suggestedAction', i)"
                                                [placeholder]="'Predefined Suggest Action'">
                                            </app-atlas-html-editor>
                                        </div>
                                    </div>
                                </div>
                              </ng-template>
                           
                        </mat-tab>
                      </mat-tab-group>
                
             
                    <div class="d-flex" *ngIf="!auditingStatus">
                        <span class="spacer"></span>
                            <button class="btn  btn-audit mt-2 mb-2" (click)="save(i)">Save</button>
                    </div>


                </div>
            </div>
        </div>


    </form>
</div>


<div *ngIf="showSpinner" class="login-spinner" style="position: absolute; top: 45vh; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>

<!-- Question details Component -->
<app-question-details *ngIf="openQuestionDetails" [event]="Question" [onClose]="onClose.bind(this)"
    style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-question-details>