<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="p-4"
        style="border: 1px solid lightgray;min-width: 400px;height:100px ; border-radius: 5px;background-color: white;">
        <i class="icon {{icon}}">
          <h1 class="" style="margin-bottom: 0px;padding-bottom: 0px;">
            {{heading}} ({{controlsCount}})
            <div class="page-title-subheading">
            </div>
          </h1>

        </i>
      </div>

    </div>
    <div class="page-title-actions">
      <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="Navigate()">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Create New
      </button>
    </div>
  </div>
</div>

<div class="mt-3 mr-auto mb-3 p-2" style="cursor: pointer;background-color: white;display:inline-block;float:right">
  <span (click)="showfilterfun()">
    <mat-icon>filter_alt</mat-icon>
  </span>
  <span (click)="refresh()">
    <mat-icon>refresh</mat-icon>
  </span>
</div>
<div class="mt-3">

  <ng-container *ngIf=" chapterControlsShow==false ">
    <label>Select Document</label>
    <select id="docu" [formControl]="docu" class="form-control mb-4" (change)="changeDocument($event)">
      <option *ngFor="let doc of documents" value="{{doc.id}}">{{doc.name}}</option>
    </select>
  </ng-container>

</div>


<ng-container>
  <app-atlas-table [columns]="columns" [dataSource]="controls" [customColumnWidths]="true" [hasPreviewIcon]="true"
    [hasDeleteIcon]="true" [onEdit]="getEditUrl.bind(this)" [onOpen]="OpenControlDetails.bind(this)"
    [onDelete]="onDelete.bind(this)" [hasServerPaging]="true" [hasPaginator]="false" [totalItems]="totalItems"
    [customPageSizeOptions]="customPageSizeOptions" [pageChange]="pageChangeHandler.bind(this)">

  </app-atlas-table>
</ng-container>

<div class=" col-md-8">
  <app-app-control-details class="controlDetails" *ngIf="openDetails" [event]="controlDetails"
    [onClose]="onClose.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 10000001; border: 1px solid gray;
    border-radius: 5px;">
  </app-app-control-details>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 100000;">
  <mat-spinner></mat-spinner>
</div>


<!-- component for controls filter path -> Shared/control-filter -->
<app-controls-filter *ngIf="showfilter" [onClose]="onClose.bind(this)" (addedfileters)="applyFilter($event)">
</app-controls-filter>
<!-- <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                        >
  
  
  
    </app-atlas-table>
  </div> -->

<!-- <ng-template #content5 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> Are you sure you want to remove the CIS Asset Type</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="delete()">Yes</button>
  </ng-template>
  <button id="btnDelete" style="display: none;" type="hidden" class="btn btn-primary"  (click)="open(content5)">Launch demo modal</button>
   -->