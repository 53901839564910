export enum FilterType {
    None = 0,
    Audits = 1,
    AuditView = 2,
    ControlDocuments = 3,
    ControlDocumentsView = 4,
    DPA = 5,
    DPAView = 6,
    TPM = 7,
    TPMView = 8,
    Questions = 9,
    QuestionsView = 10,
    ROPA = 11,
    ROPAView = 12,
    DataRight = 13,
    DataRightView = 14,
    IncidentRegistry = 15,
    IncidentRegistryView = 16,
    }

export enum UserTableSettingType {
    None = 0,
    Audits = 1,
    AuditView = 2,
    ControlDocuments = 3,
    ControlDocumentsView = 4,
    DPA = 5, 
    TPM = 6,
    Questions = 7,
    ROPA = 8,
    DataRight = 9,
    IncidentRegistry = 10,

}