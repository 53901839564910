<div class="File-List-Row row" style="margin-top: 10px">
	<span class="{{ 'Download ' + align }}" *ngIf="filesList.length && visible">
		<i class="{{ iconsDisabledClass ? 'material-icons styled-icon bulk-download-btn ' + iconsDisabledClass : 'material-icons styled-icon bulk-download-btn' }}"
		   (click)="downloadAll(filesList)">get_app</i>
		<span id="Download-All">Download All</span>
	</span>

	<div class="d-none">
		<a class="download-link"
		   *ngFor="let file of filesList"
		   [href]="file.link"
		   appDownloadFile
		   (downloadStatus)="showDownloadStatus(file.name, $event)"
		   [fileName]="file.name"></a>
	</div>

	<ng-container style="margin-top: 10px">
		<table class="table table-hover" *ngIf="filesList.length > 0" role="grid" style="width:100%; table-layout:fixed">
			<thead>
				<tr>
					<th roll="gridcell">Name</th>
					<th roll="gridcell">Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let file of filesList">
					<td roll="gridcell">
						<div
							class="File-Name"
							*ngIf="file.isDeleted"
							matTooltip="This file cannot be accessed. It may have been deleted or moved from its original location."
							[matTooltipPosition]="'above'"
						>
							{{ file.name }}
							<mat-icon class="info-icon" style="color: red"
								>info</mat-icon
							>
						</div>
						<div class="File-Name" *ngIf="!file.isDeleted">
							{{ file.name }}
						</div>
					</td>
			
					
					<td roll="gridcell">
						<div class="cont2 Buttons-Container">
							<i
								class="material-icons styled-icon download-btn"
							
								(click)="
									file.isDeleted
										? onInformationDialogOpened()
										: download(file)
								"
								>get_app</i
							>
							<i
								class="material-icons styled-icon link-btn"
								*ngIf="visibleLink"
								(click)="
									file.isDeleted
										? onInformationDialogOpened()
										: view(file)
								"
								matTooltip="Preview" 
								[matTooltipPosition]="'below'"
								>zoom_in</i
							>
							<i
								class="material-icons styled-icon link-btn"
								*ngIf="
									visibleLink && !file.uploaded && file.link
								"
								(click)="
									file.isDeleted
										? onInformationDialogOpened()
										: link(file)
								"
								>link</i
							>
							<i
								class="material-icons styled-icon delete-btn"
								*ngIf="file.id && file.userId? isDeletable(file)  : true
								"
								(click)="delete(file)"
								>delete</i
							>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	
</div>
<!-- 
<app-pop-up [isOpen]="informationDialogOpened">
	<div class="pop-up-box-confirm pop-up-box border">
		<div class="pop-up-header">
			<span
				(click)="onInformationDialogOpenedClosed()"
				class="material-icons"
				>highlight_off</span
			>
			<h1>
				This file cannot be accessed. It may have been deleted or moved
				from its original location.
			</h1>
		</div>
		<hr class="hr" />
		<div class="container">
			<ng-content></ng-content>
			<div class="text-center mt-4">
				<button
					(click)="onInformationDialogOpenedClosed()"
					type="button"
					class="
						btn btn-primary
						yes-remove-user-btn
						mr-3
						FORM-SUBMIT-BUTTON
					"
				>
					Ok
				</button>
			</div>
		</div>
	</div>
</app-pop-up> -->
