import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-atlas-file-upload',
  templateUrl: './atlas-file-upload.component.html',
  styleUrls: ['./atlas-file-upload.component.scss']
})
export class AtlasFileUploadComponent {
  @Input() onFileUpload = (el) => console.log('Open handler not implemented');

  visibleDropBox = false;
  
  ngAfterViewInit() {
    // document.getElementById('file-container').addEventListener("dragenter", () => {
    //   this.visibleDropBox = true;
    // });
    // document.getElementById('file-container').addEventListener("dragleave", (evt) => {
    //   if(!(event.target as any).contains(evt.relatedTarget)) {
    //     this.visibleDropBox = false;
    //   }
    // });
  }

  onFileSelected(event) {
    this.visibleDropBox = false;
    this.onFileUpload(event);
  }
}
