<div class="row">
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Suggested Control Ownership</h5>
          <form [formGroup]="suggestedControlOwnershipForm" (ngSubmit)="createSuggestedControlOwnership()">
            <div class="position-relative mb-3">
              <label for="name" class="form-label">Name</label>
              <input name="name" formControlName="name" id="name" placeholder="Suggested Control" type="text" class="form-control">
            </div>
            <button class="mt-1 btn btn-success" type="submit">Submit</button>
            <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>

          </form>
        </div>
      </div>
    </div>
  </div>