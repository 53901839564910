<div class="Pop-Up-Box pop-up-box">
    <div class="Pop-Up-Header">
      <span (click)="saveQuestionOnClose_Audit();" class="material-icons">highlight_off</span>
      <h1>Add Question</h1>
      <!-- <div class="Pop-Up-Edit-Button" >
              <button class="Edit-Button btn"  (click)="getEditUrl(false)">Edit</button>
          </div> -->
    </div>
  
    <hr class="HR">
  
    <div class="container">
     <app-question [inPopup]="true" (isQuestionCreated)="QuestedCreated($event)" (isClosed)="Closed($event)" [auditId] ="auditId"  (isQuestionEdited)="QuestionEdited($event)" [AddQuestionDataOnPopUp]="AddQuestionPredefinedData"></app-question>

      <div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
        <mat-spinner></mat-spinner>
      </div>
      <!-- <button class="mt-1 btn btn-success btn-atlas btn-atlas" (click)="addQuestion()">Submit</button> -->
      <button class="mt-1 ms-2 btn btn-danger" type="button" style="background-color: red;"
        (click)="saveQuestionOnClose_Audit();">Cancel</button>
    </div>
  </div>