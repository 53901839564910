<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="mb-3 card">
  <div class="card-header-tab card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">
      <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
      Portfolio Performance
    </div>
  </div>
  <div class="g-0 row">
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
          <i class="pe-7s-album text-white opacity-8"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Cash Deposits</div>
          <div class="widget-numbers">1,7M</div>
          <div class="widget-description opacity-8 ">
            <div class="d-inline text-danger pe-1">
              <fa-icon [icon]="faAngleDown"></fa-icon>
              <span class="ps-1">54.1%</span>
            </div>
            less earnings
          </div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-portfolio text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Invested Dividents</div>
          <div class="widget-numbers"><span>9M</span></div>
          <div class="widget-description opacity-8 ">
            Grow Rate:
            <span class="text-info ps-1">
              <fa-icon [icon]="faAngleDown"></fa-icon>
                <span class="ps-1">14.1%</span>
            </span>
          </div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-light text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Capital Gains</div>
          <div class="widget-numbers text-success"><span>$563</span></div>
          <div class="widget-description ">
            Increased by
            <span class="text-warning ps-1">
              <fa-icon [icon]="faAngleUp"></fa-icon>
                <span class="ps-1">7.35%</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center d-block p-3 card-footer">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary btn-lg">
                  <span class="me-2 opacity-7">
                      <i class="icon icon-anim-pulse ion-ios-analytics-outline"></i>
                  </span>
      <span class="me-1">View Complete Report</span>
    </button>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize fw-normal">Company Agents Status</div>
  </div>
  <div class="table-responsive">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Avatar</th>
        <th class="text-center">Name</th>
        <th class="text-center">Company</th>
        <th class="text-center">Status</th>
        <th class="text-center">Due Date</th>
        <th class="text-center">Target Achievement</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#54</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/1.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Juan C. Cargill</a></td>
        <td class="text-center"><a [routerLink]="[]">Micro Electronics</a></td>
        <td class="text-center">
          <div class="badge rounded-pill bg-danger">Canceled</div>
        </td>
        <td class="text-center">
                                        <span class="pe-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pe-2">
                  <div class="widget-numbers fsize-1 text-danger">71%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                         aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#55</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/2.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Johnathan Phelan</a></td>
        <td class="text-center"><a [routerLink]="[]">Hatchworks</a></td>
        <td class="text-center">
          <div class="badge rounded-pill bg-info">On Hold</div>
        </td>
        <td class="text-center">
                                        <span class="pe-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pe-2">
                  <div class="widget-numbers fsize-1 text-warning">54%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="54" aria-valuemin="0"
                         aria-valuemax="100" style="width: 54%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#56</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/3.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Darrell Lowe</a></td>
        <td class="text-center"><a [routerLink]="[]">Riddle Electronics</a></td>
        <td class="text-center">
          <div class="badge rounded-pill bg-warning">In Progress</div>
        </td>
        <td class="text-center">
                                        <span class="pe-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pe-2">
                  <div class="widget-numbers fsize-1 text-success">97%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="97" aria-valuemin="0"
                         aria-valuemax="100" style="width: 97%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#56</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/4.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">George T. Cottrell</a></td>
        <td class="text-center"><a [routerLink]="[]">Pixelcloud</a></td>
        <td class="text-center">
          <div class="badge rounded-pill bg-success">Completed</div>
        </td>
        <td class="text-center">
                                        <span class="pe-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pe-2">
                  <div class="widget-numbers fsize-1 text-info">88%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-info" role="progressbar" aria-valuenow="88" aria-valuemin="0"
                         aria-valuemax="100" style="width: 88%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block p-4 text-center card-footer">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
      <span class="me-2 opacity-7">
          <i class="fa fa-cog fa-spin"></i>
      </span>
      <span class="me-1">View Complete Report</span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-lg-6">
    <div class="mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <i class="header-icon lnr-cloud-download icon-gradient bg-happy-itmeo"> </i>
          Technical Support
        </div>
      </div>
      <div class="p-0 card-body">
        <div class="p-1 slick-slider-sm mx-auto">
          <div class="widget-chart widget-chart2 text-start p-0">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content widget-chart-content-lg pb-0">
                <div class="widget-chart-flex">
                  <div class="widget-title opacity-5 text-muted text-uppercase">Helpdesk Tickets</div>
                </div>
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div>
                      <span class="text-warning">34</span>
                    </div>
                    <div class="widget-title ms-2 font-size-lg fw-normal text-dark">
                      <span class="opacity-5 text-muted ps-2 pe-1">5%</span>
                      increase
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                <div class="d-block">
                  <canvas baseChart
                          class="chart"
                          [datasets]="datasets"
                          [labels]="labels"
                          [colors]="lineChartColors"
                          [options]="options"
                          [chartType]="'line'"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h6 class="text-muted text-uppercase font-size-md opacity-5 ps-3 pe-3 pb-1 fw-normal">Sales
          Progress</h6>
        <ul class="list-group list-group-flush">
          <li class="p-3 bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Total Orders</div>
                    <div class="widget-subheading">Last year expenses</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="widget-numbers text-success">
                      <small>$</small>
                      1896
                    </div>
                  </div>
                </div>
                <div class="widget-progress-wrapper">
                  <div class="progress-bar-sm progress-bar-animated-alt progress">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="43" aria-valuemin="0"
                         aria-valuemax="100" style="width: 43%;"></div>
                  </div>
                  <div class="progress-sub-label">
                    <div class="sub-label-left">YoY Growth</div>
                    <div class="sub-label-right">100%</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
          <i class="header-icon lnr-lighter icon-gradient bg-amy-crisp"> </i>
          Timeline Example
        </div>
      </div>
      <div class="scroll-area-lg">
        <perfect-scrollbar [autoPropagation]="true">
          <div class="p-4">
            <div
              class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
              <div class="dot-danger vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">All Hands Meeting</h4>
                  </div>
                </div>
              </div>
              <div class="dot-warning vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                  </div>
                </div>
              </div>
              <div class="dot-success vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">Build the production release
                      <div class="badge bg-danger ms-2">NEW</div>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="dot-primary vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">
                      Something not important
                    </h4>
                  </div>
                </div>
              </div>
              <div class="dot-warning vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                  </div>
                </div>
              </div>
              <div class="dot-success vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">Build the production release
                      <div class="badge bg-danger ms-2">NEW</div>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="dot-info vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">This dot has an info state</h4>
                  </div>
                </div>
              </div>
              <div class="dot-dark vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">This dot has a dark state</h4>
                  </div>
                </div>
              </div>
              <div class="dot-danger vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">All Hands Meeting</h4>
                  </div>
                </div>
              </div>
              <div class="dot-warning vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                  </div>
                </div>
              </div>
              <div class="dot-success vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">Build the production release
                      <div class="badge bg-danger ms-2">NEW</div>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="dot-primary vertical-timeline-element">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <h4 class="timeline-title">
                      Something not important
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="d-block text-center card-footer">
        <button class="btn-shadow btn-wide btn-pill btn btn-focus">
          View All Messages
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-lg-6">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal"><i
          class="header-icon lnr-database icon-gradient bg-malibu-beach"> </i>Tasks List
        </div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn" ngbDropdown>
          <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
            <fa-icon [icon]="faTh"></fa-icon>
          </button>
          <div ngbDropdownMenu>
            <button type="button" tabindex="0" class="dropdown-item"><i
              class="dropdown-icon lnr-inbox"> </i><span>Menus</span></button>
            <button type="button" tabindex="0" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"> </i><span>Settings</span>
            </button>
            <button type="button" tabindex="0" class="dropdown-item"><i class="dropdown-icon lnr-book"> </i><span>Actions</span>
            </button>
            <div tabindex="-1" class="dropdown-divider"></div>
            <div class="p-1 text-end">
              <button class="me-2 btn-shadow btn-sm btn btn-link">View Details</button>
              <button class="me-2 btn-shadow btn-sm btn btn-primary">Action</button>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-area-lg">
        <perfect-scrollbar [autoPropagation]="true">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="todo-indicator bg-warning"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox12" class="form-check-input">
                       <label class="form-label form-check-label" for="exampleCustomCheckbox12">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">Wash the car
                      <div class="badge bg-danger ms-2">Rejected</div>
                    </div>
                    <div class="widget-subheading">
                      <i>Written by Bob</i>
                    </div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="border-0 btn-transition btn btn-outline-danger">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-focus"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox1" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox1">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">Task with dropdown menu</div>
                    <div class="widget-subheading">
                      <div>By Johnny
                        <div class="badge rounded-pill bg-info ms-2">NEW</div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <div class="d-inline-block" ngbDropdown placement="bottom-right">
                      <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
                        <i class="pe-7s-menu btn-icon-wrapper"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <ul class="nav flex-column">
                          <li class="nav-item-header nav-item">Activity</li>
                          <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link">Chat
                              <div class="ms-auto badge rounded-pill bg-info">8</div>
                            </a>
                          </li>
                          <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link">Recover Password</a>
                          </li>
                          <li class="nav-item-header nav-item">My Account</li>
                          <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link">Settings
                              <div class="ms-auto badge bg-success">New</div>
                            </a>
                          </li>
                          <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link">Messages
                              <div class="ms-auto badge bg-warning">512</div>
                            </a>
                          </li>
                          <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link">Logs</a>
                          </li>
                          <li class="nav-item-divider nav-item"></li>
                          <li class="nav-item-btn nav-item">
                            <button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-primary"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox4" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox4">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">Badge on the right task</div>
                    <div class="widget-subheading">This task has show on hover actions!</div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                  </div>
                  <div class="widget-content-right ms-3">
                    <div class="badge rounded-pill bg-success">Latest Task</div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-info"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox2" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox2">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left me-3">
                    <div class="widget-content-left">
                      <img width="42" class="rounded" src="./assets/images/avatars/1.jpg" alt="">
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">Go grocery shopping</div>
                    <div class="widget-subheading">A short description for this todo item</div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="border-0 btn-transition btn btn-outline-danger">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-warning"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox12" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox12">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">Wash the car
                      <div class="badge bg-danger ms-2">Rejected</div>
                    </div>
                    <div class="widget-subheading"><i>Written by Bob</i></div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="border-0 btn-transition btn btn-outline-danger">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-focus"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox1" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox1">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left">
                    <div class="widget-heading">Task with dropdown menu</div>
                    <div class="widget-subheading">
                      <div>By Johnny
                        <div class="badge rounded-pill bg-info ms-2">NEW</div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <div class="d-inline-block" ngbDropdown placement="bottom-right">
                      <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
                        <i class="pe-7s-menu btn-icon-wrapper"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <ul class="nav flex-column">
                          <li class="nav-item-header nav-item">Activity</li>
                          <li class="nav-item"><a [routerLink]="[]" class="nav-link">Chat
                            <div class="ms-auto badge rounded-pill bg-info">8</div>
                          </a></li>
                          <li class="nav-item"><a [routerLink]="[]" class="nav-link">Recover Password</a></li>
                          <li class="nav-item-header nav-item">My Account</li>
                          <li class="nav-item"><a [routerLink]="[]" class="nav-link">Settings
                            <div class="ms-auto badge bg-success">New</div>
                          </a></li>
                          <li class="nav-item"><a [routerLink]="[]" class="nav-link">Messages
                            <div class="ms-auto badge bg-warning">512</div>
                          </a></li>
                          <li class="nav-item"><a [routerLink]="[]" class="nav-link">Logs</a></li>
                          <li class="nav-item-divider nav-item"></li>
                          <li class="nav-item-btn nav-item">
                            <button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-primary"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox4" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox4">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">Badge on the right task</div>
                    <div class="widget-subheading">This task has show on hover actions!</div>
                  </div>
                  <div class="widget-content-right widget-content-actions">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                  </div>
                  <div class="widget-content-right ms-3">
                    <div class="badge rounded-pill bg-success">Latest Task</div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="todo-indicator bg-success"></div>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left me-2">
                    <div class="custom-checkbox custom-control form-check">
                      <input type="checkbox" id="exampleCustomCheckbox3" class="form-check-input">
                      <label class="form-label form-check-label" for="exampleCustomCheckbox3">&nbsp;</label>
                    </div>
                  </div>
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">Development Task</div>
                    <div class="widget-subheading">Finish Vue ToDo List App</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="badge bg-warning me-2">69</div>
                  </div>
                  <div class="widget-content-right">
                    <button class="border-0 btn-transition btn btn-outline-success">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="border-0 btn-transition btn btn-outline-danger">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>
      <div class="d-block text-end card-footer">
        <button class="me-2 btn btn-link btn-sm">Cancel</button>
        <button class="btn btn-primary">Add Task</button>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6">
    <div class="row">
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-success border-success">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>874</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">sales last month</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-primary border-primary">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>1283</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Sales Income</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-warning border-warning">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>1286</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">last month sales</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-danger border-danger">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>564</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">total revenue</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-info border-info">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>1283</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Sales Income</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-warning border-warning">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>874</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">sales last month</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mb-3">
  <div class="g-0 row">
    <div class="col-md-12 col-lg-4">
      <ul class="list-group list-group-flush">
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Total Orders</div>
                  <div class="widget-subheading">Last year expenses</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-success">1896</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Clients</div>
                  <div class="widget-subheading">Total Clients Profit</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-primary">$12.6k</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-12 col-lg-4">
      <ul class="list-group list-group-flush">
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Followers</div>
                  <div class="widget-subheading">People Interested</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-danger">45,9%</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Products Sold</div>
                  <div class="widget-subheading">Total revenue streams</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-warning">$3M</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-12 col-lg-4">
      <ul class="list-group list-group-flush">
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Total Orders</div>
                  <div class="widget-subheading">Last year expenses</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-success">1896</div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="bg-transparent list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="widget-heading">Clients</div>
                  <div class="widget-subheading">Total Clients Profit</div>
                </div>
                <div class="widget-content-right">
                  <div class="widget-numbers text-primary">$12.6k</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
