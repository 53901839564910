<div class="notification-wrapper">
    <button (click)="toggleNotifications()" class="notification-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5_1011)">
                <path
                    d="M20 8C22.206 8 24 6.206 24 4C24 1.794 22.206 0 20 0C17.794 0 16 1.794 16 4C16 6.206 17.794 8 20 8ZM20 2C21.103 2 22 2.897 22 4C22 5.103 21.103 6 20 6C18.897 6 18 5.103 18 4C18 2.897 18.897 2 20 2ZM21.868 10.301C21.741 9.763 21.193 9.432 20.666 9.556C20.128 9.683 19.795 10.221 19.921 10.758L20.978 15.247C21.162 15.905 21.029 16.595 20.613 17.14C20.194 17.687 19.557 18 18.865 18H5.13504C4.46504 18 3.84104 17.703 3.42304 17.185C3.00604 16.668 2.85004 16.002 2.98404 15.399L5.07804 7.622C5.81804 4.312 8.72004 2.001 12.134 2.001C12.564 2.001 12.993 2.039 13.409 2.115C13.96 2.213 14.473 1.851 14.572 1.309C14.671 0.765 14.309 0.245 13.766 0.146C13.232 0.049 12.683 0 12.133 0C7.77604 0 4.07204 2.955 3.13604 7.144L1.04204 14.92C0.764039 16.164 1.06404 17.447 1.86604 18.441C2.66504 19.432 3.85704 20.001 5.13504 20.001H7.10104C7.56604 22.28 9.58504 24.001 12 24.001C14.415 24.001 16.434 22.28 16.899 20.001H18.864C20.184 20.001 21.401 19.401 22.201 18.355C23.002 17.307 23.258 15.978 22.914 14.75L21.868 10.302V10.301ZM12 22C10.698 22 9.59804 21.161 9.18404 20H14.815C14.401 21.161 13.301 22 11.999 22H12Z"
                    fill="#495057" />
            </g>
            <defs>
                <clipPath id="clip0_5_1011">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <span *ngIf="increaseCount!=0">{{increaseCount}}</span>

    </button>
    <div *ngIf="showNotifications" class="notification-overlay">
        <div class="notification-content">
            <!-- Your notification content here -->
            <button class="close-button" (click)="toggleNotifications()">✖</button>
            <p> <b>Notifications</b> </p>
            <div class="mt-3">
                <ng-container *ngFor="let item of notifications">
                    <div class="d-flex pt-1 pb-2 ps-2 pe-4 notificationBox mt-2" (click)="clickNotifiation(item)">
                        <svg width="35" height="35" viewBox="0 0 24 24" class="notifcationImage" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5_1011)">
                                <path
                                    d="M20 8C22.206 8 24 6.206 24 4C24 1.794 22.206 0 20 0C17.794 0 16 1.794 16 4C16 6.206 17.794 8 20 8ZM20 2C21.103 2 22 2.897 22 4C22 5.103 21.103 6 20 6C18.897 6 18 5.103 18 4C18 2.897 18.897 2 20 2ZM21.868 10.301C21.741 9.763 21.193 9.432 20.666 9.556C20.128 9.683 19.795 10.221 19.921 10.758L20.978 15.247C21.162 15.905 21.029 16.595 20.613 17.14C20.194 17.687 19.557 18 18.865 18H5.13504C4.46504 18 3.84104 17.703 3.42304 17.185C3.00604 16.668 2.85004 16.002 2.98404 15.399L5.07804 7.622C5.81804 4.312 8.72004 2.001 12.134 2.001C12.564 2.001 12.993 2.039 13.409 2.115C13.96 2.213 14.473 1.851 14.572 1.309C14.671 0.765 14.309 0.245 13.766 0.146C13.232 0.049 12.683 0 12.133 0C7.77604 0 4.07204 2.955 3.13604 7.144L1.04204 14.92C0.764039 16.164 1.06404 17.447 1.86604 18.441C2.66504 19.432 3.85704 20.001 5.13504 20.001H7.10104C7.56604 22.28 9.58504 24.001 12 24.001C14.415 24.001 16.434 22.28 16.899 20.001H18.864C20.184 20.001 21.401 19.401 22.201 18.355C23.002 17.307 23.258 15.978 22.914 14.75L21.868 10.302V10.301ZM12 22C10.698 22 9.59804 21.161 9.18404 20H14.815C14.401 21.161 13.301 22 11.999 22H12Z"
                                    fill="#495057" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5_1011">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span class="notifcationText">
                            {{item.content}}
                        </span>
                        <span class="notificationDate">
                            <b>
                                {{item.createdOn}}
                            </b>
                        </span>
                    </div>

                </ng-container>
                <div class="text-center mt-3" *ngIf="hasMoreNotifications && !isLoading">
                    <a href="javascript:void(0)" (click)="loadMoreNotifications()">Load More</a>
                </div>
                <div class="mt-2 notification-loading-spinner" *ngIf="isLoading">
                    <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
                </div>
            </div>
        </div>
    </div>
</div>