import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from "@angular/forms";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileInfo from "src/app/Common/models/files";
import { QuestionsViewModel } from "src/app/Models/Audit.Models";
import {
  AddQuestionFromAuditModel,
  AuditingAnswerDTO,
  AuditingControlAttachment,
  AuditingControlsInputDTO,
} from "src/app/Models/AudutingModel";
import { AuditingService } from "src/app/Services/auditing.service";
import { FileService } from "src/app/Services/file.services";
import { QuestionService } from "src/app/Services/question.service";
import { globalConstants } from "src/constants/global-constants";

const DEFAULT_DURATION = 300;
@Component({
  selector: "app-auditcontrolslist",
  templateUrl: "./auditcontrolslist.component.html",
  styleUrls: ["./auditcontrolslist.component.scss"],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class AuditcontrolslistComponent implements OnInit {
  controls = [];
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  columns: any;
  collapsed = false;
  arrow = "arrow_drop_down";
  arrowStates: Array<{ id: string, isOpen: boolean }> = [];
  questionsControlFormGroup: UntypedFormGroup;
  isLoading: boolean = false;
  identifiedScoreList = [1, 2, 3, 4, 5];
  scoreList: any;
  auditingStatus: boolean = false;
  autoSaveTimer: any;
  tuchSuggestedEditer: number[] = [];
  tuchObservationEditer: number[] = [];
  faPlus = faPlus;
  AudId;
  lblSuggestedAction = "Suggested Action";
  @Input() UpdateQuestion: boolean = false;
  @Input() set auditingId(value) {
    if (value) {
      this.auditingService.GetAuditingStatus(value).subscribe((res) => {
        this.auditingStatus = res;
      });
    }
  }
  @Input() set AuditId(value) {
    if (value) {
      this.AudId = value
    }
  }
  @Output() addQuestion = new EventEmitter<AddQuestionFromAuditModel>();
  @Input()
  openConctrolDetails: (event) => {};
  @Output() auditQuestionAnswers = new EventEmitter<any>();
  Question: QuestionsViewModel;
  openQuestionDetails: boolean = false;
  showSpinner: boolean;
  uploadedFileIds = [];
  uploadedFiles: FileInfo[] = [];
  selectedFile: any;
  controlIdForAttachment: any = null;
  AuditingIdForAttachments: any;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      ['link', 'image'], // link and image, video
    ],
    imageResize: true // for image resize
  };

  auditingControlAttachmentModel: AuditingControlAttachment =
    new AuditingControlAttachment();


  autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 1) + "px";
  }
  @HostListener("document:keydown", ["$event"])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.openQuestionDetails = false;
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private auditingService: AuditingService,
    private questionService: QuestionService,
    private fileServices: FileService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.showSpinner = true;
    this.auditingService.getDocumentationScore().subscribe((res) => {
      this.scoreList = res;
      console.log(res);
      console.log("score list");
    });
    this.autoSaveStartInterval();
  }
  autoSaveStartInterval() {
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
      this.autoSaveTimer = null;
    }
    this.autoSaveTimer = setInterval(() => {
      this.autoSaveAudit();
    }, 900000);
  }
  updateControlsAfterAddingQuestion() {
    this.auditingService.updateControls.subscribe((res) => {
      if (res) {
        console.log("called form audot start 900000", res);
        this.controls = res;
        this.buildForm();
      }
    });
  }

  buildForm = (): void => {
    this.questionsControlFormGroup = null;
    console.log('====== this.questionsControlFormGroup =======', this.questionsControlFormGroup)
    this.questionsControlFormGroup = this.fb.group({
      controlsArray: new UntypedFormArray(
        this.controls.map((control) => {
          return new UntypedFormGroup({
            questions: new UntypedFormArray(
              control.questionsViewModels.map((question) => {
                return new UntypedFormGroup({
                  questionId: new UntypedFormControl(question.id),
                  questionAnswer: new UntypedFormControl(question.answer),
                  state: new UntypedFormControl(question.state),
                  actualSuggestedAction: new UntypedFormControl(
                    question.actualSuggestedAction
                  ),
                });
              })
            ),
            suggestedAction: new UntypedFormControl(control.suggestedAction),
            documentScoure: new UntypedFormControl(control.documentationScore),
            observation: new UntypedFormControl(control.observation),
            identifiedCriticality: new UntypedFormControl(
              control.identifiedCriticality > 0 &&
                control.identifiedCriticality > 6
                ? control.identifiedCriticality
                : 3
            ),
            implementationScore: new UntypedFormControl(control.implementationScore),
            controlId: new UntypedFormControl(control.id),
            documentId: new UntypedFormControl(control.documentId),
            chapterId: new UntypedFormControl(control.chapterId),
          });
        })
      ),
    });


    console.log('=====aaaa= this.questionsControlFormGroup =======', this.questionsControlFormGroup)
    this.isLoading = false;


  };

  @Input() set AuditingId(value) {
    if (value) {
      this.AuditingIdForAttachments = value;
    }
  }
  @Input() set dataSource(value: any) {
    this.showSpinner = true;
    if (value) {
      this.controls = value;
      if (this.arrowStates.length == 0) {
        this.isLoading = true;
        this.auditingService.getAuditPageSettings(this.AudId).subscribe(data => {
          if (data) {
            let arrowStatesCopy = JSON.parse(data.values);
            this.buildForm();
            this.isLoading = false;
            this.arrowStates = arrowStatesCopy;
            this.controls.forEach(control => {
              // Check if the control ID already exists in the array
              const existingControl = this.arrowStates.find(state => state.id === control.id);
              if (existingControl) {
                if (!existingControl.isOpen) {
                  setTimeout(() => {
                    let element = document.getElementById(control.id);
                    if (element.style.display != "" && element.style.display != "block") {
                      element.style.display = "block";
                      this.arrow = "arrow_drop_down";
                    } else {
                      element.style.display = "none";
                      this.arrow = "arrow_right";
                    }
                  }, 200);

                }
                // Add new control to arrowStates with isOpen as false
              }
              // If it already exists, do not modify its isOpen value
            });
          }
          else {
            this.controls.forEach(control => {
              // Check if the control ID already exists in the array
              const existingControl = this.arrowStates.find(state => state.id === control.id);
              if (!existingControl) {
                // Add new control to arrowStates with isOpen as false
                this.arrowStates.push({ id: control.id, isOpen: false });
                setTimeout(() => {
                  let element = document.getElementById(control.id);
                  if (element.style.display != "" && element.style.display != "block") {
                    element.style.display = "block";
                    this.arrow = "arrow_drop_down";
                  } else {
                    element.style.display = "none";
                    this.arrow = "arrow_right";
                  }
                }, 200);
              }
              // If it already exists, do not modify its isOpen value
            });
            this.buildForm();
          }
        })
      }
      else {
        this.controls.forEach(control => {
          // Check if the control ID already exists in the array
          const existingControl = this.arrowStates.find(state => state.id === control.id);
          if (!existingControl) {
            // Add new control to arrowStates with isOpen as false
            this.arrowStates.push({ id: control.id, isOpen: false });
            setTimeout(() => {
              let element = document.getElementById(control.id);
              if (element.style.display != "" && element.style.display != "block") {
                element.style.display = "block";
                this.arrow = "arrow_drop_down";
              } else {
                element.style.display = "none";
                this.arrow = "arrow_right";
              }
            }, 200);
          }
          // If it already exists, do not modify its isOpen value
        });
        this.buildForm();
      }

    }
    this.showSpinner = false;
    this.isLoading = false;
  }
  getArrowIcon(controlId): string {
    const found = this.arrowStates.find(state => state.id === controlId);
    return found?.isOpen ? 'arrow_drop_down' : 'arrow_right';
  }
  close(index) {
    console.log(index);
  }

  save(index, isAutoSave: boolean = false) {
    if (!isAutoSave) this.showSpinner = true;

    let auditingControls = new AuditingControlsInputDTO();
    let valuesArray =
      this.questionsControlFormGroup.controls.controlsArray.value;
    let arr = valuesArray.at(index);
    auditingControls.ControlId = arr.controlId;
    auditingControls.DocumentationScore = arr.documentScoure;
    auditingControls.SuggestedAction = arr.suggestedAction;
    auditingControls.IdentifiedCriticality = arr.identifiedCriticality;
    auditingControls.ImplementationScore = arr.implementationScore;
    auditingControls.Observation = arr.observation;
    arr.questions.map((x) => {
      let auditngAnsers = new AuditingAnswerDTO();
      auditngAnsers.Answer = x.questionAnswer
      auditngAnsers.ActualSuggestedAction = x.actualSuggestedAction
      auditngAnsers.QuestionId = x.questionId
      auditngAnsers.ControlId = arr.controlId
      auditngAnsers.CustomerId = "";
      auditngAnsers.State = x.state;

      auditingControls.AuditingAnswers.push(auditngAnsers);
    });
    this.auditQuestionAnswers.emit({
      value: auditingControls,
      isAutoSave: isAutoSave,
    });
    this.autoSaveStartInterval();
  }
  autoSaveAudit() {
    const questionControls = <UntypedFormArray>(
      this.questionsControlFormGroup.controls["controlsArray"]
    );

    let valuesArray = [];
    valuesArray = this.questionsControlFormGroup.controls.controlsArray.value;
    if (valuesArray && valuesArray.length > 0) {
      valuesArray.forEach((element, index) => {
        let tuchEditer = this.tuchSuggestedEditer.find((e) => e == index);
        let tuchEditerObs = this.tuchObservationEditer.find((e) => e == index);
        let isEdit = questionControls.controls[index].touched;
        if (isEdit || (tuchEditer && tuchEditer >= 0) || (tuchEditerObs && tuchEditerObs >= 0)) {
          this.save(index, true);
        }
      });
    }
  }
  openAddQuestionPopUp(index) {
    let model = new AddQuestionFromAuditModel();
    let valuesArray =
      this.questionsControlFormGroup.controls.controlsArray.value;
    let arr = valuesArray.at(index);
    model.controlId = arr.controlId;
    model.documentId = arr.documentId;
    model.chapterId = arr.chapterId;
    model.redirectOption = false;
    this.addQuestion.emit(model);
  }

  openEditQuestionPopUp(index, qIndex) {
    let model = new AddQuestionFromAuditModel();
    console.log(qIndex);
    let valuesArray =
      this.questionsControlFormGroup.controls.controlsArray.value;
    console.log(valuesArray);
    let arr = valuesArray.at(index);
    console.log(arr);
    model.controlId = arr.controlId;
    model.documentId = arr.documentId;
    model.chapterId = arr.chapterId;
    model.redirectOption = false;
    model.questionId = arr.questions[qIndex].questionId;

    console.log(model);
    this.addQuestion.emit(model);
  }

  OpenControlDetails() {
    alert("this is controls details");
  }
  getScfDomain(data: any) {
    if (data != null) {
      let scfDomainArray = data.map((e) => e.value);
      let scfDomainArrayasString = scfDomainArray.join(", ");
      return scfDomainArrayasString;
    } else {
      return "";
    }
  }
  collapse(id) {
    const found = this.arrowStates.find(state => state.id === id);
    if (found) {
      found.isOpen = !found.isOpen;
    }
    let element = document.getElementById(id);
    if (element.style.display != "" && element.style.display != "block") {
      element.style.display = "block";
      this.arrow = "arrow_drop_down";
    } else {
      element.style.display = "none";
      this.arrow = "arrow_right";
    }
    let valuesObject = {
      values: JSON.stringify(this.arrowStates),
      auditId: this.AudId,
    }
    this.auditingService.createAuditPageSettings(valuesObject).subscribe(data => {

    })
  }

  onClose() {
    this.openQuestionDetails = false;
  }

  onClosAuditCheckChange(): boolean {
    const questionControls = <UntypedFormArray>(
      this.questionsControlFormGroup.controls["controlsArray"]
    );
    let isTuch = questionControls.touched;
    if (
      isTuch ||
      (this.tuchSuggestedEditer && this.tuchSuggestedEditer.length > 0) || (this.tuchObservationEditer && this.tuchObservationEditer.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  }
  ShowQuestionDetails(id) {
    this.questionService.get(id).subscribe((data) => {
      this.Question = data;
      console.log("question details ", data);
    });
    this.openQuestionDetails = true;
  }
  predefinedSuggestAction: string;
  predefinedSuggestActionChange($event, index: number) {
    this.tuchSuggestedEditer.push(index);

  }
  predefinedObservationtAction: string;
  predefinedObservationActionChange(formControlName, index, $event) {
    const formArray = this.questionsControlFormGroup.get('controlsArray') as FormArray;
    formArray.at(index).get(formControlName).setValue($event);
  }
  getFormControlValues(formControlName, index: number) {
    const formArray = this.questionsControlFormGroup.get('controlsArray') as FormArray;
    return formArray.at(index).get(formControlName).value;
  }

  public onCreate(): void {
  }

  CreatepredefinedSuggestAction(event: any) {
    let html = this.predefinedSuggestAction;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }
  CreatepredefinedObservationAction(event: any) {
    let html = this.predefinedObservationtAction;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }

  //#region attachment

  bindControlId(controlId) {
    console.log("-------------");
    console.log(controlId);
    this.controlIdForAttachment = null;
    this.controlIdForAttachment = controlId;
  }
  view(event) {
    console.log("");
  }
  isDeletable(): boolean {
    return true;
  }
  onFileUpload(event: any) {
    const files = event.target.files;
    console.log(event);
    const fileLimit = 50;
    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        return;
      } else {
        this.showSpinner = true;
        this.uploadFile(file);
        console.log(this.auditingControlAttachmentModel);
      }
    }
  }
  uploadFile(file: any) {
    this.fileServices.upload(file).subscribe((data) => {
      file.id = data;
      this.uploadedFiles.push(file);
      this.controls
        .find((x) => x.id == this.controlIdForAttachment)
        .attachments.push(file);
      this.uploadedFileIds.push(data);
      this.auditingControlAttachmentModel.AuditingId =
        this.AuditingIdForAttachments;
      this.auditingControlAttachmentModel.controlId =
        this.controlIdForAttachment;
      this.auditingControlAttachmentModel.fileId = data;
      this.auditingService
        .UploadAuditingControlAttachment(this.auditingControlAttachmentModel)
        .subscribe((res) => { });
      this.showSpinner = false;
    });
  }
  deleteFile(file: FileInfo) {
    this.fileServices
      .DeleteAuditingControlAttachment(file.id)
      .subscribe((res) => {
        this.controls.filter((x) =>
          x.attachments.find((x) => x.id == file.id)
        )[0].attachments = this.controls
          .filter((x) => x.attachments.find((x) => x.id == file.id))[0]
          .attachments.filter((x) => x.id != file.id);
      });
  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  //#endregion
}
