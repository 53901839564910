import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { AnalyticsComponent } from "./DemoPages/Dashboards/analytics/analytics.component";
import { LoginBoxedComponent } from "./DemoPages/UserPages/login-boxed/login-boxed.component";
import { ErrorControlTypesComponent } from "./Setup/error-control-types/error-control-types.component";
import { CreateEditTenantComponent } from "./settings/create-edit-tenant/create-edit-tenant.component";
import { TenantListComponent } from "./settings/tenant/tenant-list/tenant-list.component";
import { SuperAdminGuard } from "./Guard/superadmin-guard";
import { UserAcceptanceComponent } from "./settings/users/user-acceptance/user-acceptance.component";
import { CreateTenantComponent } from "./settings/tenant/create-tenant/create-tenant.component";
import { PermissionType } from "./Enum/permission-type";
import { TenantUserCreateComponent } from "./settings/tenant/tenant-user-create/tenant-user-create.component";
import { PermissionsGuard } from "./Guard/permissions-guard";

const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [PermissionsGuard],
        component: AnalyticsComponent,
        data: {
          extraParameter: "dashboardsMenu",
          permissionType: PermissionType.None,
        },
      },
      {
        path: "Dashboard",
        canActivate: [PermissionsGuard],
        loadChildren: () => import("../app/Setup/setup.module").then((m) => m.SetupModule),
        data: {
          extraParameter: "elementsMenu",
          permissionType: PermissionType.None,
        },

      },
      {
        path: "Tenants",
        component: TenantListComponent,
        canActivate: [SuperAdminGuard],
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "create-tenant-user",
        component: TenantUserCreateComponent,
        canActivate: [SuperAdminGuard],
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "edit-tenant-user/:id",
        component: TenantUserCreateComponent,
        canActivate: [SuperAdminGuard],
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "edit-tenant/:id",
        component: CreateTenantComponent,
        canActivate: [SuperAdminGuard],
        data: { extraParameter: "elementsMenu" }
      },
      {
        path: "create-tenant",
        component: CreateTenantComponent,
        canActivate: [SuperAdminGuard],
        data: { extraParameter: "elementsMenu" }
      },
      {
        path: "Settings",
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "Setup/errorControlName",
        component: ErrorControlTypesComponent,
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "Audit",
        loadChildren: () =>
          import("./Audit/Audit.module").then((m) => m.AuditModule),
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "Setup",
        loadChildren: () =>
          import("./new-setup/new-setup.module").then((m) => m.NewSetupModule),
        data: { extraParameter: "elementsMenu" },
      },
      {
        path: "Documentation",
        loadChildren: () =>
          import("./documentation/documentation.module").then(
            (m) => m.DocumentationModule
          ),
        data: { extraParameter: "elementsMenu" },
      },
    ],
    // redirectTo: "Login",
    // pathMatch: "full",
  },
  {
    path: "Signup",
    component: CreateEditTenantComponent,

  },
  {
    path: "Signup/:id",
    component: CreateEditTenantComponent,

  },
  {
    path: "atlasadmin",
    component: LoginBoxedComponent
  },
  {
    path: "invite/:id",
    component: UserAcceptanceComponent,
  }

];

@NgModule({

  declarations: [

  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
