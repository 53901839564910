import { Component, Input, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-app-popup',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss']
})
export class AppPopupComponent implements OnInit {
  @Input() onClose: (closeboth: boolean) => void = () => {};
  @Input() onConfirm: (closeboth: boolean) => void = () => {};
  @Input() ConfirmationMessage: string = "";
  @Input() Title: string = "";
  @Input() showCloseButton: boolean = false;
  @Input() showConfirmButton: boolean = true;
  @Input() closeButtonText: string = "No";
  @Input() confirmButtonText: string = "Yes";
  addMargin: boolean = false;

  constructor(
    public globals: ThemeOptions,
  ) { }

  ngOnInit(): void {
  }

}
