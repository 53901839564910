<!-- <div class="Pop-Up-Box pop-up-box">
  <div class="Pop-Up-Header">
    <span (click)="onClose(false)" class="material-icons">highlight_off</span>
    <h1>Question Filters</h1>

  </div> -->


    <form>
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Document</mat-label>
            <mat-select (ngModelChange)="applyFilters()" (selectionChange)="loadChapter()" [(ngModel)]="documentId" name="documentId">
              <mat-option [value]="">
                Select Document
              </mat-option>

              <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
                {{doc.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Chapter</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="chaptersIds" name="chaptersIds" multiple style="background-color: none;">
              <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">{{chapter.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- cis asset type -->

      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>CIS Asset Types</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="cisassettypeids" name="cisassettypeids" multiple
              style="background-color: none;">
              <mat-option *ngFor="let cisassettype of cisAssetTypelist" [value]="cisassettype.id">
                {{cisassettype.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- cis levels -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>CIS Levels</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="cislevelids" name="cislevelids" multiple style="background-color: none;">
              <mat-option *ngFor="let cislevel of cisLevellist" [value]="cislevel.id">{{cislevel.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- control functions -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Control Functions</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="controlfunctionids" name="controlfunctionids" multiple
              style="background-color: none;">
              <mat-option *ngFor="let controlfunction of controlFunctionlist" [value]="controlfunction.id">
                {{controlfunction.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- controls type -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Control Types</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="controltypeids" name="controltypeids" multiple>
              <mat-option *ngFor="let control of controlTypelist" [value]="control.id">{{control.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- primary objectives -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Primary Objectives</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="primaryobjectiveids" name="primaryobjectiveids" multiple
              style="background-color: none;">
              <mat-option *ngFor="let primaryobjective of primaryObjectiveslist" [value]="primaryobjective.id">
                {{primaryobjective.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!-- scf domains -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>SCF Domains</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="scfdomainids" name="scfdomainids" multiple style="background-color: none;">
              <mat-option *ngFor="let scfdomain of scfdomainslist" [value]="scfdomain.id">{{scfdomain.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- suggested controls -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Suggested Controls</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="suggestedcontrolids" name="suggestedcontrolids" multiple
              style="background-color: none;">
              <mat-option *ngFor="let suggestedcontrol of suggestedControllist" [value]="suggestedcontrol.id">
                {{suggestedcontrol.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!--suggested controls ownership  -->
      <div class="row">
        <div class="position-relative mb-3 col-md-12">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Suggested Controls Ownership</mat-label>
            <mat-select (ngModelChange)="applyFilters()" [(ngModel)]="suggestedcontrolownershipids" name="suggestedcontrolownershipids" multiple
              style="background-color: none;">
              <mat-option *ngFor="let sco of suggestedControlOwnershiplist" [value]="sco.id">{{sco.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


    </form>
    <div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 15%; left: 45%;z-index: 100000001;">
      <mat-spinner></mat-spinner>
    </div>
    <!-- <button class="mt-1 btn btn-success btn-atlas btn-atlas" (click)="applyFilters()">Submit</button>
    <button class="mt-1 ms-2 btn btn-success btn-atlas btn-atlas" (click)="resetFilters()">Reset</button>
    <button class="mt-1 ms-2 btn btn-danger" type="button" style="background-color: red;"
      (click)="onClose(false)">Cancel</button> -->
<!-- </div> -->