import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CisLevelService {

  constructor(private http : HttpClient) { }
  cisLevelbasePath = environment.apiUrl + 'CISLevel';
  getAllCISLevels(): any {
    return this.http.get<any>(this.cisLevelbasePath + '/All')
}

getCISLevel(id) : any {
    return this.http.get<any>(this.cisLevelbasePath + '/Get/' + id);
}

create(body): any {
    return this.http.post<any>(this.cisLevelbasePath + '/Create', body);
}

update(body): any {
    return this.http.post<any>(this.cisLevelbasePath + '/Update', body);
}

delete(id): any {
    return this.http.delete<any>(this.cisLevelbasePath + '/Delete/'+id);
}
}
