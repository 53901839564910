import { DatePipe } from "@angular/common";
import { AppConstant } from "src/constants/global-constants";

export class CommonFunction {
  static localid: string = "en-US";
  static getControlDocumentStatusIdByName(statusName: string): number {
    if (statusName == 'Draft') {
      return AppConstant.controlDocumentStatusConstants.Draft;
    }
    else if (statusName == 'Minor') {
      return AppConstant.controlDocumentStatusConstants.Minor;
    }
    else if (statusName == 'Major') {
      return AppConstant.controlDocumentStatusConstants.Major;
    }
    return 0;
  }
  static getControlDocumentStatusNameById(statusid: number): string {

    if (statusid == AppConstant.controlDocumentStatusConstants.Draft) {
      return 'Draft';
    }
    else if (statusid == AppConstant.controlDocumentStatusConstants.Minor) {
      return 'Minor';
    }
    else if (statusid == AppConstant.controlDocumentStatusConstants.Major) {
      return 'Major';
    }
    return '';
  }
  static fixedControlDocumentVersion(statusid: number, version: any): string {
    if (version == 1.0)
      return "1.0";

    if (statusid == AppConstant.controlDocumentStatusConstants.Draft) {
      return this.DecimalNumberfixed(version , 2);
    }
    else if (statusid == AppConstant.controlDocumentStatusConstants.Minor) {
      return this.DecimalNumberfixed(version , 1);
    }
    else if (statusid == AppConstant.controlDocumentStatusConstants.Major) {
      return this.DecimalNumberfixed(version , 1);
    }
    return "1.0";
  }
  static DecimalNumberfixed(setNumber, fixed) {
    return Number.parseFloat(setNumber).toFixed(fixed);
  }
  static getDateFromString(value: string) {

    return new Date(Date.parse(value));
  }
  static formatDateWithTime(date: Date, appDateTimeFormat: string): any {
    let datePipe: DatePipe = new DatePipe(this.localid);
    return datePipe.transform(date, appDateTimeFormat);

  }

  static formatClockDateTime(date: Date, clockDateTimeFormat: string): any {
    let datePipe: DatePipe = new DatePipe(this.localid);
    return datePipe.transform(date, clockDateTimeFormat);
  }

  static formatDate(date: Date, dateFormat: string): any {
    let datePipe: DatePipe = new DatePipe(this.localid);
    return datePipe.transform(date, dateFormat);

  }
  static formatDateOnlyTime(date: Date, timeFormat: string): any {
    let datePipe: DatePipe = new DatePipe(this.localid);
    return datePipe.transform(date, timeFormat);

  }
  static formatDateYear(date: Date, dateYearFormat: string): any {
    let datePipe: DatePipe = new DatePipe(this.localid);
    return datePipe.transform(date, dateYearFormat);

  }

  static removeTags(str) {
    if ((str === null) || (str === ''))
      return "";
    else
      str = str.toString();

    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }
}