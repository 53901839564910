<div
  [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme"
  (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()"
>
  <div class="app-header__logo">
    <!-- <div class="logo-src"></div> -->
    <img
      src="../../../../assets/images/Atlas_Button.png"
      width="50"
      height="50"
      alt=""
    />
    <h2 style="font-family: inherit; margin-left: 10px" class="ml-2">Atlas</h2>
    <div class="header__pane ms-auto">
      <button
        type="button"
        class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }"
        (click)="toggleSidebar()"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content nav-heading-scroll">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion
              [destroyOnHide]="false"
              [closeOthers]="true"
              [activeIds]="extraParameter"
            >
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel *ngFor="let menu of MenuItems" id="Menu{{menu.menuId}}">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link ">
                    <i class="{{menu.icon}}"></i>
                    <span class="vsm-title">{{menu.name}}</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
          
               <ng-container>
                 <ng-template ngbPanelContent >
                   <div class="vsm-dropdown">
                     <div class="vsm-list">
                       <div class="vsm-item"  *ngFor="let submenu of menu.menus">
                         <a
                           routerLinkActive="active-item"
                           routerLink="{{submenu.route}}"
                           class="vsm-link"
                           ><span class="vsm-title">{{submenu.name}}</span></a
                         >
                       </div>
                     </div>
                   </div>
                 </ng-template>

               </ng-container>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
