import { CommonModule, DatePipe } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { Routes, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { PermissionAction, PermissionType } from "../Enum/permission-type";
import { PermissionsGuard } from "../Guard/permissions-guard";
import { AuditStartDetailComponent } from "../Shared/audit-start-detail/audit-start-detail.component";
import { AuditcontrolslistComponent } from "../Shared/auditcontrolslist/auditcontrolslist.component";
import { AuditcontrolsortingComponent } from "../Shared/auditcontrolsorting/auditcontrolsorting.component";
import { QuestionControlDetailsComponent } from "../Shared/question-control-details/question-control-details.component";
import { QuestionFilterComponent } from "../Shared/question-filter/question-filter.component";
import { SharedModule } from "../Shared/Shared.module";
import { AddQuestionFromAuditComponent } from "./Audits/AddQuestionFromAudit/add-question-from-audit/add-question-from-audit.component";
import { AuditsComponent } from "./Audits/audits/audits.component";
import { CreateAuditComponent } from "./Audits/create-or-edit-audits/create-or-edit-audit.component";
import { StartAuditComponent } from "./Audits/start-audit/start-audit.component";
import { CreateOrEditCustomerComponent } from "./customer/create-or-edit-customer/create-or-edit-customer.component";
import { CustomerComponent } from "./customer/customer.component";
import { LinkedQuestionsListComponent } from "./linked-questions-list/linked-questions-list.component";
import { CreateMaturityComponent } from "./maturity/create-maturity/create-maturity.component";
import { MaturityComponent } from "./maturity/maturity.component";
import { QuestionListComponent } from "./question-list/question-list.component";
import { QuestionComponent } from "./question/question.component";
import { QuestionsListTableComponent } from "./questions-list-table/questions-list-table.component";
import { QuestionsListingComponent } from "./questions-listing/questions-listing.component";
import { HasPermissionPipe } from "../Shared/pipes/HasPermissionPipe";
import { MatRadioModule } from "@angular/material/radio";


const routes: Routes = [
  {
    path: "QuestionList",
    component: QuestionsListTableComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Questions },
  },
  {
    path: "Question",
    component: QuestionComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Questions },
  },

  {
    path: "Question/:id",
    component: QuestionComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Questions, permissionAccess: PermissionAction.Update  },
  },
  {
    path: "Questions-list",
    component: QuestionsListingComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Questions },
  },
  {
    path: "Maturity",
    component: MaturityComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.MaturityLevels },
  },
  {
    path: "Create-Maturity",
    component: CreateMaturityComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.MaturityLevels, permissionAccess: PermissionAction.Create
  },
  },
  {
    path: "Edit-Maturity/:id",
    component: CreateMaturityComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.MaturityLevels , permissionAccess: PermissionAction.Update
  },
  },
  {
    path: "Customers",
    component: CustomerComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Customers },
  },
  {
    path: "Create-or-edit-customer/:id",
    component: CreateOrEditCustomerComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Customers },

  },
  {
    path: "Create-or-edit-customer",
    component: CreateOrEditCustomerComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Customers, permissionAccess: PermissionAction.Create  },
  },
  {
    path: "Audits",
    component: AuditsComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Audits },
  },
  {
    path: "Create",
    component: CreateAuditComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Audits , permissionAccess: PermissionAction.Create  },
  },
  {
    path: "Edit/:Id",
    component: CreateAuditComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Audits, permissionAccess: PermissionAction.Update  },
  },
  {
    path: "StartAudit/:Id",
    component: StartAuditComponent,
    canActivate: [PermissionsGuard],
    data: { extraParameter: "elementsMenu", permissionType: PermissionType.Audits, permissionAccess: PermissionAction.Update  },
  },
];

@NgModule({
  declarations: [
    QuestionComponent,
    QuestionListComponent,
    LinkedQuestionsListComponent,
    QuestionsListingComponent,
    QuestionsListTableComponent,
    CustomerComponent,
    CreateOrEditCustomerComponent,
    CreateAuditComponent,
    QuestionControlDetailsComponent,
    QuestionControlDetailsComponent,
    MaturityComponent,
    CreateMaturityComponent,

    AuditsComponent,
    StartAuditComponent,
    AuditcontrolsortingComponent,
    AuditcontrolslistComponent,
    AuditStartDetailComponent,
    AddQuestionFromAuditComponent,
  ],

  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatRadioModule
  ],
  providers: [DatePipe],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AuditModule { }
