import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr"
import { UserService } from "./user-services";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private outlookHubPath = "outlook";
    private outlookLoginMethod = "Login";
    private receiveVerificationData = "ReceiveVerificationData";
    private outlookHubConnection: signalR.HubConnection;
    public notificationHubPath = "notifications";
    constructor(private userService: UserService) { }

    public startOutlookConnection(): void {
        this.outlookHubConnection = this.startConnection(this.outlookHubPath);
    }
    public getOutlookLoginData() {
        this.outlookHubConnection.invoke(this.outlookLoginMethod);
    }

    public startConnection(hubPath: string): signalR.HubConnection {
        let bearerToken = localStorage.getItem("accessToken");

        let hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.apiUrl + hubPath, {
                accessTokenFactory: () => "Bearer " + bearerToken,
            }).withAutomaticReconnect()
            .build();
    
        const updateConnectionId = () => {
            hubConnection.invoke("GetConnectionId").then(cId => {
                this.userService.ConnectUser({
                    connectionId: cId
                }).subscribe(data => {
                    console.log(cId, " ConnectionId Generated");
                }, error => {
                    console.error("Error updating connection ID:", error);
                });
            }).catch(error => {
                console.error("Error invoking GetConnectionId:", error);
            });
        };
    
        setTimeout(() => {
            hubConnection.start().then(() => {
                updateConnectionId();
            }).catch((err) => {
                console.log("Error while starting connection: " + err);
            });
        }, 3000);
    
        hubConnection.onreconnected((connectionId) => {
            console.log(`Reconnected with connection ID: ${connectionId}`);
            updateConnectionId();
        });
    
        hubConnection.onreconnecting((error) => {
            console.log(`Connection lost due to error "${error}". Reconnecting.`);
        });
    
        hubConnection.onclose((error) => {
            console.log(`Connection closed due to error "${error}".`);
        });
    
        return hubConnection;
    }
}