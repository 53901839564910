import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { QuestionComponent } from 'src/app/Audit/question/question.component';
import { AddQuestionFromAuditModel } from 'src/app/Models/AudutingModel';

@Component({
  selector: 'app-add-question-from-audit',
  templateUrl: './add-question-from-audit.component.html',
  styleUrls: ['./add-question-from-audit.component.scss']
})
export class AddQuestionFromAuditComponent  {
  constructor() {
   }
  AddQuestionPredefinedData: AddQuestionFromAuditModel = null;
  @ViewChild(QuestionComponent) QuestionComponentChild:QuestionComponent;

  @Input() onClose: (closeboth) => {
    
    
    
  };
  @Output() questionCreated = new EventEmitter<boolean>();
  @Output() questionEdited = new EventEmitter<boolean>();
  @Output() cancelPressed = new EventEmitter<boolean>();
  @Input() auditId : string;
  @Input() set AddQuestionData(value: any) {
    if (value) {
      this.AddQuestionPredefinedData = value;
    }
    this.isLoading = false
  }
  isLoading = false;




saveQuestionOnClose_Audit()
{
  
  this.QuestionComponentChild.saveAll();
 setTimeout(() => {
  this.onClose(false);
 }, 1000);
}

  QuestedCreated(event){
    if(event)
    {
      this.questionCreated.emit(true);
    }else
    {
      this.questionCreated.emit(false);
    }
  }

  Closed(event)
  {
    this.cancelPressed.emit(true)
  }
  
  QuestionEdited(event)
  {
    if(event)
    {
      this.questionEdited.emit(true);
    }else
    {
      this.questionEdited.emit(false);
    }

  }

}
