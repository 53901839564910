import { Component, Input } from '@angular/core';
import { AuditStatus } from 'src/app/Common/Enums/Atlas-Enums';
import { AuditService } from 'src/app/Services/audit-services';

@Component({
  selector: 'app-audit-start-detail',
  templateUrl: './audit-start-detail.component.html',
  
})
export class AuditStartDetailComponent  {
  auditDetails: any;
  loadView :boolean = false;
  auditStatus: string;
  constructor(private auditService : AuditService) { }



  @Input() set AuditId(value){
    if(value)
    {
      this.auditService.get(value).subscribe(res => 
        {
          this.auditDetails = res;
          this.auditStatus = AuditStatus[res.status]

          console.log(res)
          this.loadView = true;
        });
    }
    }
  }

