import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ThemeOptions } from "../../../../../theme-options";
import { UserService } from "src/app/Services/user-services";
import { LogoutServices } from "src/app/Services/LogoutServices";
import { TenantServices } from "src/app/Services/tenant-services";
import { signOut } from "aws-amplify/auth";
@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
  styleUrls: ['./user-box-component.scss'],
})
export class UserBoxComponent {
  isTenantSelected = false;
  tenant: any;
  isLoading = false;

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private userService: UserService,
    private logoutService: LogoutServices,
    private tenantServices: TenantServices
  ) { }

  handleLogout() {
    // clear local storage, session storage, and cookies
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      if (name.trim() === "XSRF-TOKEN" || name.trim().startsWith("cognito")) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    }


    // sign out user from Cognito
    signOut()
      .then(() => {
        console.log("User signed out successfully");
        // redirect user to login page or another page as needed
      })
      .catch((error) => {
        console.error("Error signing out user:", error);
      });
  }
  logout() {
    if (this.getSuperAdmin) {
      localStorage.clear();
      window.location.href = "/atlasadmin";
    }
    else {
      this.logoutService.logout();
    }
  }
  get getRole() {
    return (
       " (" + localStorage.getItem("roles") + ") " + localStorage.getItem("Tenant")
    );
  }
  get getEmail() {
    return (
       localStorage.getItem("email")
    );
  }
  get getUserName() {
    return (
      localStorage.getItem("Name")
    );
  }
  get getUserNameRole() {
    return (
      localStorage.getItem("Name") + " (" + localStorage.getItem("roles") + ") "
    );
  }
  userTime() {
    this.router.navigate(['Settings/user-time']);
  }
  accountSettings() {
    this.router.navigate(['account-settings/Integrations']);
  }
  switchDirectory() {
    this.tenant = JSON.parse(localStorage.getItem("AllTenants"));
    this.isTenantSelected = true;
  }
  onClickTenant(tenant: any) {
    const roleNames = localStorage.getItem("roles");
    const tenantDTO =
    {
      Id: tenant.id,
      // RoleName: roleNames
    };
    this.isLoading = true;
    this.tenantServices.saveCurrentTenant(tenantDTO).subscribe((data) => {
      localStorage.setItem("permission", data.permission.map(permission => permission.type));
      localStorage.setItem("roles", data.roles);
      localStorage.setItem("Tenant", tenant.name);
      localStorage.setItem("Name", data.user.name);
      localStorage.setItem("profile", data.profile);

      setTimeout(() => {
        this.isLoading = false;
        location.reload();
      }, 2000);
    });

  }
  get getSuperAdmin() {
    let roles = localStorage.getItem("roles");
    if (roles == "SuperAdmin") {
      this.isTenantSelected = false;
      return true;
    }
    else {
      return false;
    }

  }
  get profie() {
    return localStorage.getItem('profile') == null || localStorage.getItem('profile') == '' || localStorage.getItem('profile') == 'null' ? './assets/images/user.png' : localStorage.getItem('profile');
  }
}
