import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ControlDocumentsServices } from 'src/app/Services/control-documents.services';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { globalConstants } from 'src/constants/global-constants';

@Component({
  selector: 'app-control-document-log-change-popup',
  templateUrl: './control-document-log-change-popup.component.html',
  styleUrls: ['./control-document-log-change-popup.component.scss']
})
export class ControlDocumentLogChangePopupComponent {
  changeLogs: any;
  _controlDocumentId: string = "";
  _changeLog: string = "";
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  constructor(private controlDocumentService: ControlDocumentsServices,
    private router: Router, private datePipe: DatePipe) {
  }
  @Input() onClose: (closeboth) => {};
  @Input() onSave: (log) => {};
  @Input() set controlDocumentId(value) {
    if (value) {
      this._controlDocumentId = value;
      this.controlDocumentService.GetControlDocumentChangeLogs(value).subscribe(res => {
        this.changeLogs = res;
        console.log(res);
      })
    }
  }

  columns: Partial<Column>[] = [

    {
      label: "Status",
      elementAttribute: "status",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Version",
      elementAttribute: "version",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Change Time",
      elementAttribute: "createdDate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      formatFn: (date: Date) => {
        let dateString = this.datePipe.transform(date, "MMM d, y, hh:mm a");
        if (dateString) {
          return dateString;
        } else {
          return "";
        }
      },
    },
    {
      label: "Change Log Text",
      elementAttribute: "logChange",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },


  ];
  Save() {
    console.log("save function call")
    this.onSave(this._changeLog);

  }
}
