<div>
    <h3 class="pl4">Favorite Views</h3>
    <div class="favorite-filters-list">
        <div *ngFor="let filter of favoriteFilters; let i = index" class="pl-4 favorite-filter-item"
            [class.active]="i === activeFilterIndex">
            <span class="filter-text selected" (click)="selectFilter(filter)" *ngIf="filter.isCurrent">{{
                filter.viewName }}</span>
            <span class="filter-text" *ngIf="!filter.isCurrent" (click)="selectFilter(filter)">{{ filter.viewName
                }}</span>
            <span *ngIf="!filter.isCurrent && filter.id" (click)="deleteFilter(filter); $event.stopPropagation()"
                class="material-icons delete-icon">delete</span>
        </div>
    </div>

    <div class="add-filter-section">
        <button *ngIf="!addingNewFilter" (click)="$event.stopPropagation(); isSaveOpen = true"
            class="add-filter-button btn btn-success">+
            create new</button>
    </div>
</div>

<ng-container *ngIf="isSaveOpen" (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeFilterPopup();">
        <div class="pop-up-box" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header">
                <span (click)="closeFilterPopup()" class="material-icons">highlight_off</span>
                <h1>Filters</h1>
            </div>
            <hr class="HR">
            <ng-container>
                <div class="container mt-2">
                    <mat-form-field appearance="fill" class="form-field-audit" style="width: 100%;">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Enter your name" [(ngModel)]="favName" style="width: 100%;">
                    </mat-form-field>

                    <div class="col-md-12 buttons mb-2">
                        <button class="btn btn-warning" mat-button
                            (click)="$event.stopPropagation(); closeFilterPopup()" [disabled]="loading"
                            type="button">Cancel</button>
                        <button class="btn btn-success buttonSave" mat-button [disabled]="loading" type="button"
                            (click)="$event.stopPropagation(); save()">Save</button>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</ng-container>