import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonFunction } from '../commen/commen-function';
import { DiffEditorModel } from "ngx-monaco-editor";
import { ControlDocumentsServices } from 'src/app/Services/control-documents.services';
import * as beautify from 'js-beautify';
@Component({
  selector: 'app-compare-version-popup',
  templateUrl: './compare-version-popup.component.html',
  styleUrls: ['./compare-version-popup.component.scss']
})
export class CompareVersionPopupComponent implements OnInit {

  versionModelPopUp: boolean = false;
  compareVersionIdFirst: string = "";
  compareVersionIdSecond: string = "";
  isLoading: boolean = false;
  AllAuditTrailsList = [];
  text1 = "";
  text2 = "";
  isCompared = true;
  @Input() onClose: () => {};
  @Input() set compareWith(value) {
    if (value) {
      this.compareVersionIdFirst = value
      console.log(this.compareVersionIdFirst)
    }
  }

  @Input() set compareTo(value) {
    if (value) {
      this.compareVersionIdSecond = value
      console.log(this.compareVersionIdSecond)
    }
  }
  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";

  @Input()
  languages = ["plaintext", "postiats"];
  @Input() set controldocumentId(value) {
    if (value) {
      console.log("compare version pop called")
      this.GetAllAuditTrails(value);
    }
  }




  @Input()
  themes = [
    {
      value: "vs",
      name: "Default",
    },
    {
      value: "vs-dark",
      name: "Dark",
    },
    {
      value: "hc-black",
      name: "High Dark",
    },
  ];

  // input
  inputOptions = { theme: "vs", language: "plaintext" };
  // compare, output
  diffOptions = {
    theme: "vs",
    language: "html",
    readOnly: true,
    renderSideBySide: true,
    renderWhitespace: 'all'
  };
  originalModel: DiffEditorModel = {
    code: "",
    language: 'html'
  };

  modifiedModel: DiffEditorModel = {
    code: "",
    language: 'html'
  };

  constructor(private controlDocumentsServices: ControlDocumentsServices, private renderer: Renderer2) { }

  onChangeLanguage(language) {
    this.inputOptions = {
      ... this.inputOptions,
      language: language,
    }
    this.originalModel = {
      ... this.originalModel,
      language: language,
    }
    this.modifiedModel = {
      ... this.modifiedModel,
      language: language,
    }
  }
  onChangeTheme(theme) {
    this.inputOptions = { ...  this.inputOptions, theme: theme }
    this.diffOptions = { ... this.diffOptions, theme: theme }
  }

  onChangeInline(checked) {
    this.diffOptions = {
      ...  this.diffOptions,
      renderSideBySide: !checked,
    }
  }



  ngOnInit(): void {
    console.log('====== CompareVersionPopupComponent Called =======',)
  }


  versionModelPopUpShow(isShow: boolean) {
    this.versionModelPopUp = isShow;
  }
  @ViewChild('contentDiv') contentDiv: ElementRef;
  @ViewChild('contentDiv1') contentDiv1: ElementRef;

  CompareVersion() {
    this.isLoading = true;
    let obj1 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdFirst)
        ?.newValue
    );
    let obj2 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdSecond)
        ?.newValue
    );
    if (obj1 && obj2) {
      this.text1 = `${obj1.DescriptionHTMLFormate}`;
      this.text2 = `${obj2.DescriptionHTMLFormate}`;
      this.onCompare();
    }
    this.isLoading = false;
  }

  GetAllAuditTrails(controldocumentId: string) {
    this.isLoading = true;
    this.AllAuditTrailsList = [];
    this.controlDocumentsServices.GetAllTrailAuditListByControlDocumentId(controldocumentId)
      .subscribe((data) => {

        if (data && data.length > 0) {
          data.forEach((element) => {
            element.createdDate = CommonFunction.formatDate(
              element.createdDate,
              "dd-MM-yyyy hh:mm:ss"
            )
            element.version = CommonFunction.fixedControlDocumentVersion(
              CommonFunction.getControlDocumentStatusIdByName(element.status),
              element.version
            );
          });
          this.AllAuditTrailsList = data;
          this.isLoading = false;
        }
      },
        (error) => {
          console.log("error has been occured");
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  onCompare() {
    let originalText = this.text1.replace(/﻿/g, '')
    this.text1 = beautify.html(originalText);
    let modifiedText = this.text2.replace(/﻿/g, '')
    this.text2 = beautify.html(modifiedText);
    originalText = this.contentDiv.nativeElement.innerText;
    modifiedText = this.contentDiv1.nativeElement.innerText;
    this.originalModel = {
      ... this.originalModel,
      code: originalText,
      language: 'html'
    }
    this.modifiedModel = {
      ... this.originalModel,
      code: modifiedText,
      language: 'html'
    }
    this.isCompared = true;
    this.versionModelPopUpShow(true);
  }
  ngAfterViewInit() {
    this.renderer.listen(this.contentDiv.nativeElement, 'DOMSubtreeModified', () => {
      this.onCompare();
    });

    this.renderer.listen(this.contentDiv1.nativeElement, 'DOMSubtreeModified', () => {
      this.onCompare();
    });
  }
} 
