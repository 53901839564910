<div class="Inforce-Table-Container" [class.inner-table-container]="hasInnerTable">

	<table matSort mat-table multiTemplateDataRows [dataSource]="_dataSource"
		[class.customColumnWidths]="customColumnWidths">

		<ng-container matColumnDef="expandIcon" *ngIf="hasInnerTable">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef>
				<button mat-icon-button [matTooltip]="isAllExpanded ? 'Collapse All' : 'Expand All'"
					[matTooltipPosition]="'below'" (click)="expandCollapseAll()">
					<mat-icon class="collapse-expand-icon">
						{{isAllExpanded ? 'expand_more' : 'keyboard_arrow_right'}}
					</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button [matTooltip]="element.expanded ? 'Collapse' : 'Expand'"
					[matTooltipPosition]="'below'" (click)="toggleRow(element)">
					<mat-icon class="collapse-expand-icon">
						{{element.expanded ? 'expand_more' : 'keyboard_arrow_right'}}
					</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="positionOpenIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%">
				<button class="collapsiblebtn mb-1" (click)="getArrowIcon()" *ngIf="haveCollapseIcon">
					<mat-icon class="arrowIcon" *ngIf="!arrowClose">arrow_drop_down</mat-icon>
					<mat-icon class="arrowIcon" *ngIf="arrowClose">arrow_right</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Details" [matTooltipPosition]="'below'" (click)="onOpen(element)"
					data-automation="details-button">
					<mat-icon class="Open-Details-Icon blue-icon">reorder</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="positionDownloadIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%"></th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Download" [matTooltipPosition]="'below'"
					(click)="downloadURL(element.documentPath)" data-automation="details-button">
					<mat-icon class="Open-Details-Icon blue-icon">cloud_download</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container *ngIf="hascheckBox" matColumnDef="positionCheckBox">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%; text-align: center; padding-left: 3px;">
				<mat-checkbox (change)="onCheckedAll($event)">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: center !important;">
				<mat-checkbox [checked]="isAllChecked" (change)="onCheckChanged($event,element)">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container *ngIf="hasPreviewIcon" matColumnDef="positionEditIcon">
			<th [hidden]="!canViewEditIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewEditIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Edit" [matTooltipPosition]="'below'" (click)="onEdit(element)"
					data-automation="edit-button">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>

		<!-- to start button on audit screen -->
		<ng-container *ngIf="hasStartIcon" matColumnDef="positionStartIcon">
			<th [hidden]="!canViewStartIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewStartIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Start" [matTooltipPosition]="'below'" (click)="onStart(element)"
					data-automation="start-button">
					<mat-icon>start</mat-icon>
				</button>
			</td>
		</ng-container>
		<!-- end -->

		<!-- to show custom Icon on Screen -->
		<ng-container *ngIf="hasCustomIcon" matColumnDef="positionCustomIcon">
			<th [hidden]="!canViewCustomIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewCustomIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Control-Details" [matTooltipPosition]="'below'"
					(click)="onCustomIconClick(element)" data-automation="controlDetail-button">
					<mat-icon>open_in_new</mat-icon>
				</button>
			</td>
		</ng-container>
		<!-- end -->

		<ng-container *ngIf="hasDeleteIcon" matColumnDef="positionDeleteIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%"></th>
			<td mat-cell *matCellDef="let element">
				<mat-icon class="Delete-Icon material-icons delete-icon mt-1 blue-icon" matTooltip="Delete"
					[matTooltipPosition]="'below'" (click)="onDelete(element)">
					delete
				</mat-icon>
			</td>
		</ng-container>

		<ng-container *ngFor="let column of columns; let i = index">

			<ng-container *ngIf="!(column.hidden || column.iconColumn || column.isProjected)"
				matColumnDef="{{ column.elementAttribute }}">

				<th mat-header-cell
					class="{{column.columnClass == 'text-underline' ? undefined : column.columnClass}} mat-header-cell"
					[style.width]="column.columnWidth || 'auto'" class="header" mat-sort-header
					[disabled]="column.disableSorting" *matHeaderCellDef
					attr.data-automation="table-column--{{ column.label }}">
					<span style="color: white;">{{column.label}}</span>
				</th>
				<td (click)='onCellClick(column.onClickFn, element, $event)'
					[ngStyle]="column.onClickFn && {'cursor': 'pointer'}"
					[ngClass]="{ 'cursor-pointer': column.onClickFn }" [class]="column.columnClass"
					[style.width]="column.columnWidth || 'auto'" *matCellDef="let element" mat-cell>
					<span
						*ngIf="!column.isHtml && !column.isIconButtonColum && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && !column.hasToolTip"
						matTooltip="{{revealTruncatedText(accessNestedProperty(element, column.elementAttribute), column.formatFn)}}"
						(click)="column.isLink && onRowClick(element)" [ngClass]="{ 'cursor-pointer': column.isLink }"
						matTooltipClass="inforce-tooltip">
						{{
						column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
						accessNestedProperty(element, column.elementAttribute)
						}}
						<div *ngIf="column.elementAttribute == 'cardDetail'" style="float: right;">
							<span class="{{ 'icon ' + getBrand(element) }}"></span>
						</div>
						<mat-icon style="font-size: 10px;" *ngIf="column.onClickFn">open_in_new</mat-icon>
					</span>

					<span
						*ngIf="!column.isHtml && !column.isIconButtonColum && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.hasToolTip"
						[matTooltip]="accessNestedProperty(element,column.toolTipText)"
						(click)="column.isLink && onRowClick(element)" [ngClass]="{ 'cursor-pointer': column.isLink }"
						matTooltipClass="inforce-tooltip">
						{{
						column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
						accessNestedProperty(element, column.elementAttribute)
						}}

					</span>


					<span
						*ngIf="!column.options && !column.isIconButtonColum && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.isHtml"
						matTooltipClass="inforce-tooltip">
						<button mat-icon-button [matMenuTriggerFor]="htmlMenu"
							(click)="AssignHtml(accessNestedProperty(element, column.elementAttribute))">
							<mat-icon>open_in_new</mat-icon>
						</button>
					</span>

					<span
						*ngIf="!column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && !column.isIconButtonColum && !column.isHtml && column.isControlDocument"
						matTooltipClass="inforce-tooltip">
						{{
						showControlLinkedText(element, column.linkedControlTextCount)
						}}
						<button mat-icon-button [matMenuTriggerFor]="htmlMenuControlDocument"
							*ngIf="hasControlTextLinked(element, column.linkedControlTextCount)"
							(click)="AssignControlDocuments(element ,column.elementAttribute)">
							<mat-icon>add</mat-icon>
						</button>
					</span>

					<!-- Custom Icon Button Column -->
					<span
						*ngIf="!column.isHtml && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.isIconButtonColum"
						matTooltipClass="inforce-tooltip">
						<button *ngIf="column.hasToolTip" mat-icon-button [matTooltip]="column.toolTipText"
							[matTooltipPosition]="column.tooltipPosition" (click)="onCustomButtonIconClick(element)">
							<mat-icon>{{column.iconName}}</mat-icon>
						</button>

						<button *ngIf="!column.hasToolTip" mat-icon-button (click)="onCustomButtonIconClick(element)">
							<mat-icon>{{column.iconName}}</mat-icon>
						</button>
					</span>

					<div class="d-flex justify-content-between"
						*ngIf="currentElement && element.id == currentElement.id && showCustomizationSave">
						<input type=text [(ngModel)]="currentElement[customizedElementAttribute]" />
						<button class="customized-save-btn btn btn-green"
							(click)="onEditSave(currentElement[customizedElementAttribute])">
							Save
						</button>

					</div>
					<div style="padding: 0px 3.125px; text-align: left; border: 1px solid grey; height: 21px; overflow: hidden; text-overflow: ellipsis;"
						*ngIf="!(!column.options && !column.isArray) && accessNestedProperty(element, column.elementAttribute) && ((column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length < 2)">
						<div *ngIf="column.type">
							<a *ngIf="column.type === 'email'" target="_blank" rel="noopener noreferrer"
								[href]="'mailto: ' + (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute))[0]">
								{{
								(column.formatFn
								? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
								accessNestedProperty(element, column.elementAttribute))[0]
								}}
							</a>
						</div>
						<div *ngIf="!column.type">
							{{
							(column.formatFn ? column.formatFn(accessNestedProperty(element,
							column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute))[0]
							}}
						</div>
					</div>

					<select
						*ngIf="!column.hasChangeFunc && (column.options || column.isArray) && accessNestedProperty(element, column.elementAttribute) && (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length > 1"
						class="Select">
						<option
							*ngFor="let data of (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute));"
							[value]="data">
							{{data}}
						</option>
					</select>

					<select
						*ngIf="column.hasChangeFunc && (column.options || column.isArray) && accessNestedProperty(element, column.elementAttribute) && (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length > 1"
						class="Select" (change)="dropdownChange(element,$event)">
						<option
							*ngFor="let data of (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute));"
							[value]="data.id">
							{{data.name}}
						</option>
					</select>
				</td>
			</ng-container>
		</ng-container>
		<mat-menu #htmlMenu="matMenu" style="min-width: 512px;">
			<ng-template matMenuContent>
				<span [innerHTML]="getHtml(HtmlOnDescription)" (click)="showControls($event)" class="innerHtmlCard">

				</span>
			</ng-template>
		</mat-menu>

		<mat-menu #htmlMenuControlDocument="matMenu" style="min-width: 512px;">
			<ng-template matMenuContent>
				<table class="ml-2">
					<thead>
						<tr>
							<th>Name</th>
							<th>Version</th>
							<th>Status</th>
							<th>Created Date</th>
							<th>Control Linked Text</th>
						</tr>

					</thead>
					<tbody>
						<tr *ngFor="let controlDoc of controlDocuments">
							<td><a routerLink="/Documentation/revision-history/{{controlDoc.controlDocumentId}}"
									target="_blank">{{controlDoc.controlDocumentName}}</a></td>
							<td>{{controlDoc.version}}</td>
							<td>{{controlDoc.status}}</td>
							<td>{{controlDoc.createdDate}}</td>
							<td [matTooltip]="controlDoc.linkedText" [matTooltipPosition]="'below'">
								{{transformText(controlDoc.linkedText)}}</td>
						</tr>
					</tbody>
				</table>
			</ng-template>
		</mat-menu>

		<ng-container *ngIf="hasInnerTable" matColumnDef="innerTable">
			<td mat-cell *matCellDef="let element" [attr.colSpan]="columns.length" class="inner-table">
				<app-atlas-table [columns]="innerColumns" [hasPaginator]="false"
					[dataSource]="accessNestedProperty(element, innerDataSelector)">
				</app-atlas-table>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="filter()"></tr>
		<tr mat-row *matRowDef="let row; columns: filter();"
			[ngClass]="{'active-times-lips': isBilling == true && row.startedOn !== null  }"></tr>
		<ng-container *ngIf="hasInnerTable">
			<tr mat-row *matRowDef="let row; columns: ['innerTable']" [class.d-none]="!row.expanded"></tr>
		</ng-container>
	</table>

</div>

<mat-paginator [class.d-none]="!hasPaginator" (page)="pageEvent = pageChange($event)"
	[pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>

<mat-paginator [class.d-none]="!hasServerPaging" #paginatorServer *ngIf="this.hasServerPaging"
	(page)="pageEvent = pageChange($event)" [pageSizeOptions]="customPageSizeOptions" [length]="totalItemsCount"
	showFirstLastButtons>
</mat-paginator>
<mat-menu #appMenu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item>Settings</button>
		<button mat-menu-item>Help</button>
	</ng-template>
</mat-menu>

<app-quill-control-list *ngIf="showControlListPopUp" [onClose]="closeControlListPopUp.bind(this)"
	[LinkControl]="controlsIds"></app-quill-control-list>