import { CommonModule, DatePipe } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CalendarModule } from "primeng/calendar";
import { QuestionDetailsComponent } from "../Audit/question-details/question-details.component";
import { DemoMaterialModule } from "../material-module";
import { AppControlDetailsComponent } from "./app-control-details/app-control-details.component";
import { AtlasFileUploadComponent } from "./atlas-file-upload/atlas-file-upload.component";
import { AtlasFilesListComponent } from "./atlas-files-list/atlas-files-list.component";
import { ControlsFilterComponent } from "./controls-filter/controls-filter.component";
import { DownloadFileDirective } from "./directives/download.file-directive";
import { AtlasTableComponent } from "./form-module/atlas-forms.component";
import { LinkQuestionComponent } from "./link-question/link-question.component";
import { SortingPopUpComponent } from "./sorting-pop-up/sorting-pop-up.component";
import { QuillControlListComponent } from './quill-control-list/quill-control-list.component';
import { CompareVersionPopupComponent } from './compare-version-popup/compare-version-popup.component';
import { MonacoEditorModule } from "ngx-monaco-editor";
import { AppCustomerDetailsComponent } from './app-customer-details/app-customer-details.component';
import { AppAuditDetailsComponent } from './app-audit-details/app-audit-details.component';
import { AtlasHtmlEditorComponent } from './atlas-html-editor/atlas-html-editor.component';
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { MatTableModule } from '@angular/material/table';
import { DiffViewerComponent } from './diff-viewer/diff-viewer.component';
import { HasPermissionPipe } from "./pipes/HasPermissionPipe";
import { FilterFunctionComponent } from './filter-function/filter-function.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FavoriteViewsComponent } from './favorite-views/favorite-views.component';

@NgModule({
    declarations: [
        AtlasTableComponent,
        AtlasFileUploadComponent,
        AtlasFilesListComponent,
        DownloadFileDirective,
        ControlsFilterComponent,
        QuestionDetailsComponent,
        SortingPopUpComponent,
        LinkQuestionComponent,
        AppControlDetailsComponent,
        QuillControlListComponent,
        CompareVersionPopupComponent,
        AppAuditDetailsComponent,
        AppCustomerDetailsComponent,
        AtlasHtmlEditorComponent,
        DiffViewerComponent,
        HasPermissionPipe,
        FilterFunctionComponent,
        FilterButtonComponent,
        FavoriteViewsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DemoMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDatepickerModule,
        MatInputModule,
        CalendarModule,
        MatTooltipModule,
        RichTextEditorAllModule,
        MonacoEditorModule.forRoot(),
        MatTableModule
    ],
    exports: [
        AtlasTableComponent,
        AtlasFileUploadComponent,
        AtlasFilesListComponent,
        ControlsFilterComponent,
        QuestionDetailsComponent,
        SortingPopUpComponent,
        LinkQuestionComponent,
        AppControlDetailsComponent,
        CompareVersionPopupComponent,
        AppAuditDetailsComponent,
        AppCustomerDetailsComponent,
        MatMenuModule,
        AtlasHtmlEditorComponent,
        DiffViewerComponent,
        HasPermissionPipe,
        FilterFunctionComponent
    ],
    providers: [DatePipe],
    bootstrap: [FilterFunctionComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
