import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent  {
  @ViewChild('searchInput') searchInput: ElementRef;
  public isActive: any;

  searchInputFocus()
  {
    this.isActive = true;
    this.searchInput.nativeElement.focus();
    
  }

}
