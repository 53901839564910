<ng-container (click)="$event.stopPropagation()">
	<div class="popup-overlay" (click)="onClose(false)">
		<div class="pop-up-box  pb-4" (click)="$event.stopPropagation()">
			<div class="Pop-Up-Header  pt-4 ps-3 pe-3 ">
				<h1>Control Details</h1>
				<span class="spacer"></span>
				<svg (click)="onClose(false)" width="10" height="10" viewBox="0 0 10 10" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>
			<hr class="HR ms-2 me-2">
			<div class="container ps-2">
				<div class="d-flex mt-3">
					<h4 class="title-text">{{event.title}}</h4>
					<span class="spacer"></span>
					<button class=" edit-button" (click)="getEditUrl(event)">
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_735_703)">
								<path
									d="M11.4265 0.574007C11.0866 0.234606 10.6259 0.0439758 10.1455 0.0439758C9.66515 0.0439758 9.20443 0.234606 8.86451 0.574007L0.732508 8.70601C0.499633 8.93757 0.314989 9.21302 0.189257 9.5164C0.0635245 9.81979 -0.000798988 10.1451 7.49061e-06 10.4735V11.5C7.49061e-06 11.6326 0.0526859 11.7598 0.146454 11.8536C0.240222 11.9473 0.367399 12 0.500008 12H1.52651C1.85489 12.0009 2.18019 11.9367 2.48359 11.8111C2.78698 11.6854 3.06243 11.5008 3.29401 11.268L11.4265 3.13551C11.7658 2.79561 11.9563 2.33499 11.9563 1.85476C11.9563 1.37452 11.7658 0.91391 11.4265 0.574007ZM2.58701 10.561C2.30501 10.8411 1.92399 10.9989 1.52651 11H1.00001V10.4735C0.999502 10.2765 1.03809 10.0813 1.11353 9.89923C1.18897 9.7172 1.29976 9.55193 1.43951 9.41301L7.61101 3.24151L8.76101 4.39151L2.58701 10.561ZM10.719 2.42851L9.46601 3.68201L8.31601 2.53451L9.56951 1.28101C9.64502 1.20566 9.73463 1.14593 9.83322 1.10521C9.93182 1.0645 10.0375 1.0436 10.1441 1.04372C10.2508 1.04383 10.3564 1.06496 10.4549 1.10589C10.5534 1.14682 10.6429 1.20675 10.7183 1.28226C10.7936 1.35777 10.8533 1.44738 10.8941 1.54597C10.9348 1.64457 10.9557 1.75022 10.9555 1.85689C10.9554 1.96356 10.9343 2.06917 10.8934 2.16767C10.8524 2.26618 10.7925 2.35566 10.717 2.43101L10.719 2.42851Z"
									fill="white" />
							</g>
							<defs>
								<clipPath id="clip0_735_703">
									<rect width="12" height="12" fill="white" />
								</clipPath>
							</defs>
						</svg>
						 Edit
					</button>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Description</label>
					<p class="value-text" [innerHTML]="description"></p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Objective</label>
					<p class="value-text" [innerHTML]="objective"></p>

				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Auditing Guidelines</label>
					<p class="value-text" [innerHTML]="auditingGuideLines"></p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Other Information</label>
					<p class="value-text" [innerHTML]="otherInformation"></p>

				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">CIS Asset Types</label>
					<p *ngFor="let tag of event.cisAssetTypeIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Control Types</label>
					<p *ngFor="let tag of event.controlTypeIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Control Functions</label>
					<p *ngFor="let tag of event.controlFunctionIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">CIS Levels</label>
					<p *ngFor="let tag of event.cisLevelIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Primary Objectives</label>
					<p *ngFor="let tag of event.primaryObjectiveIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">SCF Domains</label>
					<p *ngFor="let tag of event.scfDomainIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Suggested Controls</label>
					<p *ngFor="let tag of event.suggestedControlIds" class="tag value-text">{{tag.value}}</p>
				</div>
				<div class="Col-Lg-12 col-lg-12">
					<label class="label-text">Suggested Control Ownership</label>
					<p *ngFor="let tag of event.scoIds" class="tag value-text">{{tag.value}}</p>
				</div>
			</div>
		</div>
	</div>
</ng-container>