<div class="row">
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <form [formGroup]="publishDocumentForm" >
          
            <div class="position-relative mb-3">
              <mat-form-field appearance="fill" class="d-block">
                <mat-label>Select Control Document</mat-label>
                <mat-select formControlName="controlDocumentId">
                  <mat-option *ngFor="let item of controlDocuments" [value]="item.id">{{ item.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
  
            <div class="position-relative mb-3">
              <mat-form-field appearance="fill" class="d-block">
                <mat-label>Select Reminder</mat-label>
                <mat-select formControlName="reminder" multiple>
                  <mat-option *ngFor="let reminder of reminders" [value]="reminder.value">{{ reminder.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="position-relative mb-3">
              <mat-form-field appearance="fill" class="d-block">
                <mat-label>Assign to</mat-label>
                <mat-select formControlName="assignUser" multiple (selectionChange)="onSelectionChange($event)">
                  <mat-option [value]="everyone" id="everyoneOption">
                     Everyone
                  </mat-option>
                  <mat-option *ngFor="let user of users" [value]="user.id">{{ user.fullName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
                   
            <div class="position-relative mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea id="description" formControlName="description" placeholder="Description" class="form-control"></textarea>
            </div>
  
            <button type="button" [disabled]="submitting" class="mt-1 btn btn-success btn-atlas" (click)="createPublishDocument()">Publish</button>
            <button type="button" class="mt-1 ms-2 btn btn-atlas-cancel" (click)="MoveBack()">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="submitting" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
  </div>
