import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function markFormGroupTouched(formGroup: UntypedFormGroup) {
  (<any>Object).values(formGroup.controls).forEach((control) => {
    if (control.invalid) {
      control.markAsTouched();
    }

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}
export function hasInvalidControl(formGroup: UntypedFormGroup | UntypedFormArray): boolean {
  for (const key in formGroup.controls) {
    let control = formGroup.controls[key];
    if (control.invalid) {
      return true;
    }

    if (control?.controls) {
      if (hasInvalidControl(control)) {
        return true;
      }
    }
  }

  return false;
}
