import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CisAssetTypeService {

  constructor(private http : HttpClient) { }
  cisAssetTypebasePath = environment.apiUrl + 'CISAssetType';
  getAll(): any {
    return this.http.get<any>(this.cisAssetTypebasePath + '/All')
}

get(id) : any {
    return this.http.get<any>(this.cisAssetTypebasePath + '/Get/' + id);
}

create(body): any {
    return this.http.post<any>(this.cisAssetTypebasePath + '/Create', body);
}

update(body): any {
    return this.http.post<any>(this.cisAssetTypebasePath + '/Update', body);
}

delete(id): any {
    return this.http.delete<any>(this.cisAssetTypebasePath + '/Delete/'+id);
}
}
