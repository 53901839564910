export class ControlModel implements IControlModel {
  Id: string;
  documentId: string;
  title: string;
  objective: string;
  description: string;
  otherInformation: string;
  auditingGuideLines: string;
  chapterId: string;
  auditingId;
  string;
  customerId: string;
  controlTypeIds: any[];
  cisAssetTypeIds: any[];
  cisLevelIds: any[];
  controlFunctionIds: any[];
  PrimaryObjectiveIds: any[];
  suggestedControlIds: any[];
  scfDomainIds: any[];
  internalControlIds: any[];
  ScoIds: any[];
  chapterIds: any[];
  pageNumber: any;
  pageSize: any;
}

export interface IControlModel {
  Id: string;
  documentId: string;
  title: string;
  objective: string;
  description: string;
  otherInformation: string;
  auditingGuideLines: string;
  chapterId: string;
  auditingId;
  string;
  customerId: string;
  controlTypeIds: any[];
  cisAssetTypeIds: any[];
  cisLevelIds: any[];
  controlFunctionIds: any[];
  PrimaryObjectiveIds: any[];
  suggestedControlIds: any[];
  scfDomainIds: any[];
  internalControlIds: any[];
}
