import { DatePipe } from "@angular/common";
import { Component, AfterContentInit, OnInit, Input, Output, EventEmitter, ViewChild, ContentChildren, QueryList } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource, MatTable, MatColumnDef } from "@angular/material/table";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { EllipsisPipe } from "../pipes/ellipsis.pipe";

@Component({
  selector: "app-atlas-table",
  templateUrl: "./atlas-table.component.html",
  styleUrls: [
    "./atlas-table.component.scss",
    "./atlas-table-mq-component.scss",
  ],
  providers: [EllipsisPipe],

})
export class AtlasTableComponent implements AfterContentInit, OnInit {
  @Input() columns: Array<Column> = [];
  @Input() customColumnWidths: boolean = false;
  @Input() hasPaginator: boolean = true;
  @Input() isBilling: boolean = false;
  @Input() customPageSizeOptions;
  @Input() resizeColumn = false;
  @Input() hasDownloadIcon = false;
  pageSize = 10;
  totalItemsCount: number;
  @Input() set totalItems(value: number) {
    this.totalItemsCount = value;
  };



  showControlListPopUp: boolean = false;
  controlsIds: any;
  controlDocuments: any;

  @Input() set dataSource(value: Array<any>) {
    if (value) {
      this.data = value;
      if (value.length > 0) {
        this.pageSizeOptions = this.initialOptions.filter(
          (size) => size < value.length
        );
        this.pageSizeOptions.push(value.length);
        // When new element is added and the user has been set the page size to be max possible,
        //increment the page size in order to view all the entites again
        if (!this.hasServerPaging) {
          if (this.paginator && this.paginator.pageSize + 1 === value.length) {
            this.paginator._changePageSize(value.length);
          } else {
            this.paginator._changePageSize(50);
          }
        }

      }

      this._dataSource = new MatTableDataSource(value);
      if (!this.hasServerPaging) {
        this._dataSource.sort = this.sort;
      }

      if (this.hasPaginator && !this.hasServerPaging) {
        this._dataSource.paginator = this.paginator;
      }


      // Add property expanded: boolean to each element if inner table is enabled
      if (this.hasInnerTable) {
        value.forEach((element: any) => {
          element.expanded = this.innerTableExpanded;
        });
      }


    }
  }
  @Input() hasServerPaging = false;
  @Input() hasOpenIcon = false;
  @Input() hasPreviewIcon = false;
  @Input() hasStartIcon = false;
  @Input() itemCount: any;
  @Input() hasCustomIcon = false;
  @Input() hasDeleteIcon = false;
  @Input() hascheckBox = false;
  @Input() hasInnerTable = false;
  @Input() innerTableExpanded = false;
  @Input() innerColumns: Column[] = [];
  @Input() innerDataSelector: string;
  @Input() isAllChecked: boolean = false;
  @Input() haveCollapseIcon: boolean = false;
  @Input() onOpen = (el) => console.log("Open handler not implemented");
  @Input() arrowClosed = () => console.log("Open handler not implemented");
  @Input() onEdit = (el) => console.log("Edit handler not implemented");
  @Input() onStart = (el) => console.log("Start handler not implemented");
  @Input() onCustomIconClick = (el) => console.log("Custom Icon Click handler not implemented");
  @Input() onCustomButtonIconClick = (el) => console.log("Custom Icon Click handler not implemented");
  @Input() onDelete = (el) => console.log("Delete handler not implemented");
  @Input() pageChange = (el) =>
    console.log("pageChange handler not implemented");
  @Input() onCheckChanged = (el, e) =>
    console.log("pageChange handler not implemented");
  @Input() onAllChecked = (el) =>
    console.log("pageChange handler not implemented");
  @Input() onRowClick = (el) => {
    if (this.onRowClickReroute) {
      this.reroute();
    } else {
      console.log("onRowClick handler not implemented");
    }
  };



  @Input() onRowClickReroute: string;
  @Input() pageEvent: any;
  @Input() showCustomizationSave = false;
  @Input() currentElement: any;
  @Input() onEditSave = (el) =>
    console.log("Edit Save handler not implemented");
  @Input() customizedElementAttribute = "";
  @Output() SortPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() VersionCompersion: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginatorServer: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<{}>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  data: any[];
  isAllExpanded: boolean = false;
  initialOptions = [25, 50, 100, 500];
  pageSizeOptions: number[] = [...this.initialOptions];

  constructor(private router: Router, protected _sanitizer: DomSanitizer, private datePipe: DatePipe, private _ellipsisPipe: EllipsisPipe) {
    this._dataSource = new MatTableDataSource([]);
    this._dataSource.data = [];
    // Remove query string if any
  }

  ngOnInit(): void {
    console.log(this.columns, "columns:::::::::::::");
    if (this.customPageSizeOptions) {
      this.pageSizeOptions = this.customPageSizeOptions;
      this.initialOptions = this.customPageSizeOptions;
    }
  }
  count = 0;
  downloadURL(url) {
    let hiddenIFrameID = "hiddenDownloader" + this.count++;
    let iframe = document.createElement("iframe");
    iframe.id = hiddenIFrameID;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    iframe.src = url;
  }
  onCheckedAll(ev) {
    if (ev.checked) {
      this.isAllChecked = true;
      this.onAllChecked(ev);
    } else {
      this.isAllChecked = false;
      this.onAllChecked(ev);
    }
  }
  ngAfterContentInit() {
    this.columnDefs.forEach((columnDef) => {
      this.table.addColumnDef(columnDef);
    });
    if (this?.sort?.sortChange) {
      this.sort.sortChange.subscribe((sort: Sort) => {
        this.SortPageChanged.emit({
          sortBy: sort.active,
          sortDir: sort.direction,
        });
      });
    }

  }

  dropdownChange(element, event) {
    this.VersionCompersion.emit({
      currentVersion: element.controlAuditTrailId,
      previousVersion: event.target.value,
      controlDocumentId: element.controlDocumentId
    })
  }

  hideTable = true;
  list;
  _columns;
  _dataSource;

  filter() {
    return this.columns
      .filter((c) => !c.hidden)
      .map((el) => el.elementAttribute);
  }

  toggleRow(element: any) {
    element.expanded = !element.expanded;
  }
  HtmlOnDescription = "";
  AssignHtml(html) {
    this.HtmlOnDescription = html;

  }
  arrowClose = true;
  getArrowIcon() {
    this.arrowClose = !this.arrowClose;
  }
  showControls(event) {
    let ids = [];
    let currentElement = event.target;
    while (currentElement) {
      if (currentElement?.id?.includes('Control-')) {
        ids.push(currentElement.id.replace('Control-', ''));
      }
      currentElement = currentElement.parentNode;
    }
    const idsString = 'Control-' + ids.join('Control-');
    if (event.target.localName == 'span' && event.target.id.includes('Control-')) {
      event.preventDefault();
      let LinkControl = idsString.split('Control-');
      LinkControl.shift();
      this.controlsIds = LinkControl;
      this.showControlListPopUp = true
    }
  }

  closeControlListPopUp(isShow) {
    this.showControlListPopUp = isShow;
  }
  public accessNestedProperty(obj: Object, prop: string) {
    const index = prop.indexOf(".");

    if (typeof obj === "undefined") {
      return;
    }

    if (index > -1) {
      return this.accessNestedProperty(
        obj[prop.substring(0, index)],
        prop.slice(index + 1)
      );
    }

    return obj[prop];
  }

  public showControlLinkedText(obj: Object, prop: string) {
    const index = prop.indexOf(".");

    if (typeof obj === "undefined") {
      return;
    }

    if (index > -1) {
      return this.accessNestedProperty(
        obj[prop.substring(0, index)],
        prop.slice(index + 1)
      );
    }

    if (obj[prop] == 0) {
      return "No Control Text Linked";
    }
    return obj[prop] + " Control Text Linked";
  }


  public hasControlTextLinked(obj: Object, prop: string) {
    const index = prop.indexOf(".");

    if (typeof obj === "undefined") {
      return false;
    }

    if (index > -1) {
      return this.accessNestedProperty(
        obj[prop.substring(0, index)],
        prop.slice(index + 1)
      );
    }

    if (obj[prop] == 0) {
      return false;
    }
    return true;
  }

  canViewEditIcon(): boolean {
    return true;
  }

  canViewStartIcon(): boolean {
    return true;
  }

  canViewCustomIcon(): boolean {
    return true;
  }
  transformText(linkedText) {
    console.log(linkedText);
    return this._ellipsisPipe.transform(linkedText, 50);
  }

  expandCollapseAll(): void {
    if (this?.data?.length && this.hasInnerTable) {
      this.isAllExpanded = !this.isAllExpanded;
      this.data.forEach((element: any) => {
        element.expanded = this.isAllExpanded;
      });
    }
  }

  revealTruncatedText(value: string, formatFn?: (argument: any) => string) {
    if (formatFn && value) {
      let formatedValue = formatFn(value).toString();
      if (formatedValue.endsWith("...")) {
        return value;
      }
    }
  }

  onCellClick(onClickFn: (element: any) => void, element: any, event: any) {
    if (onClickFn) {
      event.stopPropagation();
      onClickFn(element);
    }
  }

  getBrand(e: any): string {
    return "";
  }
  getHtml(html): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  reroute() {
    this.router.navigate([this.onRowClickReroute]);
  }
  // onDelete(evt){
  // }


  AssignControlDocuments(obj: any, list: any) {
    this.controlDocuments = obj.controlDocuments
    this.controlDocuments.forEach(e => {
      e.createdDate = this.datePipe.transform(e.createdDate, "MMM d, y, h:mm:ss a");
    });
  }


}

export interface Column {
  label: string;
  elementAttribute: string;
  isProjected: boolean;
  hidden: boolean;
  iconColumn: boolean;
  options?: Array<string>;
  isArray: boolean;
  hasChangeFunc: boolean;
  formatFn?;
  onClickFn?: (element: any) => void;
  columnClass?: string;
  columnWidth?: string;
  disableSorting?: boolean;
  isLink?: boolean;
  isHtml: boolean;
  isControlDocument: boolean;
  hasToolTip: boolean;
  toolTipText: string;
  iconName: string;
  isIconButtonColum: boolean;
  tooltipPosition: string;
  linkedControlTextCount: string;
}
