<div class="Pop-Up-Box pop-up-box">
	<div class="Pop-Up-Header">
		<span (click)="onClose(false)" class="material-icons">highlight_off</span>
		<h1>Control Details</h1>
		<div class="Pop-Up-Edit-Button" >
			<button class="Edit-Button btn"  (click)="getEditUrl(false)">Edit</button>
		</div>
	</div>

	<hr class="HR">

	<div class="container">
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Title</strong></h4>
                <p>{{event.title}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Description</strong></h4>
                <p [innerHTML] ="description"></p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Objective</strong></h4>   
                <p [innerHTML] = "objective"></p>

		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Auditing Guidlines</strong></h4>
                <p [innerHTML] = "auditingGuideLines"></p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Other Information</strong></h4>
                <p [innerHTML] = "otherInformation"></p>

		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>CIS Asset Types</strong></h4>
                <p *ngFor="let tag of event.cisAssetTypeIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Control Types</strong></h4>
                <p *ngFor="let tag of event.controlTypeIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Control Functions</strong></h4>
                <p *ngFor="let tag of event.controlFunctionIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>CIS Levels</strong></h4>
                <p *ngFor="let tag of event.cisLevelIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Primary Objectives</strong></h4>
                <p *ngFor="let tag of event.primaryObjectiveIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>SCF Domains</strong></h4>
                <p *ngFor="let tag of event.scfDomainIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Suggested Controls</strong></h4>
                <p *ngFor="let tag of event.suggestedControlIds" class="tag">{{tag.value}}</p>
		</div>
		<div class="Col-Lg-12 col-lg-12">
				<h4><strong>Suggested Control Ownership</strong></h4>
                <p *ngFor="let tag of event.scoIds" class="tag">{{tag.value}}</p>
		</div>
	</div>
</div>

