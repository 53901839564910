import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuggestedControlOwnershipService } from "src/app/Services/suggested-control-ownership.service";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-suggested-control-ownership",
  templateUrl: "./suggested-control-ownership.component.html",
  providers: [
    HasPermissionPipe,
  ],
})
export class SuggestedControlOwnershipComponent implements OnInit {
  heading = "Suggested Controls Ownership";
  subheading = "Suggested Controls Ownership of Control inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  constructor(
    private suggestedControlOwnershipService: SuggestedControlOwnershipService,
    private router: Router,
    private modalService: NgbModal,
    private hasPermissionPipe: HasPermissionPipe,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,


  ) {
    this.titleService.setTitle(this.heading);
  }

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  suggestedControlOwnershipId: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  route :any;

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionDeleteIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionEditIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];

  ngOnInit(): void {
    this.getAll();
    this.route = this.activatedRoute.snapshot;
  }

  getAll() {
    this.suggestedControlOwnershipService.getAll().subscribe((res) => {
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Dashboard/Setup/create-or-edit-suggested-control-ownership/" + event.id,
    ]);
  }

  onDelete(event) {
    this.suggestedControlOwnershipId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.suggestedControlOwnershipService
          .delete(this.suggestedControlOwnershipId)
          .subscribe((data) => {
            this.getAll();
            this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.suggestedControlOwnershipService
      .delete(this.suggestedControlOwnershipId)
      .subscribe((data) => {
        this.getAll();
        this.modalService.dismissAll();
      });
  }
  ngOnDestroy() {
    this.titleService.setTitle("");
  }
}
