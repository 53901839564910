export enum PermissionType {
  None = 0,
  SuggestedControlOwnership = 1,
  Questions = 2,
  Audits = 3,
  Controls = 4,
  Incidents = 5,
  CategoriesControlDocuments = 6,
  DataProtectionAgreements = 7,
  CISAssetTypes = 8,
  Customers = 9,
  SuggestedControls = 10,
  CategoriesKnowledgeDB = 11,
  Documents = 12,
  ControlFunctions = 13,
  DocumentTypes = 14,
  SCFDomains = 15,
  Chapters = 16,
  CISLevels = 17,
  PrimaryObjectives = 18,
  MaturityLevels = 19,
  ControlTypes = 20,
  ControlDocuments = 21,
  Documentation = 22,
  Users = 23,
  Permissions = 24,
  KnowledgeDataBase = 25,
  SideMenu = 26,
  DataImpactAssessment = 27,
  Roles = 28,
  InformationProcessRegister = 29,
  ImportDocument = 30,
  Compliance = 31,
  Tenant = 32,
  UserSettings = 33,
  ImportQuestions = 34,
  Department = 35,
  PublishDocument = 36,
  DPA = 37,
  Dashboard = 38,
  TPM = 39,
  ROPA = 40,
  DataRight = 41,
  Settings = 42,
  Integrations = 43,
  IncidentRegistry = 44
}

export enum PermissionAction {
  None = "none",
  Create = "canCreate",
  Read = "canRead",
  Update = "canUpdate",
  Delete = "canDelete"
}
export enum FormsSection {
  None = 'None',
  DPA = 'DPA',
  TPM = 'TPM',
  ROPA = 'ROPA',
  DataRight = 'DataRight',
  IncidentRegistry = 'IncidentRegistry'

}

export enum ROPAFileTypes {
  None = 0,
  GENERAL = 1,
  DPIASCREENING = 2,
  DPIA = 3,
  TIA = 4
}
export enum IncidentRegistryFileTypes {
  None = 0, 
  PostMortem = 1, 
}

export interface EntityPermissions {
  entityType: PermissionType;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}
