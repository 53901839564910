import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuditingService {
  updateControls = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }
  auditingbasePath = environment.apiUrl + "Auditing";
  AuditBasePath = environment.apiUrl + "Audit";
  SettingsBasePath = environment.apiUrl + "Settings";

  getAll(docIds): any {
    return this.http.post<any>(this.auditingbasePath + "/GetAll", docIds);
  }

  createAuditQuestionAnswer(model): any {
    return this.http.post<any>(
      this.auditingbasePath + "/CreateAuditingControl",
      model
    );
  }

  getAuditingId(auditId) {
    return this.http.get<any>(
      this.auditingbasePath + "/GetAuditingId/" + auditId
    );
  }

  getControlsQuestion(audtingModel) {
    return this.http.post<any>(
      this.auditingbasePath + "/GetControl",
      audtingModel
    );
  }

  //get data against audtingId customerId and docIds
  getData(audtingModel) {
    return this.http.post<any>(this.auditingbasePath + "/Get", audtingModel);
  }

  getDocumentationScore() {
    return this.http.get<any>(this.auditingbasePath + "/GetDocumnetationScore");
  }
  ApplyFilters(body) {
    return this.http.post<any>(this.auditingbasePath + "/ApplyFilters/", body);
  }

  applySorting(body) {
    return this.http.post<any>(this.auditingbasePath + "/GetAllSorted/", body);
  }

  getAuditProgress(body) {
    return this.http.post<any>(this.auditingbasePath + "/CalculateProgress/", body);
  }
  createAuditPageSettings(body) {
    return this.http.post<any>(this.AuditBasePath + "/SaveAuditPageSettings/", body);
  }
  getAuditPageSettings(AuditId) {
    return this.http.get<any>(this.SettingsBasePath + "/GetAuditPageSettings/" + AuditId);
  }

  UploadAuditingControlAttachment(body) {
    return this.http.post<any>(this.auditingbasePath + "/UploadAuditingControlsAttachment/", body);
  }


  closeAuditing(isClosed, auditingId) {
    return this.http.get(this.auditingbasePath + "/CloseAuditing/" + isClosed + "/" + auditingId);
  }


  GetAuditingStatus(auditingId) {
    return this.http.get<boolean>(this.auditingbasePath + "/GetAuditingStatus/" + auditingId);
  }

}
