import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Amplify } from "aws-amplify";
import aws_exports from "./app/aws-exports";
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfdXRRRmReUU1xWkI=');

Amplify.configure(aws_exports);
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.userPoolId,
      userPoolClientId: environment.clientId,
      identityPoolId: '',
      loginWith: {
        oauth: {
          domain: environment.domain,
          scopes: [
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: [ environment.applicationUrl],
          redirectSignOut: [ environment.applicationUrl],
          responseType: 'code',
        }
      },
    }
  }
});
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
