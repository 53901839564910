import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../environments/environment";

const basePath = environment.apiUrl + "Events";

@Injectable({
  providedIn: "root",
})
export class RolesServices {
  rolesBasePath = environment.apiUrl + "Roles";

  constructor(private http: HttpClient) { }



  getAllRoles(): any {
    return this.http.get<any>(this.rolesBasePath + "/All");
  }
  getAllMenus(): any {
    return this.http.get<any>(this.rolesBasePath + "/AllMenu");
  }

  getRoleMenu(roleId): any {
    return this.http.get<any>(this.rolesBasePath + "/GetRoleMenu/" + roleId);
  }
  getRoleAccess(): any {
    return this.http.get<any>(this.rolesBasePath + "/GetRoleAccess/");
  }
  saveRoleMenus(body): any {
    return this.http.post<any>(this.rolesBasePath + "/saveRoleMenu", body);
  }
  create(body): any {
    return this.http.post<any>(this.rolesBasePath + "/create", body);
  }
  update(body): any {
    return this.http.post<any>(this.rolesBasePath + "/update", body);
  }
  delete(body): any {
    return this.http.post<any>(this.rolesBasePath + "/Delete", body);
  }
  get(id): any {
    return this.http.get<any>(this.rolesBasePath + "/Get/" + id);
  }

  attachFile(file: any, eventId: number, caseId: number): any {
    let route = basePath + `/Documents`;
    if (eventId) {
      route += `/${eventId}`;
    }
    if (caseId) {
      route += `?caseId=${caseId}`;
    }

    const formData = new FormData();
    formData.append("File", file);

    return this.http.post(route, formData, {
      reportProgress: true,
      observe: "events",
    });
  }
}
