import { Location } from "@angular/common";
import { Component, } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CisLevelService } from "src/app/Services/cis-level.service";
import { NotificationService } from "src/app/Services/notification.service";

@Component({
  selector: "app-create-cs-level",
  templateUrl: "./create-cs-level.component.html",
  styleUrls: ["./create-cs-level.component.sass"],
})
export class CreateCsLevelComponent {
  heading = "CIS Levels";
  subheading = "CIS Levels that you will be uploading inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  cisLevelForm: UntypedFormGroup;
  cisLevelId: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cisLevelService: CisLevelService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location
  ) {
    this.cisLevelForm = this.formBuilder.group({
      name: ["", [Validators.required]],
    });

    this.route.params.subscribe((qp) => {
      let cisleveid = qp.id;
      this.cisLevelId = cisleveid;

      if (this.cisLevelId) {
        this.cisLevelService.getCISLevel(this.cisLevelId).subscribe((data) => {
          this.cisLevelForm.patchValue({
            name: data.name,
          });
        });
      }
    });
  }


  MoveBack() {
    this.locationService.back();
  }
  createDocumentType() {
    if (this.cisLevelForm.invalid) {
      return;
    } else if (this.cisLevelId) {
      this.cisLevelService
        .update({
          name: this.cisLevelForm.get("name").value,
          id: this.cisLevelId,
        })
        .subscribe(
          (data) => {
            this.notify.showInfo("Updated Successfully", "CIS Level");
            this.router.navigate(["/Dashboard/Setup/CISLevel"]);
          },
          (error) => {
            this.notify.showError("Error while updating", "CIS Level");
            this.router.navigate(["/Dashboard/Setup/CISLevel"]);
          }
        );
    } else {
      this.cisLevelService
        .create({
          name: this.cisLevelForm.get("name").value,
        })
        .subscribe(
          (data) => {
            this.notify.showSuccess("Created Successfully", "CIS Level");
            this.router.navigate(["/Dashboard/Setup/CISLevel"]);
          },
          (error) => {
            this.notify.showError("Error while creating", "CIS Level");
            this.router.navigate(["/Dashboard/Setup/CISLevel"]);
          }
        );
    }

  }
}
