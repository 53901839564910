import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SuperAdminServices {
  superAdminBasePath = environment.apiUrl + "SuperAdmin/";

  constructor(private http: HttpClient) { }

  LoginAdmin(subId) {
    return this.http.post<any>(this.superAdminBasePath + "AdminLogin", {
      subId: subId,
    });
  }
  getUser(id, tenantId) {
    let params = new HttpParams()
      .set('id', id)
      .set('TenantId', tenantId);
    return this.http.get<any>(this.superAdminBasePath + "GetUser", { params });
  }
  getTenant(id) {
    return this.http.get<any>(this.superAdminBasePath + "GetTenant/" + id);
  }
  getAllRoles(tenantId): any {
    return this.http.get<any>(this.superAdminBasePath + "GetAllRoles/" + tenantId);
  }
  recoverUser(id, tenantId): any {
    let params = new HttpParams()
      .set('id', id)
      .set('tenantId', tenantId);
    return this.http.get<any>(this.superAdminBasePath + "RecoverUser", { params });
  }

  deleteUser(id, tenantId): any {
    let params = new HttpParams()
      .set('id', id)
      .set('tenantId', tenantId);
    return this.http.get<any>(this.superAdminBasePath + "DeleteUser", { params });
  }
  createTenantUser(body): any {
    return this.http.post<any>(this.superAdminBasePath + "SendInvitation", body);
  }
  resetPassword(userId: string, password: string): any {
    return this.http.get<any>(this.superAdminBasePath + "ResetPassword/" + userId);
  }
  LinkToTenant(name: string, tenant: string): any {
    const body = {
      email: name,
      TenantId: tenant,
    };
    return this.http.post<any>(this.superAdminBasePath + "LinkToTenant", body);
  }
  update(body): any {
    return this.http.post<any>(this.superAdminBasePath + "Update", body);
  }
  createTenant(body): any {
    return this.http.post<any>(this.superAdminBasePath + "CreateTenant", body);
  }
}
