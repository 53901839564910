<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <span class="ps-2">Copyright 2022 - Atlas.com</span>
    </div>
    <div class="app-footer-right">
      <!-- <a class="btn btn-sm btn-primary me-2" href="https://dashboardpack.com">Homepage</a> -->
    </div>
  </div>
</div>
