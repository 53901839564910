
  <div class="row">
    <div class="main-card mb-3 card">
      <app-question-list [Questions]="Questions" [onEdit]="onQuestionEdit.bind(this)"
        [onDelete]="onQuestionDelete.bind(this)">
      </app-question-list>
      <div class="card-body">
        <form [formGroup]="questionForm" (ngSubmit)="save()">



          <!-- controls type -->
          <div class="row">
            <div class="position-relative mb-3 col-md-4">
              <app-atlas-input controlName="documentId" placeholder="Select Document" label="Document" [Options]="documentlist"
                [onChange]="loadChapter.bind(this)" type="selectSingle">
              </app-atlas-input>
            </div>
            <div class="position-relative mb-3 col-md-4">
              <app-atlas-input controlName="chapterId" placeholder="Select Chapter" label="Chapter" [Options]="chaptersList"
                [onChange]="loadControls.bind(this)" type="selectSingle">
              </app-atlas-input>
            </div>
            <div class="position-relative mb-3 col-md-4">
              <app-atlas-input controlName="controlId" placeholder="Select Control" label="Controls" [Options]="ControlsList"
                 type="selectSingle">
              </app-atlas-input>
            </div>
          </div>

          <div class="row">
            <div class="position-relative mb-3 col-md-6">
              <app-atlas-input controlName="Question" placeholder="Enter Question here" label="Question"
                    type="text">
              </app-atlas-input>
            </div>
            <div class="col-md-12">
              <div class="position-relative mb-3 ">
                <app-atlas-html-editor [descriptionHTMLFormate]="description" [Label]="lblDescription"
                  [onCreateCommand]="onCreate.bind(this)" [onChange]="descriptionChange.bind(this)"
                  [placeholder]="lblDescription"></app-atlas-html-editor>
              </div>
            </div>
            <div class="col-md-12">
              <div class="position-relative mb-3 ">
                <app-atlas-html-editor [descriptionHTMLFormate]="predefinedSuggestAction"
                  [Label]="lblPredefinedSuggestedAction" [onCreateCommand]="onCreate.bind(this)"
                  [onChange]="predefinedSuggestActionChange.bind(this)"
                  [placeholder]="lblPredefinedSuggestedAction"></app-atlas-html-editor>
              </div>
            </div>


            <!-- controls type -->
            <div class="row">
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Control Types</mat-label>
                  <mat-select [(ngModel)]="controltypeids" [ngModelOptions]="{standalone: true}" name="controltypeids"
                    multiple>
                    <mat-option *ngFor="let control of controlTypelist" [value]="control.id">{{control.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Primary Objectives</mat-label>
                  <mat-select [(ngModel)]="primaryobjectiveids" [ngModelOptions]="{standalone: true}"
                    name="primaryobjectiveids" multiple style="background-color: none;">
                    <mat-option *ngFor="let primaryobjective of primaryObjectiveslist" [value]="primaryobjective.id">
                      {{primaryobjective.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Control Functions</mat-label>
                  <mat-select [(ngModel)]="controlfunctionids" [ngModelOptions]="{standalone: true}"
                    name="controlfunctionids" multiple style="background-color: none;">
                    <mat-option *ngFor="let controlfunction of controlFunctionlist" [value]="controlfunction.id">
                      {{controlfunction.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <!-- suggested controls -->
            <div class="row">
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Suggested Controls</mat-label>
                  <mat-select [(ngModel)]="suggestedcontrolids" [ngModelOptions]="{standalone: true}"
                    name="suggestedcontrolids" multiple style="background-color: none;">
                    <mat-option *ngFor="let suggestedcontrol of suggestedControllist" [value]="suggestedcontrol.id">
                      {{suggestedcontrol.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Suggested Controls Ownership</mat-label>
                  <mat-select [(ngModel)]="suggestedcontrolownershipids" [ngModelOptions]="{standalone: true}"
                    name="suggestedcontrolownershipids" multiple style="background-color: none;">
                    <mat-option *ngFor="let sco of suggestedControlOwnershiplist" [value]="sco.id">{{sco.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>SCF Domains</mat-label>
                  <mat-select [(ngModel)]="scfdomainids" name="scfdomainids" [ngModelOptions]="{standalone: true}"
                    multiple style="background-color: none;">
                    <mat-option *ngFor="let scfdomain of scfdomainslist" [value]="scfdomain.id">{{scfdomain.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- cis levels -->
            <div class="row">
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>CIS Levels</mat-label>
                  <mat-select [(ngModel)]="cislevelids" [ngModelOptions]="{standalone: true}" name="cislevelids"
                    multiple style="background-color: none;">
                    <mat-option *ngFor="let cislevel of cisLevellist" [value]="cislevel.id">{{cislevel.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="position-relative mb-3 col-md-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>CIS Asset Types</mat-label>
                  <mat-select [(ngModel)]="cisassettypeids" name="cisassettypeids" [ngModelOptions]="{standalone: true}"
                    multiple style="background-color: none;">
                    <mat-option *ngFor="let cisassettype of cisAssetTypelist" [value]="cisassettype.id">
                      {{cisassettype.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- cis asset type -->
            <app-linked-questions-list [LinkedQuestions]="linkedQuestions" [onDelete]="deleteLinkedQuestion.bind(this)">

            </app-linked-questions-list>
            <div  *ngIf="questionId" class="d-flex">
              <span href="#" (click)=" showlinkQuestionComponent()" class="link-text">
                + Link Question
              </span>
            </div>
            <app-link-question *ngIf="linkQuestion" [onClose]="onClose.bind(this)"
              (linkQuestionOut)="linkQuestionfunc($event)"
              ></app-link-question>

          </div>
          <button class="mt-1 btn btn-success btn-atlas btn-width-manual" [disabled]="questionForm.invalid" type="submit">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel btn-width-manual" (click)="cancel()" type="button"
            >Cancel</button>
        </form>
        <div class="row mt-2">
          <div class="col-4 ms-auto">
            <button color="primary" class="btn btn-atlas btn-full-width" type="button" [disabled]="Questions.length==0 && enableOnEdit == true" (click)="saveAll()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
  </div>
