import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DepartmentsService } from "src/app/Services/departments.service";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.sass'],
  providers:[HasPermissionPipe]
})
export class DepartmentsComponent implements OnInit {
  heading = "Departments";
  subheading = "Departments inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  constructor(
    private departmentsService: DepartmentsService,
    private router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private titleService: TitleService,
  ) {
    this.titleService.setTitle("Departments");
  }

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  departmentId: any;
  route: any;
  columns: Partial<Column>[] = [];
  customPageSizeOptions = globalConstants.customPageSizeOptions;


  initColumns() {
    this.columns = [
      {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];
  }
  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }

  getAll() {
    this.departmentsService.getAll().subscribe((res) => {
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "Dashboard/Setup/create-or-edit-department/" + event.id,
    ]);
  }

  onDelete(event) {
    this.departmentId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.departmentsService
          .delete(this.departmentId)
          .subscribe((data) => {
            this.getAll();
            this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.departmentsService.delete(this.departmentId).subscribe((data) => {
      this.getAll();
      this.modalService.dismissAll();
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle("");
  }
}
