import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuditService {
  constructor(private http: HttpClient) {}
  auditbasePath = environment.apiUrl + "Audit";
  getAll(body): any {
    return this.http.post<any>(this.auditbasePath + "/GetAll", body);
  }

  get(id): any {
    return this.http.get<any>(this.auditbasePath + "/Get/" + id);
  }

  getAuditEditDocumentDetails(id): any {
    return this.http.get<any>(this.auditbasePath + "/AuditEditDetails/" + id);
  }

  create(body): any {
    return this.http.post<any>(this.auditbasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.auditbasePath + "/Update", body);
  }

  delete(id): any {
    return this.http.delete<any>(this.auditbasePath + "/Delete/" + id, {});
  }

  getList(id) {
    return this.http.get<any>(this.auditbasePath + "/GetList/" + id);
  }

  getLeafChilds(id: string) {
    return this.http.get<any>(this.auditbasePath + "/GetLeafChilds/" + id);
  }

  updateAuditStatus(status,auditId)
  {
    return this.http.get<boolean>(this.auditbasePath + "/UpdateAuditStatus/"+ status +"/" +auditId)
  }

  DuplicateAudit(id: string) {
    return this.http.get<any>(this.auditbasePath + "/DuplicateAdit/" + id);
  }

  downloadPdf(html: string, auditName: string) {
    return this.http.post(this.auditbasePath + "/DownloadAuditPdf", { Html: html, AuditName: auditName }, {
      responseType: 'blob' 
    });
  }

}
