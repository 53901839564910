<mat-toolbar *ngIf="FilterType == filterTypeEnum.Audits">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyAuditFilter.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
        </span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="favourite-icon-line">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
            <div class="ms-2" *ngIf="canCreate">
                <button class="btn btn-atlas btn-create-filter-record" routerLink="/Audit/Create">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <span class="ms-1">
                        Create New
                    </span>
                </button>
            </div>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar *ngIf="FilterType == filterTypeEnum.ControlDocuments">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearControlDocumentSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType"
                [onApplyFilter]="ApplyControlDocumentFilter.bind(this)" [inputValue]="parentInputValue">
            </app-filter-button>
        </span>
        <span class="spacer"></span>
        <div class="inline-block me-2 statusbox">
            <mat-select #selectBox class="statusdropdown" [(ngModel)]="selectedStatus"
                (selectionChange)="changeStatus(selectedStatus)">
                <mat-select-trigger>
                    {{ getSelectedStatusText() || 'Select Status' }}
                </mat-select-trigger>
                <mat-option [value]="0">Any</mat-option>
                <mat-option [value]="1">Draft</mat-option>
                <mat-option [value]="2">Minor</mat-option>
                <mat-option [value]="3">Major</mat-option>
            </mat-select>
            <svg (click)="toggleSelect()" xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 14 6"
                fill="none" class="custom-select-icon">
                <path
                    d="M7 6C6.72592 6.00037 6.45444 5.95805 6.20118 5.87545C5.94792 5.79286 5.71786 5.67162 5.52423 5.51871L0 1.16331L1.47577 0L7 4.35458L12.5242 0L14 1.16331L8.47577 5.51789C8.28223 5.67095 8.05221 5.79234 7.79894 5.87507C7.54567 5.95781 7.27415 6.00027 7 6Z"
                    fill="#234F92" />
            </svg>
        </div>

        <div class="favorite-container">

            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon>star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
            <div class="ms-2" *ngIf="canCreate">
                <button class="btn btn-atlas btn-create-filter-record"
                    routerLink="/Documentation/ControlDocumentsCreate">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <span class="ms-1">
                        Create New
                    </span>
                </button>
            </div>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar *ngIf="FilterType == filterTypeEnum.DPA">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearDPASearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyDPAFilters.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplyDPAFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2" *ngIf="canCreate">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreateDPA()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_400_3434)">
                        <path
                            d="M5.38739 3.67019L7.33801 1.71888L7.33336 12.0107C7.33336 12.3789 7.63182 12.6774 8 12.6774C8.36818 12.6774 8.66665 12.3789 8.66665 12.0107L8.6713 1.73022L10.6126 3.67219C10.8775 3.92797 11.2995 3.92066 11.5553 3.65582C11.8048 3.39748 11.8048 2.98789 11.5553 2.72955L9.41464 0.586241C8.63384 -0.195059 7.36754 -0.195466 6.58624 0.585335C6.58593 0.585647 6.58565 0.585928 6.58534 0.586241L4.44471 2.72755C4.18894 2.99239 4.19625 3.41442 4.46109 3.67019C4.71943 3.91972 5.12902 3.91972 5.38739 3.67019Z"
                            fill="white" />
                        <path
                            d="M15.3333 10.6667C14.9651 10.6667 14.6666 10.9652 14.6666 11.3334V14C14.6666 14.3682 14.3682 14.6666 14 14.6666H2.00018C1.632 14.6666 1.33354 14.3682 1.33354 14V11.3334C1.33354 10.9652 1.03507 10.6667 0.66689 10.6667C0.298708 10.6667 0.000244141 10.9652 0.000244141 11.3334V14C0.000244141 15.1045 0.895667 16 2.00021 16H14C15.1046 16 16 15.1045 16 14V11.3334C15.9999 10.9652 15.7015 10.6667 15.3333 10.6667Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_400_3434">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span class="ms-1">
                    Upload DPA
                </span>
            </button>
        </div>
    </div>

</mat-toolbar>
<mat-toolbar *ngIf="FilterType == filterTypeEnum.Questions">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearQuestionsSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyQuestionFilters.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplyQuestionFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreateQuestion()">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M1 6H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

                <span class="ms-1">
                    Create New
                </span>

            </button>
        </div>
    </div>

</mat-toolbar>

<mat-toolbar *ngIf="FilterType == filterTypeEnum.TPM">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearTPMSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyTPMFilters.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplyTPMFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2" *ngIf="canCreate">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreateTPM()">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

                <span class="ms-1">
                    New Third Party
                </span>
            </button>
        </div>
    </div>
</mat-toolbar>

<mat-toolbar *ngIf="FilterType == filterTypeEnum.ROPA">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearROPASearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyROPAFilters.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplyROPAFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2" *ngIf="canCreate">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreateROPA()">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

                <span class="ms-1">
                    New Processing
                </span>
            </button>
        </div>
    </div>
</mat-toolbar>

<mat-toolbar *ngIf="FilterType == filterTypeEnum.DataRight">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="cleardataRightSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType"
                [onApplyFilter]="ApplydataRightFilters.bind(this)" [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplydataRightFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2" *ngIf="canCreate">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreatedataRight()">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

                <span class="ms-1">
                    New Data Subcject Requests
                </span>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar *ngIf="FilterType == filterTypeEnum.IncidentRegistry">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearincIdentRegistrySearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType"
                [onApplyFilter]="ApplyIncidentRegistryFilters.bind(this)" [inputValue]="parentInputValue">
            </app-filter-button>
            <div class="reset-button" (click)="ApplyIncidentRegistryFilters({}, true)">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.2425 2.53857C12.5186 2.53857 12.7425 2.76243 12.7425 3.03857V5.867C12.7425 6.14314 12.5186 6.367 12.2425 6.367H9.41405C9.13791 6.367 8.91405 6.14314 8.91405 5.867C8.91405 5.59086 9.13791 5.367 9.41405 5.367H10.9876C9.09072 3.87162 6.33231 3.999 4.58215 5.74915C2.69462 7.63668 2.69462 10.697 4.58215 12.5845C6.46969 14.472 9.52999 14.472 11.4175 12.5845C12.6821 11.3199 13.1001 9.52868 12.6691 7.91474C12.5978 7.64795 12.7564 7.37391 13.0232 7.30267C13.29 7.23142 13.564 7.38994 13.6352 7.65673C14.1546 9.60138 13.6518 11.7644 12.1246 13.2916C9.84657 15.5697 6.15311 15.5697 3.87505 13.2916C1.59699 11.0136 1.59699 7.3201 3.87505 5.04204C6.02973 2.88737 9.45064 2.77067 11.7425 4.69195V3.03857C11.7425 2.76243 11.9663 2.53857 12.2425 2.53857Z"
                        fill="#2D577C" />
                </svg>
                <span>
                    Reset All
                </span>
            </div>
        </span>
        <span class="spacer"></span>
        <div class="favorite-container">
            <svg (click)="openTableSetting()" class="me-2 tablesettings-icon" width="45" height="45" viewBox="0 0 40 34"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
                <g clip-path="url(#clip0_400_5674)">
                    <path
                        d="M18 9H15.3333C13.4953 9 12 10.4953 12 12.3333V13.6667C12 14.402 12.598 15 13.3333 15H18C18.7353 15 19.3333 14.402 19.3333 13.6667V10.3333C19.3333 9.598 18.7353 9 18 9ZM13.3333 13.6667V12.3333C13.3333 11.2307 14.2307 10.3333 15.3333 10.3333H18L18.0013 13.6667H13.3333ZM26.6667 19H22C21.2647 19 20.6667 19.598 20.6667 20.3333V23.6667C20.6667 24.402 21.2647 25 22 25H24.6667C26.5047 25 28 23.5047 28 21.6667V20.3333C28 19.598 27.402 19 26.6667 19ZM26.6667 21.6667C26.6667 22.7693 25.7693 23.6667 24.6667 23.6667H22V20.3333H26.6667V21.6667ZM24.6667 9H22C21.2647 9 20.6667 9.598 20.6667 10.3333V16.3333C20.6667 17.0687 21.2647 17.6667 22 17.6667H26.6667C27.402 17.6667 28 17.0687 28 16.3333V12.3333C28 10.4953 26.5047 9 24.6667 9ZM22 16.3333V10.3333H24.6667C25.7693 10.3333 26.6667 11.2307 26.6667 12.3333L26.668 16.3333H22ZM18 16.3333H13.3333C12.598 16.3333 12 16.9313 12 17.6667V21.6667C12 23.5047 13.4953 25 15.3333 25H18C18.7353 25 19.3333 24.402 19.3333 23.6667V17.6667C19.3333 16.9313 18.7353 16.3333 18 16.3333ZM15.3333 23.6667C14.2307 23.6667 13.3333 22.7693 13.3333 21.6667V17.6667H18L18.0013 23.6667H15.3333Z"
                        fill="#2D577C" />
                </g>
                <defs>
                    <clipPath id="clip0_400_5674">
                        <rect width="16" height="16" fill="white" transform="translate(12 9)" />
                    </clipPath>
                </defs>
            </svg>
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon class="star-Icon">star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
        <div class="ms-2" *ngIf="canCreate">
            <button class="btn btn-atlas btn-create-filter-record" (click)="navigateToCreateIncidentRegistry()">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50012 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M1 5.99997H10" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>

                <span class="ms-1">
                    New Incident Registry
                </span>
            </button>
        </div>
    </div>
</mat-toolbar>