<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Chapter</h5>
        <form [formGroup]="chapterForm" (ngSubmit)="createsChapter()">

          <div class="position-relative mb-3">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Select Document</mat-label>
              <mat-select formControlName="documentId" (selectionChange)="loadChapters($event)">
                <mat-option *ngFor="let document of documentList" [value]="document.id">
                  {{document.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="position-relative mb-3">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Select Parent</mat-label>
              <mat-select formControlName="parentId">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">
                  {{chapter.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>



          <div class="position-relative mb-3">
            <label for="chapterId" class="form-label">Chapter Id</label>
            <input name="chapterId" formControlName="chapterId" id="chapterId" placeholder="Chapter Id" type="text"
              class="form-control">
          </div>
          <div class="position-relative mb-3">
            <label for="title" class="form-label">Title</label>
            <input name="title" formControlName="title" id="title" placeholder="Title" type="text" class="form-control">
          </div>
            <div class="position-relative mb-3 " id="target">
              <mat-label>Description</mat-label>
              <div id="rteContainer">
                <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)" [onChange]="onChange.bind(this)"
                  [placeholder]="'Description'" [descriptionHTMLFormate]="descriptionHTMLFormate">
                </app-atlas-html-editor>
              </div>

            </div>
          <!-- <div class="position-relative mb-3">
              <label for="principalIntent" class="form-label">Principal Intent</label>
              <input name="" formControlName="" id="principalIntent" placeholder="Principal Intent" type="text" class="form-control">
            </div>
  
            <div class="position-relative mb-3">
              <label for="spdPrincipal" class="form-label">SPD Principal</label>
              <input name="spdPrincipal" formControlName="spdPrincipal" id="spdPrincipal" placeholder="SPD Principal" type="text" class="form-control">
            </div> -->
          <button class="mt-1 btn btn-success btn-atlas" type="submit">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</div>