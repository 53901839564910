<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Role</h5>
        <form [formGroup]="roleForm" (ngSubmit)="sendRole()">
          <div class="position-relative mb-3">
            <label for="exampleEmail" class="form-label">Name</label>
            <input name="name" id="userName" formControlName="name" type="email" placeholder="Role Name" type="email"
              class="form-control">
            <small *ngIf="roleForm.invalid" class="text text-danger">Required</small>
          </div>
          <button type="submit" class="mt-1 btn btn-success">Submit</button>
          <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</div>