import { Component, Input, OnInit } from '@angular/core';
import { FilterType } from '../commen/Enums';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterFormConfig } from '../commen/Models';
import { FilterService } from '../Services/FilterService';
import { CustomerService } from 'src/app/Services/customer.service';
import { DocumentService } from 'src/app/Services/document.service';
import { DatePipe } from '@angular/common';
import { AuditStatus } from 'src/app/Common/Enums/Atlas-Enums';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  isFilterPopupOpen: boolean = false;
  @Input() filterType: FilterType;
  @Input() onApplyFilter: (filters: any) => void;
  @Input()
  set inputValue(value: any) {
    if (value) {
      this.updateFilterCount(value);
    }
  }
  filterForm: UntypedFormGroup;
  filterCount: number = 0;
  public filterTypeEnum = FilterType;
  auditStatus: any = [AuditStatus.New, AuditStatus.Ongoing, AuditStatus.Closed, AuditStatus.Reopened];
  auditStatusValues: any = AuditStatus
  constructor(private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    private customService: CustomerService,
    private documentService: DocumentService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initializeForm(false);
  }
  initializeForm(open): void {
    this.filterForm = this.formBuilder.group({});
    const formControls = FilterFormConfig[this.filterType] || {};
    for (const key in formControls) {
      if (formControls.hasOwnProperty(key)) {
        this.filterForm.addControl(key, this.formBuilder.control(formControls[key]));
      }
    }
    if (open) {
      this.filterService.getFilterData(this.getFilterTypeString(this.filterType)).subscribe(data => {
        if (data) {
          for (const key in formControls) {
            if (formControls.hasOwnProperty(key) && data.hasOwnProperty(key)) {
              this.filterForm.get(key)?.setValue(data[key]);
            }
          }
        }
      });
    }

  }
  getFilterTypeString(filterType: FilterType): string {
    if (filterType) {
      return FilterType[filterType];
    }
    return FilterType[FilterType.None];
  }

  click() {
    this.isFilterPopupOpen = true;
    switch (this.filterType) {
      case FilterType.Audits:
        this.fetchAuditsData();
        this.initializeForm(true);
        break;
      default:
        break;
    }
  }
  closeFilterPopup(): void {
    this.isFilterPopupOpen = false;
    console.log("executing")
  }
  clear() {
    this.filterForm.reset();
  }
  excludeKeys = ['startDateFrom', 'startDateTo', 'endDateTo', 'endDateFrom', 'pagingParamsDTO', 'pageNumber', 'filterType', 'keyWords'];
  save(): void {
    var values = this.filterForm.value;
    this.updateFilterCount(values)
    if (this.filterType == FilterType.Audits) {
      if (values.startDateFrom) {
        values.startDateFrom = this.datePipe.transform(values.startDateFrom, 'yyyy-MM-dd');
      }
      if (values.startDateTo) {
        values.startDateTo = this.datePipe.transform(values.startDateTo, 'yyyy-MM-dd');
      }
      if (values.endDateFrom) {
        values.endDateFrom = this.datePipe.transform(values.endDateFrom, 'yyyy-MM-dd');
      }
      if (values.endDateTo) {
        values.endDateTo = this.datePipe.transform(values.endDateTo, 'yyyy-MM-dd');
      }
    }
    this.filterService.save(this.filterForm.value, this.getFilterTypeString(this.filterType)).subscribe(data => {
      if (this.onApplyFilter) {
        this.isFilterPopupOpen = false;
        this.onApplyFilter(this.filterForm.value);
      }
    })

  }
  updateFilterCount(filters) {
    let count = 0;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const controlValue = filters[key];
        if (controlValue && !this.excludeKeys.includes(key)) {
          count++;
        }
      }
    }
    if (this.filterType == FilterType.Audits && (filters['startDateFrom'] || filters['startDateTo'])) {
      count++;
    }
    if (this.filterType == FilterType.Audits && (filters['endDateFrom'] || filters['endDateTo'])) {
      count++;
    }
    this.filterCount = count;
  }
  //getAuditsInformation
  customers = [];
  documents = [];
  fetchAuditsData() {
    this.customService.getAll().subscribe(data => {
      this.customers = data;
    })
    this.documentService.getAll().subscribe(data => {
      this.documents = data;
    })
  }
}
