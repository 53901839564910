import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, HostListener, Input, Renderer2, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPen, faWindowClose, faPrint, faSave, faFile } from '@fortawesome/free-solid-svg-icons';
import { RevisionHistoryService } from 'src/app/Services/revision-history.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { globalConstants } from 'src/constants/global-constants';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { ControlService } from 'src/app/Services/control.service';
import { DateHelper } from 'src/app/Common/Helpers/date.helper';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ControlDocumentsServices } from 'src/app/Services/control-documents.services';


@Component({
  selector: 'app-revision-history',
  templateUrl: './revision-history.component.html',
  styleUrls: ['./revision-history.component.scss'],
})
export class RevisionHistoryComponent implements AfterViewChecked {
  heading = "Control Document Name";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  faPlus = faPen;
  faPrint = faPrint;
  faSave = faSave;
  faFile = faFile;
  faWindowClose = faWindowClose
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  isLoading = false;
  revisionHistoryData: any;
  showComparisonPopup: boolean = false;
  compareWith: number;
  compareTo: number;
  Id: any;
  @Input()
  onClose: (closeboth) => {};
  controlDocId: any;
  openDetails: boolean;
  selectedControlDocument: any;
  isIncludeDraftChecked: boolean = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  LinkForLink: any;
  linkedControlsList: any;
  showControlListPopUp: boolean = false;
  controlDetails: any;
  openControlDetails: boolean = false;
  openDiffViewer: boolean = false;
  haslinkClass: boolean = false;
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    console.log("click function called")
    this.getParentElByClassName(event.target)
    if (!this.haslinkClass) {
      this.dropdown.nativeElement.style.display = 'none';
    }
  }
  getHtml(html): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  getParentElByClassName(target) {
    let actualNode = target.parentElement;
    this.haslinkClass = false
    while (actualNode !== null) {
      if (actualNode == null)
        break;
      if (actualNode.className == "linkClass") {
        this.haslinkClass = true
        break;
      }
      actualNode = actualNode.parentElement;
    }
    return actualNode;;
  }


  CustomLinkFuntion(event) {
    event.preventDefault();
    const linkEl = event.target.closest('a');
    if (linkEl?.href?.includes('Control-')) {
      this.dropdown.nativeElement.style.display = 'block';
      this.dropdown.nativeElement.style.left = `${event.clientX}px`;
      this.dropdown.nativeElement.style.top = `${event.clientY}px`;
      this.LinkForLink = linkEl.href;
    }
    else if (linkEl) {
      this.LinkForLink = null;
      this.dropdown.nativeElement.style.display = 'none';
    }
    else {
      this.dropdown.nativeElement.style.display = 'none';
      this.LinkForLink = null;
    }
    event.preventDefault();


  }
  ViewControl(Link: string) {

    if (Link) {
      this.isLoading = true;
      this.dropdown.nativeElement.style.display = 'none';
      let LinkControl = Link.split('Control-');
      LinkControl.shift();

      this.controlServices.getControlsTitle(LinkControl).subscribe(res => {
        this.linkedControlsList = res;
        this.isLoading = false
      },
        error => { this.isLoading = false })

      this.showControlListPopUp = true
    }
  }

  closeComparisonPopup() {
    this.showComparisonPopup = false
  }
  @Input() set controlDocumentId(value: any) {
    if (value) {
      this.Id = value;
      this.revisionHistoryService.get({ controlDocumentId: value, isDraftIncluded: this.isIncludeDraftChecked }).subscribe(data => {
        const permanentTimeZone = this.dateHelper.getPermanentTimeZone();
        let offset = this.dateHelper.getOffsetForMassTransactions(
          this.dateHelper.getUTCTImeZone(),
          permanentTimeZone, data.createdDate
        );
        let dates = this.dateHelper.setOffset(
          data.createdDate,
          data.createdDate,
          offset
        );
        data.createdDate = new Date(dates.start);
        data.revisionHistoryList = this.calculateTImeZone(data.revisionHistoryList);
        this.revisionHistoryData = data;
        this.heading = data.name
      })
    }
  }
  calculateTImeZone(res) {
    let controlDocuments = [];
    res.map(d => {
      const permanentTimeZone = this.dateHelper.getPermanentTimeZone();
      let offset = this.dateHelper.getOffsetForMassTransactions(
        this.dateHelper.getUTCTImeZone(),
        permanentTimeZone, d.createdDate
      );
      let dates = this.dateHelper.setOffset(
        d.createdDate,
        d.createdDate,
        offset
      );
      d.createdDate = new Date(dates.start);
      controlDocuments.push(d);
    })
    return controlDocuments;
  }
  controlsIds = [];
  showControlListPopUpPopupLinked = false
  showControls(event) {
    let ids = [];
    let currentElement = event.target;
    while (currentElement) {
      if (currentElement?.id.includes('Control-')) {
        ids.push(currentElement.id.replace('Control-', ''));
      }
      currentElement = currentElement.parentNode;
    }
    const idsString = 'Control-' + ids.join('Control-');
    if (event.target.localName == 'span' && event.target.id.includes('Control-')) {
      event.preventDefault();
      let LinkControl = idsString.split('Control-');
      LinkControl.shift();
      this.controlsIds = LinkControl;
      this.showControlListPopUp = true
      this.getLinkedControls(this.controlsIds);
    }
  }
  constructor(private route: ActivatedRoute,
    private revisionHistoryService: RevisionHistoryService,
    private datePipe: DatePipe,
    private dateHelper: DateHelper,
    private router: Router,
    private renderer: Renderer2, private elementRef: ElementRef,
    private controlServices: ControlService,
    protected _sanitizer: DomSanitizer,
    private controlDocumentsServices: ControlDocumentsServices
  ) {
    (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.route.params.subscribe(qp => {
      let _controlDocumentId = qp.id;
      if (_controlDocumentId != null)
        this.controlDocumentId = _controlDocumentId;
    })
  }

  ngAfterViewChecked(): void {
    const elements = this.elementRef.nativeElement.querySelectorAll('.linkClass');
    elements.forEach(element => {
      this.renderer.listen(element, 'click', (event) => {
        event.preventDefault();
        const linkEl = event.target.closest('a');
        if (linkEl?.href?.includes('Control-')) {
          this.dropdown.nativeElement.style.display = 'block';
          this.dropdown.nativeElement.style.left = `${event.clientX}px`;
          this.dropdown.nativeElement.style.top = `${event.clientY}px`;
          this.LinkForLink = linkEl.href;
        }
        else if (linkEl) {
          this.LinkForLink = null;
          this.dropdown.nativeElement.style.display = 'none';
        }
        else {
          this.dropdown.nativeElement.style.display = 'none';
          this.LinkForLink = null;
        }
        event.preventDefault();
      });
    });
  }


  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },

    {
      label: "Revision",
      elementAttribute: "versionWithStatus",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Document Owner",
      elementAttribute: "documentOwner",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Updated By",
      elementAttribute: "updatedBy",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Date",
      elementAttribute: "createdDate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      formatFn: (date: Date) => {
        let dateString = this.datePipe.transform(date, "MMM d, y, h:mm:ss a");
        if (dateString) {
          return dateString;
        } else {
          return "";
        }
      },
    },

    {
      label: "Change Log",
      elementAttribute: "logChange",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Compare",
      elementAttribute: "versionList",
      columnClass: "matters-TypeWidth",
      isLink: false,
      isArray: true,
      hasChangeFunc: true,
      columnWidth: "200px",
    },
  ];

  Edit() {
    this.router.navigate([
      "/Documentation/ControlDocumentsCreate/" + this.Id,
    ]);
  }
  arrowClose = true;
  getArrowIcon() {
    this.arrowClose = !this.arrowClose;
  }
  previouseHTML = '';
  CurrentHTML = '';
  Toggle(event) {
    if (event && event.revisionHistoryList && event.revisionHistoryList[0]) {
      this.CurrentHTML = event.descriptionHTMLFormate;
      var auditTrailId = event.status == 'Draft' ? event.revisionHistoryList[0].controlAuditTrailId : event.revisionHistoryList[1].controlAuditTrailId;
      this.controlDocumentsServices.getAuditTrailById(auditTrailId).subscribe(data => {
        this.previouseHTML = data.descriptionHTMLFormate;
        this.openDiffViewer = true;
      })
    }
  }
  onVersionChange(obj) {
    this.compareWith = obj.currentVersion;
    this.compareTo = obj.previousVersion;
    this.controlDocId = obj.controlDocumentId
    this.showComparisonPopup = true
  }

  formatDate(date) {
    let dateString = this.datePipe.transform(date, "MMM d, y, h:mm:ss a");
    if (dateString) {
      return dateString;
    } else {
      return "";
    }

  }

  saveTableAsPDF() {
    const documentDefinition: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              width: '100%',
              text: [
                { text: 'Control Document: ', bold: true },
                { text: this.revisionHistoryData.name },
              ],
              style: 'Header'
            }
          ]
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: 'Categories: ', bold: true },
                { text: this.revisionHistoryData.category },
              ],

            },
            {
              width: '50%',
              text: [
                { text: 'Status: ', bold: true },
                { text: this.revisionHistoryData.status },
              ],

            },
          ],
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: 'Time/Date: ', bold: true },
                { text: this.formatDate(this.revisionHistoryData.createdDate) },
              ],
            },
            {
              width: '50%',
              text: [
                { text: 'Author: ', bold: true },
                { text: this.revisionHistoryData.author },
              ],
              margin: [0, 0, 0, 20],
            },
          ]
        },
        {
          table: {
            headerRows: 1,
            body: [
              ['Revision', 'Author', 'Date', 'Description'],
              ...this.revisionHistoryData.revisionHistoryList.map(p => ([p.version || " ",
              (p.documentOwner !== null && p.documentOwner !== undefined) ? p.documentOwner : "",
              this.formatDate(p.createdDate), (p.description || " ")])),
            ]
          }
        },
        {
          text: 'Description',
          style: 'sectionHeader',
        },
        {
          text: this.revisionHistoryData.description,
        },
        {
          text: 'Controls Associated With This Control Document',
          style: 'sectionHeader',
        },
        {
          ul: this.revisionHistoryData.associatedControls.map((control: any) => {
            return control.title;
          }),
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        Header: {
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20],
        }
      },
    };
    let pdfName = this.revisionHistoryData.name + ' Revision History'
    pdfMake.createPdf(documentDefinition).download(pdfName);
  }

  printTableAsPDF() {
    const documentDefinition: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              width: '100%',
              text: [
                { text: 'Control Document: ', bold: true },
                { text: this.revisionHistoryData.name },
              ],
              style: 'Header'
            }
          ]
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: 'Categories: ', bold: true },
                { text: this.revisionHistoryData.category },
              ],

            },
            {
              width: '50%',
              text: [
                { text: 'Status: ', bold: true },
                { text: this.revisionHistoryData.status },
              ],

            },
          ],
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: 'Time/Date: ', bold: true },
                { text: this.formatDate(this.revisionHistoryData.createdDate) },
              ],
            },
            {
              width: '50%',
              text: [
                { text: 'Author: ', bold: true },
                { text: this.revisionHistoryData.author },
              ],
              margin: [0, 0, 0, 20],
            },
          ]
        },
        {
          table: {
            headerRows: 1,
            body: [
              ['Revision', 'Author', 'Date', 'Description'],
              ...this.revisionHistoryData.revisionHistoryList.map(p => ([p.version || " ",
              (p.documentOwner !== null && p.documentOwner !== undefined) ? p.documentOwner : "",
              this.formatDate(p.createdDate), (p.description || " ")])),

            ]
          }
        },
        {
          text: 'Description',
          style: 'sectionHeader',
        },
        {
          text: this.revisionHistoryData.description,
        },
        {
          text: 'Controls Associated With This Control Document',
          style: 'sectionHeader',
        },
        {
          ul: this.revisionHistoryData.associatedControls.map((control: any) => {
            return control.title;
          }),
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        Header: {
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20],
        }
      },
    };
    pdfMake.createPdf(documentDefinition).print();
  }

  onOpenDetails(event) {
    this.isLoading = true;
    let controlDocumentObj = {
      id: event.controlDocumentId,
      status: event.status,
      version: event.version,
    };
    this.revisionHistoryService
      .getControlDocumentOldVersion(controlDocumentObj)
      .subscribe(
        (data) => {
          if (data) {
            this.openDetails = true;
            this.selectedControlDocument = data;
          }
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  onCloseDetail(event) {
    this.openDetails = false;
  }

  includeDraft() {
    this.isLoading = true;
    this.revisionHistoryService.get({ controlDocumentId: this.Id, isDraftIncluded: this.isIncludeDraftChecked }).subscribe(res => {
      this.revisionHistoryData = res;
      this.isLoading = false;
    },
      err => {
        this.isLoading = false;
        console.log(err);
      })
  }

  closeControlListPopUp(value) {
    this.showControlListPopUp = value;
  }
  closePopup() {
    this.showControlListPopUpPopupLinked = false;
  }
  onCloseControlDetail(event) {
    this.openControlDetails = false;
    this.openDiffViewer = false;
  }

  OpenControlDetails(Id) {
    this.isLoading = true;
    this.controlServices.get(Id).subscribe((data) => {
      this.isLoading = false;
      this.openControlDetails = true;
      this.showControlListPopUp = false;
      this.controlDetails = data;
      console.log(data)
    });
  }

  getLinkedControls(linkedControlIds) {
    this.linkedControlsList = [];
    this.isLoading = true;
    console.log(linkedControlIds)
    this.controlServices.getControlsTitle(linkedControlIds).subscribe(res => {
      this.linkedControlsList = res;
      this.isLoading = false;
      console.log(res)
    })
    this.showControlListPopUp = true
  }
}
