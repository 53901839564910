import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ControlDocumentsServices } from "src/app/Services/control-documents.services";
import { NotificationService } from "src/app/Services/notification.service";
import { CommonFunction } from "src/app/Shared/commen/commen-function";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { globalConstants, AppConstant } from "src/constants/global-constants";
import Swal from "sweetalert2";
import { CategoriesControlDocumentsServices } from "src/app/Services/categories-control-documentsServices";
import { DatePipe } from "@angular/common";
import { DateHelper } from "src/app/Common/Helpers/date.helper";
import { ApplicationTimeZone } from "src/app/Models/application-timezone";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";

@Component({
  selector: "app-control-documents-list",
  templateUrl: "./control-documents-list.component.html",
  providers:[HasPermissionPipe]

})
export class ControlDocumentsListComponent implements OnInit {
  heading = "Control Documents";
  subheading = "Control Documents inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  faPlus = faPlus;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  isLoading = false;
  submitForm = false;
  categoriesControlDocumentList = [];
  cisLevelId: any;
  openDetails = false;
  public openControlDetails = false;
  data = [];
  displayData = [];
  activeTab: string = "all";
  addCat: boolean;
  appConstant: any;
  selectedControlDocument: any;
  showRevisionHistory: boolean = false;
  controlDocumentId: any;
  showComparisonPopup: boolean = false;
  columns: Partial<Column>[] = [];
  currentRoute: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private controlDocumentsServices: ControlDocumentsServices,
    private dateHelper: DateHelper,
    private categoriesControlDocumentsServices: CategoriesControlDocumentsServices,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private hasPermissionPipe: HasPermissionPipe,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.appConstant = AppConstant;
  }
  // curr = formatNumber(100,this.locale,
  //   '2.1-5')
  ngOnInit(): void {
    this.getAllCats();
    this.currentRoute = this.route.snapshot;
    this.initColumns();
  }
  getUTCTImeZone(): ApplicationTimeZone {
    return {
      alias: "UTC",
      displayName: "(UTC) Coordinated Universal Time",
      offset: 0,
      daylightSavings: false,
      abbreviation: 'UTC',
      zoneId: 'UTC',
      id: 1
    }
  }
  onchangeStatusTab(statusId: number = 0) {
    if (statusId == 0) {
      this.displayData = this.data;
      this.activeTab = "all";
      return;
    }

    if (statusId == this.appConstant.controlDocumentStatusConstants.Draft) {
      this.activeTab = "draft";
      this.displayData = this.data.filter(
        (e) =>
          e.status == CommonFunction.getControlDocumentStatusNameById(statusId)
      );
    } else if (
      statusId == this.appConstant.controlDocumentStatusConstants.Minor
    ) {
      this.activeTab = "minor";
      this.displayData = this.data.filter(
        (e) =>
          e.status == CommonFunction.getControlDocumentStatusNameById(statusId)
      );
    } else if (
      statusId == this.appConstant.controlDocumentStatusConstants.Major
    ) {
      this.activeTab = "major";
      this.displayData = this.data.filter(
        (e) =>
          e.status == CommonFunction.getControlDocumentStatusNameById(statusId)
      );
    }
  }

  initColumns() {
    this.columns = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.currentRoute, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.currentRoute, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.currentRoute, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.currentRoute, 'canUpdate')
    },
    {
      label: "Name",
      elementAttribute: "controlDocumentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Status",
      elementAttribute: "status",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Version",
      elementAttribute: "version",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Date/Time",
      elementAttribute: "createdDate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      formatFn: (date: Date) => {

        let dateString = this.datePipe.transform(date, "MMM d, y, h:mm:ss a");
        if (dateString) {
          return dateString;
        } else {
          return "";
        }
      },
    },
    {
      label: "Document Owner",
      elementAttribute: "documentOwner",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Department",
      elementAttribute: "departmentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Updated By",
      elementAttribute: "lastUpdatedBy",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Categorie",
      elementAttribute: "categoriesControlDocumentName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Description",
      elementAttribute: "descriptionHTMLFormate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      isHtml: true,
      columnWidth: "200px",
    },
  ];
}
  getEditUrl(event: any) {
    this.router.navigate([
      "/Documentation/ControlDocumentsCreate/" + event.controlDocumentId,
    ]);
  }
  onCloseDetail(event) {
    this.openDetails = false;
    this.openControlDetails = false;

  }
  onClose(event) {
    this.openDetails = false;
    this.showRevisionHistory = false;
    this.showComparisonPopup = false;
    console.log(event)
  }
  onDelete(event) {
    this.cisLevelId = event.controlDocumentId;
    let controlDocumentObj = {
      id: event.controlDocumentId,
      status: event.status,
      version: event.version,
    };
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        // call the delete api here
        this.isLoading = true;
        this.controlDocumentsServices
          .deleteControldocumentAuditTrailByControldocument(controlDocumentObj)
          .subscribe(
            (response) => {
              this.isLoading = false;
              this.getAll();
            },
            (error: any) => {
              //Message
              this.notify.showError(error?.message, "Control Documents");
            }
          );
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
  onOpenDetails(event) {
    this.controlDocumentId = event.controlDocumentId
    this.showRevisionHistory = true
  }
  getAllCats() {
    this.isLoading = true;
    this.categoriesControlDocumentList = [];
    this.categoriesControlDocumentsServices.getAll().subscribe((data) => {
      this.isLoading = false;
      if (data) {
        this.categoriesControlDocumentList = data;
        this.getAll();
      }
    });
  }
  getAll() {
    this.isLoading = true;
    this.controlDocumentsServices.getAllAuditTrails().subscribe((res) => {
      this.isLoading = false;
      if (res && res.length > 0) {
        res.forEach((element) => {
          element.version = CommonFunction.fixedControlDocumentVersion(
            CommonFunction.getControlDocumentStatusIdByName(element.status),
            element.version
          );
          element.categoriesControlDocumentName =
            this.categoriesControlDocumentList.find(
              (e) =>
                e.id ==
                JSON.parse(element?.newValue)?.CategoriesControlDocumentId
            )?.name ?? "";
        });
        let controlDocuments = [];
        res.map(d => {
          const permanentTimeZone = this.dateHelper.getPermanentTimeZone();
          let offset = this.dateHelper.getOffsetForMassTransactions(
            this.getUTCTImeZone(),
            permanentTimeZone, d.createdDate
          );
          let dates = this.dateHelper.setOffset(
            d.createdDate,
            d.createdDate,
            offset
          );
          d.createdDate = new Date(dates.start);
          controlDocuments.push(d);
        })
        this.data = controlDocuments;
        this.onchangeStatusTab();
      }
    });
  }
}
