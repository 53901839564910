import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplainceService {

  complaincebasePath = environment.apiUrl + 'Complaince';
  constructor(private http : HttpClient) { }

  getControlDocuments(body): any {
    return this.http.post<any>(this.complaincebasePath + '/GetControlDocuments', body);
}
}
