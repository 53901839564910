<div [ngClass]="{
	'marginLeft': addMargin && !globals.toggleSidebar}" [class]="'Pop-Up-Box pop-up-box'">
	<div class="Pop-Up-Header">
		<span (click)="onClose(false)" class="material-icons">highlight_off</span>
		<h1>Customer Details</h1>
		<!-- <div class="Pop-Up-Edit-Button">
			<button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button>
		</div> -->
	</div>

	<hr class="HR">

	<div class="container">
		<p><strong>Customer: </strong> {{customerDetails.name}}</p>
	</div>
</div>