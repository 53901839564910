import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-question-control-details',
  templateUrl: './question-control-details.component.html',
  styleUrls: ['./question-control-details.component.scss']
})
export class QuestionControlDetailsComponent implements OnInit {

  constructor(private router: Router,protected _sanitizer: DomSanitizer) {}
  description : SafeHtml;
  auditingGuideLines: SafeHtml;
  objective: SafeHtml;
  otherInformation: SafeHtml;

 ngOnInit(): void {
   this.description = this._sanitizer.bypassSecurityTrustHtml(this.event.description);
   this.objective = this._sanitizer.bypassSecurityTrustHtml(this.event.objective);
   this.otherInformation = this._sanitizer.bypassSecurityTrustHtml(this.event.otherInformation);
   this.auditingGuideLines = this._sanitizer.bypassSecurityTrustHtml(this.event.auditingGuideLines);

 }
 @Input()
 onClose: (closeboth) => {};

 @Input()
 selectedEvent: any = {};

 @Input()
 event: any = {} as any;

 getEditUrl(event: any) {
   this.router.navigate(["/Dashboard/Setup/createControl/" + this.event.id]);
 }

}
