import { Component, HostListener } from '@angular/core';
import FileInfo from 'src/app/Common/models/files';
import { ImportService } from 'src/app/Services/import.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { TitleService } from 'src/app/Services/title.service';

interface dataLists {
  controlFunction: ImportDataItem[];
  suggestedControl: ImportDataItem[];
  suggestedControlOwnership: ImportDataItem[];
  primaryObjective: ImportDataItem[];
  cisAssetType: ImportDataItem[];
  cisLevels: ImportDataItem[];
  controlType: ImportDataItem[];
  scfDomain: ImportDataItem[];
}

interface ImportDataItem {
  value: string;
  isExist: boolean;
}

interface ImportQuestionViewModel {
  questionNumber: number;
  question: string;
  description: string;
  controlTitle: string;
  linkedControls: string;
  predefinedSuggestedAction: string;
  dataLists: dataLists;
}
const humanReadableColumnNames: string[] = [
  'Question Number', 'Question', 'Control Title', 'Description', 'Linked Controls Name', 'Predefined Suggested Action',
  'CIS Asset Type', 'CIS Levels',
  'Control Function', 'Control Type', 'Primary Objective', 'SCF Domain', 'Suggested Control'
];

@Component({
  selector: 'app-import-questions',
  templateUrl: './import-questions.component.html',
  styleUrls: ['./import-questions.component.scss']
})

export class ImportQuestionsComponent {

  heading = "Preview Questions";
  subheading = "Preview Imported Questions before save";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  uploadedFiles: FileInfo[] = [];
  uploadedFile: File | null;
  isLoading = false;
  IsPreviewDataLoaded: boolean = false;
  dataSource: any[] = [];
  data: any;

  constructor(private importService: ImportService,
    private notify: NotificationService,
    private titleService: TitleService
  ) { 
    this.titleService.setTitle("Import Questions");
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.IsPreviewDataLoaded = false;
    }
  }

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      ['link', 'image'], // link and image, video
    ],
    imageResize: true // for image resize
  };

  displayedColumns: string[] = ['questionNumber', 'question', 'controlTitle', 'description', 'linkedControls', 'predefinedSuggestedAction', 'dataLists.cisAssetType', 'dataLists.cisLevels', 'dataLists.controlFunction', 'dataLists.controlType', 'dataLists.primaryObjective', 'dataLists.scfDomain', 'dataLists.suggestedControl'];
  ngOnDestroy() {
    this.titleService.setTitle('');
  }
  getHumanReadableColumnName(index: number): string {
    return humanReadableColumnNames[index];
  }
  onFileUpload(event: any) {
    const files = event.target.files;
    const fileLimit = 1;
    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        this.notify.showWarning("You Can Upload Only One File To Import Data", "Import");
        return;
      } else {
        this.isLoading = true;
        this.uploadFile(file);
      }
    }

  }
  MoveBack() {
    this.IsPreviewDataLoaded = false;
  }
  uploadFile(file: any) {
    this.uploadedFiles.push(file);
    this.uploadedFile = file;
    this.isLoading = false;
  }

  createDocument() {
    const formData = new FormData();
    formData.append(this.uploadedFile.name, this.uploadedFile);
    if (!this.isLoading) {
      this.isLoading = true
      this.importService.ImportQuestions(formData).subscribe(res => {
        if (res.isError) {
          this.isLoading = false;
          this.notify.showError("Document Already Imported", "Import");
          this.uploadedFiles = []
          return;
        }
        this.notify.showSuccess("Imported Data Is Ready For Preview", "Import");
        this.dataSource = res.importData;
        console.log(this.dataSource)
        this.transformDataForTable();
        this.IsPreviewDataLoaded = true;
        this.isLoading = false;
        this.uploadedFiles = [];
      })
    }

  }

  transformDataForTable() {

    const flattenedData: any[] = [];

    for (const item of this.dataSource) {
      const rowData: any = {
        questionNumber: item.questionNumber,
        question: item.question,
        description: item.description,
        controlTitle: item.controlTitle,
        linkedControls: item.linkedControls,
        predefinedSuggestedAction: item.predefinedSuggestedAction,
        'dataLists.controlFunction': item.dataLists.controlFunction?.map(dataItem => dataItem.value).join(', '),
        'dataLists.suggestedControl': item.dataLists.suggestedControl?.map(dataItem => dataItem.value).join(', '),
        'dataLists.suggestedControlOwnership': item.dataLists.suggestedControlOwnership?.map(dataItem => dataItem.value).join(', '),
        'dataLists.primaryObjective': item.dataLists.primaryObjective?.map(dataItem => dataItem.value).join(', '),
        'dataLists.cisAssetType': item.dataLists.cisAssetType?.map(dataItem => dataItem.value).join(', '),
        'dataLists.cisLevels': item.dataLists.cisLevels?.map(dataItem => dataItem.value).join(', '),
        'dataLists.controlType': item.dataLists.controlType?.map(dataItem => dataItem.value).join(', '),
        'dataLists.scfDomain': item.dataLists.scfDomain?.map(dataItem => dataItem.value).join(', ')
      };

      flattenedData.push(rowData);
    }

    this.dataSource = flattenedData;
  }



  deleteFile(file: FileInfo) {
    this.uploadedFiles = []
  }
  isDeletable(): boolean {
    return true;
  }
  view(event) {
    console.log("");
  }

  onClose(event) {
    this.IsPreviewDataLoaded = false
  }

  hasNewDataList(datalist: any) {
    return !!datalist.find(x => !x.isExist);
  }

  hasAnyNewDataList(datalists: any): boolean {
    let hasNewDataList: boolean = false;
    let resultArray = Object.keys(datalists).map(function (personNamedIndex) {
      let datalist = datalists[personNamedIndex];
      return datalist;
    });
    resultArray.forEach(e => {
      let res = e.find(x => !x.isExist);
      if (res != null) {
        hasNewDataList = true
      }
    })
    return hasNewDataList;
  }

  saveData() {
    this.isLoading = true
    const originalData: ImportQuestionViewModel[] = [];
    for (const flattenedRow of this.dataSource) {
      const originalRow: ImportQuestionViewModel = {
        questionNumber: flattenedRow.questionNumber ? flattenedRow.questionNumber : 0,
        question: flattenedRow.question,
        description: flattenedRow.description,
        controlTitle: flattenedRow.controlTitle,
        linkedControls: flattenedRow.linkedControls,
        predefinedSuggestedAction: flattenedRow.predefinedSuggestedAction,
        dataLists: {
          controlFunction: this.convertToDataList(flattenedRow['dataLists.controlFunction']),
          suggestedControl: this.convertToDataList(flattenedRow['dataLists.suggestedControl']),
          suggestedControlOwnership: this.convertToDataList(flattenedRow['dataLists.suggestedControlOwnership']),
          primaryObjective: this.convertToDataList(flattenedRow['dataLists.primaryObjective']),
          cisAssetType: this.convertToDataList(flattenedRow['dataLists.cisAssetType']),
          cisLevels: this.convertToDataList(flattenedRow['dataLists.cisLevels']),
          controlType: this.convertToDataList(flattenedRow['dataLists.controlType']),
          scfDomain: this.convertToDataList(flattenedRow['dataLists.scfDomain']),
        },
      };

      originalData.push(originalRow);
    }

    console.log('Original Data:', originalData);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.importService.saveImportQuestions(originalData).subscribe(res => {
      this.notify.showSuccess("Imported Data Saved Successfully", "Import");
      this.IsPreviewDataLoaded = false;
      this.isLoading = false
    },
      err => {
        this.notify.showError("Error Occured While Saving Data", "Import");
        this.IsPreviewDataLoaded = false;
        this.isLoading = false
      });
  }

  convertToDataList(data: string): ImportDataItem[] {
    if (!data) {
      return [];
    }

    const dataList: ImportDataItem[] = data.split(', ').map(value => ({ value, isExist: true }));
    return dataList;
  }


}
