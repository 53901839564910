import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification.service';
import { SuggestedControlOwnershipService } from 'src/app/Services/suggested-control-ownership.service';
import { TitleService } from 'src/app/Services/title.service';

@Component({
  selector: 'app-create-suggested-control-ownership',
  templateUrl: './create-suggested-control-ownership.component.html',

})
export class CreateSuggestedControlOwnershipComponent {


  heading = 'Suggested Control Ownership';
  subheading = 'Suggested Controls Ownership of Control inside the system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  suggestedControlOwnershipForm: UntypedFormGroup;
  suggestedControlOwnershipId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private suggestedControlOwnershipService: SuggestedControlOwnershipService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("New Suggested Control Ownership");
    this.titleService.setBackButtonUrl('/Dashboard/Setup/suggested-control-ownership');
    this.suggestedControlOwnershipForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });


    this.route.params.subscribe(qp => {
      let suggestedControlid = qp.id;
      this.suggestedControlOwnershipId = suggestedControlid;

      if (this.suggestedControlOwnershipId) {
        this.titleService.setTitle("Edit Suggested Control Ownership");
        this.suggestedControlOwnershipService.get(this.suggestedControlOwnershipId).subscribe(data => {
          this.suggestedControlOwnershipForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }


  MoveBack() {
    this.locationService.back();
  }
  createSuggestedControlOwnership() {
    if (this.suggestedControlOwnershipForm.invalid) {
      return
    }
    else if (this.suggestedControlOwnershipId) {
      this.suggestedControlOwnershipService.update({
        name: this.suggestedControlOwnershipForm.get('name').value,
        id: this.suggestedControlOwnershipId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Suggested Control Ownership');
        this.router.navigate(['/Dashboard/Setup/suggested-control-ownership']);
      },
        error => {
          this.notify.showError('Error while updating', 'Suggested Control Ownership');
          this.router.navigate(['/Dashboard/Setup/suggested-control-ownership']);
        })
    }
    else {
      this.suggestedControlOwnershipService.create({
        name: this.suggestedControlOwnershipForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Suggested Control Ownership');
        this.router.navigate(['/Dashboard/Setup/suggested-control-ownership'])
      },
        error => {
          this.notify.showError('Error while creating', 'Suggested Control Ownership');
          this.router.navigate(['/Dashboard/Setup/suggested-control-ownership']);
        }
      )
    }

  }
  ngOnDestroy(){
    this.titleService.setTitle("");
    this.titleService.setBackButtonUrl('');
  }
}
