<div class="row invitationForm" *ngIf="!isError">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">User</h5>
                <form [formGroup]="userForm" (ngSubmit)="createUser()" autocomplete="off">

                    <div class="position-relative mb-2" *ngIf="roles">
                        <mat-form-field appearance="fill" class="d-block">
                            <mat-label>Selected Role</mat-label>
                            <mat-select formControlName="roleIds" [disabled]="userForm.get('roleIds').disabled" multiple>
                                <mat-option *ngFor="let role of roles" [value]="role.id">
                                    {{ role.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="position-relative mb-2">
                        <label for="email" class="form-label">Email</label>
                        <input [disabled]="isDisabled" readonly name="email" id="email" formControlName="email"
                            type="email" placeholder="Email" class="form-control" required>

                    </div>
                    <!--First Name-->
                    <div class="position-relative mb-2">
                        <label for="firstName" class="form-label">First Name</label>
                        <input name="firstName" id="firstName" formControlName="firstName" type="text"
                            placeholder="First Name" class="form-control"
                            [ngClass]="{ 'invalid-input': userForm.get('firstName').invalid && userForm.get('firstName').touched }"
                            required>
                    </div>


                    <!--Last Name-->
                    <div class="position-relative mb-2">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input name="lastName" id="lastName" formControlName="lastName" type="text"
                            placeholder="Last Name" class="form-control"
                            [ngClass]="{ 'invalid-input': userForm.get('lastName').invalid && userForm.get('lastName').touched }"
                            required>
                    </div>

                    <!-- password-->

                    <div class="position-relative mb-2" *ngIf="!existingUser">
                        <label for="password" class="form-label">Password</label>
                        <input name="password" (change)="setValidation()" (focus)="showValidation()"
                            (focusout)="checkValidation($event)" id="password" formControlName="password"
                            [ngClass]="{ 'invalid-input': userForm.get('password').invalid && userForm.get('password').touched }"
                            type="password" placeholder="Password" class="form-control mb-2">
                        <div class="position-relative validationMsg mt-2 " *ngIf="displayPasswordValidation"
                            style="background-color: #f1f4f6; border: 1px solid #f1f4f6;">
                            <ul>
                                <li *ngIf="isPasswordLengthValid()" class="text-success">14 characters long.</li>
                                <li *ngIf="!isPasswordLengthValid()" class="text-danger">14 characters long.</li>
                                <li *ngIf="!isPasswordSpecialCharactersValid()" class="text-danger">Must have special
                                    characters like (#*&).</li>
                                <li *ngIf="isPasswordSpecialCharactersValid()" class="text-success">Must have special
                                    characters like (#*&).</li>
                                <li *ngIf="isPasswordCaseValid()" class="text-success">Must have upper and lower case
                                    characters.</li>
                                <li *ngIf="!isPasswordCaseValid()" class="text-danger">Must have upper and lower case
                                    characters.</li>
                                <li *ngIf="isPasswordNumericValid()" class="text-success">Must have a numeric value.
                                </li>
                                <li *ngIf="!isPasswordNumericValid()" class="text-danger">Must have a numeric value.
                                </li>
                                <li *ngIf="userForm.get('password').value === userForm.get('confirmPassword').value && userForm.get('password').value"
                                    class="text-success">Password and Confirm Password must match.</li>
                                <li *ngIf="userForm.get('password').value != userForm.get('confirmPassword').value || !userForm.get('password').value"
                                    class="text-danger">Password and Confirm Password must match.</li>
                            </ul>
                        </div>

                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <input name="confirmPassword" (change)="setValidation()" id="confirmPassword"
                            formControlName="confirmPassword" type="password" placeholder="Confirm Password"
                            [ngClass]="{ 'invalid-input': userForm.get('confirmPassword').invalid && userForm.get('confirmPassword').touched }"
                            class="form-control">
                    </div>

                    <button type="submit" class="mt-1 btn btn-success btn-atlas" [disabled]="isLoading">Save</button>
                    <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button">Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isError" class="error-container">
    <div class="error-message">
        <h1>{{errorMessage}}</h1>
    </div>
</div>
<div class="d-flex justify-content-center" *ngIf="duplicateUser">

    <div class="Pop-Up-Box pop-up-box">
        <div class="Pop-Up-Header">
            <h1>Duplicate User</h1>
        </div>

        <hr class="HR">

        <div class="container">

            <div class="position-relative row">
                <div class="position-relative mb-1">
                    <label for="name" class="form-label">you already am a member of the tenant</label>

                </div>
            </div>
            <div class="position-relative row">
                <div class="col-sm-1">
                    <button class="mb-2 btn btn-success btn-atlas btn-sm small text-small"
                        (click)="duplicateUserPopUp(false)">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>