<button mat-icon-button class="filter-button" (click)="click()">
    <mat-icon>filter_list</mat-icon>
    <span>{{ filterCount > 0 ? 'All Filters' : 'Filters' }}</span>
    <span *ngIf=" filterCount> 0">({{ filterCount }})</span>
</button>

<ng-container *ngIf="isFilterPopupOpen" (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeFilterPopup();">
        <div class="pop-up-box" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header">
                <span (click)="closeFilterPopup()" class="material-icons">highlight_off</span>
                <h1>Filters</h1>
            </div>
            <hr class="HR">
            <ng-container *ngIf="filterType === filterTypeEnum.Audits">
                <form [formGroup]="filterForm" class="container mt-2">
                    <mat-form-field appearance="fill" class="form-field-audit">
                        <mat-label>Select Customer</mat-label>
                        <mat-select formControlName="customerIds" name="customerListId" multiple
                            style="background-color: none;">
                            <mat-option *ngFor="let item of customers" [value]="item.id">
                                {{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="form-field-audit">
                        <mat-label>Audit based on</mat-label>
                        <mat-select formControlName="documentIds" name="documentsListId" multiple
                            style="background-color: none;">
                            <mat-option *ngFor="let item of documents" [value]="item.id">
                                {{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-field-audit">
                        <mat-label>Enter Start Date</mat-label>
                        <mat-date-range-input [rangePicker]="startPicker">
                            <input matStartDate placeholder="Start date to" formControlName="startDateFrom">
                            <input matEndDate placeholder="start date from" formControlName="startDateTo">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #startPicker></mat-date-range-picker>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="form-field-audit">
                        <mat-label>Enter End Date</mat-label>
                        <mat-date-range-input [rangePicker]="endPicker">
                            <input matEndDate placeholder="End date from" formControlName="endDateTo">
                            <input matStartDate placeholder="End date to" formControlName="endDateFrom">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #endPicker></mat-date-range-picker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-field-audit">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let item of auditStatus; let i=index" [value]="i+1">
                                {{auditStatusValues[item]}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-md-12 buttons mb-2">
                        <button class="btn btn-warning" mat-button (click)="clear()">Clear</button>
                        <button class="btn btn-success" mat-button (click)="save()">Apply</button>
                    </div>
                </form>
            </ng-container>

        </div>
    </div>
</ng-container>