import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../environments/environment";

const basePath = environment.apiUrl + "Events";

@Injectable({
    providedIn: "root",
})
export class TimeZonesServices {
    timeZoneBasePath = environment.apiUrl + "Timezone";
    constructor(private http: HttpClient) { }
    getAll(): any {
        return this.http.get<any>(this.timeZoneBasePath + "/All");
    }
    get(): any {
        return this.http.get<any>(this.timeZoneBasePath + "/Get");
    }
    save(body): any {
        return this.http.post(this.timeZoneBasePath + "/Create", body);
    }
}
