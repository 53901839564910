import { Component } from '@angular/core';

@Component({
  selector: 'app-chart-boxes3',
  templateUrl: './chart-boxes3.component.html',
  styles: []
})
export class ChartBoxes3Component {

  heading = 'Chart Boxes III';
  subheading = 'Highly configurable boxes best used for showing numbers in an user friendly way.';
  icon = 'pe-7s-wallet icon-gradient bg-plum-plate';


}
