<form *ngIf="!isLoading" (ngSubmit)="onSubmit()" #companyDetailsForm="ngForm">
  <div class="col-md-12">
    <div class="main-card mb-1 card">
      <div class="card-body">
        <h5 class="card-title">Basic</h5>
        <p>Company Address</p>
        <mat-form-field appearance="fill" class="company-address-field">
          <mat-label>Company Address</mat-label>
          <input matInput [(ngModel)]="companyAddress" name="companyAddress" />
        </mat-form-field>
        <p>Company Picture</p>
        <label *ngIf="picSelected" class="oval-image">
          <input type="file" accept="image/*" (change)="onPictureSelect($event)" />
          <img [src]="companyPicture" alt="Company Picture" required />
          <div class="camera-overlay">
            <mat-icon class="camera-icon">camera_alt</mat-icon>
          </div>
        </label>
        <button *ngIf="!picSelected" mat-raised-button class="mt-1 btn btn-success"
          (click)="fileInput.click(); $event.preventDefault()">
          Upload Picture
        </button>

        <input type="file" accept="image/*" (change)="onPictureSelect($event)" style="display: none" #fileInput
          required />
      </div>
    </div>
    <div class="main-card mb-1 card">
      <div class="card-body">
        <h5 class="card-title">Security</h5>
        <p>Session Timeout</p>
        <div class="form-group">
          <mat-form-field appearance="fill" class="session-timeout-field">
            <mat-label>Session Timeout (Minutes)</mat-label>
            <input matInput type="number" [(ngModel)]="sessionTimeout" name="sessionTimeout" required />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <button type="submit" mat-raised-button class="btn btn-success"
        [disabled]="!companyDetailsForm.form.valid || isLoadingButton">
        Submit
      </button>
    </div>
  </div>
</form>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%">
  <mat-spinner></mat-spinner>
</div>