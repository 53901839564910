<div class="d-flex justify-content-center">

  <div class="Pop-Up-Box pop-up-box ">
    <div class="Pop-Up-Header">
      <span (click)="onClose(false)" class="material-icons">highlight_off</span>
      <h1>Control Document</h1>
      <div class="Pop-Up-Edit-Button">
        <!-- <button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button> -->
      </div>
    </div>

    <hr class="HR">

    <div class="container">
      <div class="col-sm-3 mt-2">
        <!-- <button class="mb-1 btn btn-success btn-sm small text-small"
          (click)="showDocumentControlHistoryModel(true)">View History</button> -->
      </div>
      <div class="position-relative row mt-1">
        <div class="position-relative mb-1 col-md-8">
          <label class="form-label"><strong>Name </strong> <span
              class="ml-4">{{this.controlDocumentDetails.Name}}</span></label>
        </div>
        <div class="position-relative mb-1 col-md-4">
          <label class="form-label"><strong>Version </strong> <span
              class="ml-4">{{controlDocumentDetails.Version}}</span></label>

        </div>
      </div>

      <div class="position-relative row">
        <div class="position-relative mb-1 col-md-8">
          <!-- <label for="name" class="form-label"><strong>Categories Control Document </strong> <span
              class="ml-4">{{this.controlDocumentDetails.CategoriesControlDocumentName}}</span></label> -->


        </div>
        <div class="position-relative mb-1 col-md-4">
          <label class="form-label"><strong>Status </strong> <span
              class="ml-3">{{controlDocumentDetails.Status}}</span></label>
        </div>
      </div>
      <div class="position-relative row">
        <div class="position-relative mb-1 col-md-8">
          <label class="form-label"><strong>Time/Date </strong> <span
              class="ml-3">{{this.controlDocumentDetails.createdDate}}</span></label>
        </div>
        <div class="position-relative mb-1 col-md-4">
          <label for="name" class="form-label"><strong>Document Owner </strong> <span
              class="ml-3">{{this.controlDocumentDetails.documentOwner}}</span></label>
        </div>
      </div>
      <div class="position-relative row">
        <div class="position-relative mb-1 col-md-4">
          <label for="name" class="form-label"><strong>Updated By </strong> <span
              class="ml-3">{{this.controlDocumentDetails.lastUpdatedBy}}</span></label>
        </div>
      </div>
      <div class="position-relative row">
        <div class="col-md-12">
          <div class="position-relative mb-1 col-md-12">
            <label class="form-label"><strong>Description </strong></label>
            <p class="" [innerHTML]="controlDocumentDetails.DescriptionHTMLFormate"></p>
          </div>
        </div>




      </div>
    </div>

    <div class="row text-center" *ngIf="false">
      <div class="main-card card">
        <div class="card-body">

          <div class="container-fluid container-diff">
            <h1>Text Compare</h1>
            <div *ngIf="isCompared" class="row">
              <div class="col-md-12">
                <form class="form-inline" role="form">
                  <label for="lang" class="mb-2 mr-sm-2">Language:</label>
                  <select id="lang" name="lang" class="form-control mb-2 mr-sm-2" [(ngModel)]="selectedLang"
                    (change)="onChangeLanguage($event.target.value)">
                    <option *ngFor="let option of languages" [value]="option">{{option}}</option>
                  </select>
                  <label for="theme" class="mb-2 mr-sm-2">Theme:</label>
                  <select id="theme" name="theme" class="form-control mb-2 mr-sm-2" [(ngModel)]="selectedTheme"
                    (change)="onChangeTheme($event.target.value)">
                    <option *ngFor="let option of themes" [value]="option.value">{{option.name}}</option>
                  </select>
                  <div class="form-check mb-2 mr-sm-2">
                    <label class="form-check-label">
                      <input class="form-check-input " type="checkbox" (change)="onChangeInline($event.target.checked)">
                      Inline Diff
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="isCompared" class="row">
              <div class="col-md-12 editor">
                <ngx-monaco-diff-editor id="diffeditor" [options]="diffOptions" [originalModel]="originalModel"
                  [modifiedModel]="modifiedModel"></ngx-monaco-diff-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-title">
                  <label>Original Text</label>
                </div>
                <ngx-monaco-editor id="editor1" [options]="inputOptions" [(ngModel)]="text1"></ngx-monaco-editor>
              </div>
              <div class="col-md-6">
                <div class="input-title">
                  <label>Changed Text</label>
                </div>
                <ngx-monaco-editor id="editor2" [options]="inputOptions" [(ngModel)]="text2"></ngx-monaco-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align:center">
                <button type="button" class="btn btn-primary" (click)="onCompare()">Find Difference</button>
              </div>
            </div>
          </div>
          <br>
          <br>
        </div>
      </div>

    </div>

    <div class="Pop-Up-Box pop-up-box fixed-popup-box" *ngIf="historyDocumentationModel">
      <div class="Pop-Up-Header">
        <span (click)="showDocumentControlHistoryModel(false)" class="material-icons">highlight_off</span>
        <h1>Audit Trails</h1>
        <!--  -->
      </div>

      <hr class="HR">
      <div class="row">

        <div class="position-relative mb-3 col-md-6">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Control Document Version</mat-label>
            <mat-select [(ngModel)]="compareVersionIdFirst">
              <mat-option *ngFor="let catedocTyoe of AllAuditTrailsList" [value]="catedocTyoe.id">
                {{catedocTyoe.rounded_Version}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="position-relative mb-3 col-md-6">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Control Document Version</mat-label>
            <mat-select [(ngModel)]="compareVersionIdSecond">
              <mat-option *ngFor="let catedocTyoe of AllAuditTrailsList" [value]="catedocTyoe.id">
                {{catedocTyoe.rounded_Version}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <button class="mt-1 btn btn-success" (click)="CompareVersion()">Compare</button>
        <!-- <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">Version</th>
                <th scope="col">CreatedDate</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of AllAuditTrailsList" >
                <td>{{row.version}}</td>
                <td>{{row.createdDate}}</td>
                <td>{{row.status}}</td>
                <td scope="col"><button class="btn btn-sm btn-success">Compare</button></td>
      
              </tr>
            
            </tbody>
          </table> -->
        <div *ngIf="versionModelPopUp">
          <div class="Pop-Up-Header">
            <span (click)="versionModelPopUpShow(false)" class="material-icons">highlight_off</span>
            <h1>Compare</h1>
            <!--  -->
          </div>

          <hr class="HR">
          <div class="row">
            <div class="col-md-12">
              <form class="form-inline" role="form">

                <label for="theme" class="mb-2 mr-sm-2">Theme:</label>
                <select id="theme" name="theme" class="form-control mb-2 mr-sm-2" [(ngModel)]="selectedTheme"
                  (change)="onChangeTheme($event.target.value)">
                  <option *ngFor="let option of themes" [value]="option.value">{{option.name}}</option>
                </select>
                <div class="form-check mb-2 mr-sm-2">
                  <label class="form-check-label">
                    <input class="form-check-input " type="checkbox" (change)="onChangeInline($event.target.checked)">
                    Inline Diff
                  </label>
                </div>
              </form>
            </div>
            <div *ngIf="isCompared" class="row">
              <div class="col-md-12 editor">
                <ngx-monaco-diff-editor id="diffeditor" [options]="diffOptions" [originalModel]="originalModel"
                  [modifiedModel]="modifiedModel"></ngx-monaco-diff-editor>
              </div>
            </div>
          </div>

        </div>
      </div>
      <p class="mt-2">
        <!-- <button class="btn btn-success" (click)="submitPatentMenu()" type="button">{{this.parentMenuId != "" ? 'Update':'Save'}}</button> -->
      </p>
    </div>

    <!-- spinner -->
    <div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 1000001;">
      <mat-spinner></mat-spinner>
    </div>
  </div>