<mat-toolbar *ngIf="FilterType == filterTypeEnum.Audits">
    <div class="toolbar-container">
        <div class="search-container">
            <input matInput placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()">
            <span class="search-icon" *ngIf="!isSearched" (click)="search()">
                <mat-icon>search</mat-icon>
            </span>
            <span class="search-icon" *ngIf="isSearched" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <span class="inline-block filter-button">
            <app-filter-button #filterButton [filterType]="FilterType" [onApplyFilter]="ApplyAuditFilter.bind(this)"
                [inputValue]="parentInputValue">
            </app-filter-button>
        </span>
        <div class="favorite-container">
            <button mat-icon-button [matMenuTriggerFor]="favoriteMenu" class="favorite-button">
                <mat-icon>star_outline</mat-icon>
            </button>
            <mat-menu #favoriteMenu="matMenu" class="favorite-filters-popup">
                <app-favorite-views [filterType]="FilterType" [favoriteFilters]="favoriteViews"
                    [applyFavFilters]="ApplyFavFilters.bind(this)"
                    [onSaveFilter]="saveFavView.bind(this)"></app-favorite-views>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>