<div class="col">
  <ng-container>
    <div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%; z-index: 1000">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 styoclass="card-title">Personal</h5>
        <div class="position-relative mb-3">
          <form #registrationForm="ngForm" (ngSubmit)="onSubmit(registrationForm)">
            <div class="input-container">
              <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput type="text" id="firstName" name="firstName" [(ngModel)]="firstName" required />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" id="lastName" name="lastName" [(ngModel)]="lastName" required />
              </mat-form-field>
            </div>

            <button mat-raised-button class="mt-1 btn btn-success" type="submit" [disabled]="!registrationForm.valid">
              Submit
            </button>
          </form>

          <!-- <small *ngIf="timeZoneForm.invalid" class="text text-danger">Required</small> -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 styoclass="card-title">Timezone</h5>
        <section class="example-section">
          <mat-checkbox name="auto" (change)="selection('auto')" class="example-margin" [(ngModel)]="Auto">Auto
            Detect</mat-checkbox>
          <mat-checkbox name="manual" style="margin-left: 20px" (change)="selection('manual')"
            [(ngModel)]="chooseManually" class="example-margin">Select Menually</mat-checkbox>
        </section>
        <div class="position-relative mb-3 mt-5">
          <mat-form-field appearance="fill" class="timeZoneDropdown">
            <mat-label>Select Timezone</mat-label>
            <mat-select [(ngModel)]="selectedTimeZone" name="timezones" [disabled]="Auto"
              (selectionChange)="saveTimeZone()">
              <mat-option *ngFor="let timezone of timezones" [value]="timezone.id">
                {{ timezone.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <small *ngIf="timeZoneForm.invalid" class="text text-danger">Required</small> -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 styoclass="card-title">Reset Password</h5>
        <button mat-raised-button class="mt-1 btn btn-success" type="button" (click)="passwordPopup(true)">Reset
          Password</button>

      </div>
    </div>
  </div>
</div>

<div class="Pop-Up-Box pop-up-box" *ngIf="resetPasswordPopup">
  <div class="Pop-Up-Header">
    <span (click)="passwordPopup(false)" class="material-icons">highlight_off</span>
    <h1>Reset Password</h1>
  </div>
  <hr class="HR">
  <div class="container">
    <form [formGroup]="passwordForm" autocomplete="off">
      <label for="oldPassword" class="form-label">Current Password</label>
      <input id="oldPassword" formControlName="oldPassword" type="password" placeholder="Current Password"
        class="form-control">
      <label for="password" class="form-label">Password</label>
      <input name="password" (change)="setValidation()" (focus)="showValidation()" (focusout)="checkValidation($event)"
        id="password" formControlName="password" type="password" placeholder="Password" class="form-control">
      <div class="position-relative validationMsg mt-2" *ngIf="displayPasswordValidation"
        style="background-color: #f1f4f6; border: 1px solid #f1f4f6;">
        <ul>
          <li *ngIf="isPasswordLengthValid()" class="text-success">12 characters long.</li>
          <li *ngIf="!isPasswordLengthValid()" class="text-danger">12 characters long.</li>
          <li *ngIf="!isPasswordSpecialCharactersValid()" class="text-danger">Must have special
            characters like (#*&).</li>
          <li *ngIf="isPasswordSpecialCharactersValid()" class="text-success">Must have special
            characters like (#*&).</li>
          <li *ngIf="isPasswordCaseValid()" class="text-success">Must have upper and lower case
            characters.</li>
          <li *ngIf="!isPasswordCaseValid()" class="text-danger">Must have upper and lower case
            characters.</li>
          <li *ngIf="isPasswordNumericValid()" class="text-success">Must have a numeric value.</li>
          <li *ngIf="!isPasswordNumericValid()" class="text-danger">Must have a numeric value.</li>
          <li
            *ngIf="passwordForm.get('password').value === passwordForm.get('confirmPassword').value && passwordForm.get('password').value"
            class="text-success">Password and Confirm Password must match.</li>
          <li
            *ngIf="passwordForm.get('password').value != passwordForm.get('confirmPassword').value || !passwordForm.get('password').value"
            class="text-danger">Password and Confirm Password must match.</li>
        </ul>
      </div>

      <label for="confirmPassword" class="form-label">Confirm Password</label>
      <input name="confirmPassword" (change)="setValidation()" id="confirmPassword" formControlName="confirmPassword"
        type="password" placeholder="Confirm Password" class="form-control">

      <div class="button-container">

        <button mat-raised-button class="mt-1 btn btn-success" (click)="onResetPassword(passwordForm)" type="button"
          [disabled]="!areAllCriteriaMet() || isLoading">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>