<div class="d-flex">
  <div class="header-btn-lg pe-0" style="border: 1px solid grey;border-radius: 5px;padding-left: 13px;">
    {{getUserNameAndRole}}
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">

        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle (click)="switchDirectory()">
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle ml-2">
                  <img width="42" height="42" [src]="profie" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div *ngIf="isTenantSelected && !getSuperAdmin">
              <div ngbDropdownMenu>
                <div class="dropdown-item p-3" *ngFor="let ten of tenant" (click)="onClickTenant(ten)"
                  style="border: 1px solid #bab8b8;">
                  {{ ten.name }}
                </div>
                <button class="dropdown-item" (click)="userTime()">User Setting</button>
                <button class="dropdown-item" (click)="logout()">Log out</button>
              </div>
            </div>
            <div *ngIf="getSuperAdmin">
              <div ngbDropdownMenu>
                <button class="dropdown-item" (click)="logout()">Log out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>