import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http : HttpClient) { }
  maturitybasePath = environment.apiUrl + 'Menu';
  getParentMenu(): any {
    return this.http.get<any>(this.maturitybasePath + '/GetParentMenuList')
}
getMenuByParentId(parentId): any{
    return this.http.get<any>(this.maturitybasePath + '/getMenuByParentId/'+parentId);
}
get(id) : any {
    return this.http.get<any>(this.maturitybasePath + '/Get/' + id);
}

createMenu(body): any {
    return this.http.post<any>(this.maturitybasePath + '/CreateMenu', body);
}

updateList(body): any {
    return this.http.post<any>(this.maturitybasePath + '/Update/List', body);
}
update(body): any {
    return this.http.post<any>(this.maturitybasePath + '/Update', body);
}

delete(id): any {
    return this.http.delete<any>(this.maturitybasePath + '/Delete/'+id);
}
}
