import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { markFormGroupTouched } from 'src/app/Helpers/FormHelper';
import { CustomerService } from 'src/app/Services/customer.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { TitleService } from 'src/app/Services/title.service';

@Component({
  selector: 'app-create-or-edit-customer',
  templateUrl: './create-or-edit-customer.component.html',
  styleUrls: ['./create-or-edit-customer.component.sass']
})
export class CreateOrEditCustomerComponent {

  heading = 'Customer';
  subheading = 'Customer inside the system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  customerForm: UntypedFormGroup;
  customerId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private customer: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private titleService: TitleService,
  ) {
    this.titleService.setTitle('New Customer');
    this.titleService.setBackButtonUrl('/Audit/Customers'); 
    this.customerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });


    this.route.params.subscribe(qp => {
      this.customerId = qp.id;

      if (this.customerId) {
       this.titleService.setTitle('Edit Customer');
        this.customer.get(this.customerId).subscribe(data => {
          this.customerForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }



  back() {
    this.router.navigate(['/Audit/Customers'])
  }
  ngOnDestroy() {
    this.titleService.setTitle('');
    this.titleService.setBackButtonUrl('');
  }
  createCustomer() {
    if (this.customerForm.invalid) {
      markFormGroupTouched(this.customerForm);

    }
    else if (this.customerId) {
      this.customer.update({
        name: this.customerForm.get('name').value,
        id: this.customerId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Customer');
        this.router.navigate(['/Audit/Customers'])
      },
        error => {
          this.notify.showError('Error while updating', 'Customer');
          this.router.navigate(['/Audit/Customers'])
        })
    }
    else {
      this.customer.create({
        name: this.customerForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Customer');
        this.router.navigate(['/Audit/Customers'])
      },
        error => {
          this.notify.showError('Error while creating', 'Customer');
          this.router.navigate(['/Audit/Customers'])
        }
      )
    }


  }
}
