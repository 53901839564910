<div class="d-flex">
  <div class="header-btn-lg pe-0 ps-0 ms-0">
    <div class="icon-wrapper icon-wrapper-alt rounded-circle ms-2">
      <img width="42" height="42" [src]="profie" alt="" class="rounded-circle">
    </div>
    <span >
      <div class="atlas-bold-label">
        {{getUserName}}
      </div>
      <div class="atlas-light-label">
        {{getRole}}
      </div>
    
        
    </span>
    <span class="ms-3" [matMenuTriggerFor]="menu" (click)="switchDirectory()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8337 7.5L10.0003 13.3333L4.16699 7.5" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </span>
    <mat-menu #menu="matMenu"  class="user-settings-dropdown">
      <div class="user-profile d-flex">
        <img  width="42" height="42"  [src]="profie" alt="profile" class="rounded-circle">
        <div class="ms-3">
          <div class="atlas-bold-label">{{ getUserName }}</div>
          <div class="atlas-light-label">{{ getEmail }}</div>
        </div>
      </div>
      <mat-divider class="mt-3"></mat-divider>
      <div class="tenant-header d-flex align-items-center mt-2 ms-2">
        <span class="atlas-light-label-14">Switch Directory</span>
      </div>
      <div *ngIf="isTenantSelected && !getSuperAdmin">
        <button mat-menu-item *ngFor="let ten of tenant" (click)="onClickTenant(ten)">
          {{ ten.name }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="userTime()">User Setting</button>
        <button mat-menu-item (click)="accountSettings()">Tenant Settings</button>
        <button mat-menu-item (click)="logout()">Log out</button>
      </div>
      <div *ngIf="getSuperAdmin">
        <button mat-menu-item (click)="logout()">Log out</button>
      </div>
    </mat-menu>
  </div>
</div>