<ng-container *ngIf="loggedIn && isTenant">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="signup && !loggedIn">
    <app-create-edit-tenant></app-create-edit-tenant>
</ng-container>
<ng-container *ngIf="superAdminLogin && !loggedIn">
    <app-login-boxed></app-login-boxed>
</ng-container>
<ng-container *ngIf="invitePage && !loggedIn">
    <router-outlet></router-outlet>
    <!-- <app-user-acceptance></app-user-acceptance> -->
</ng-container>
<ng-container>
    <div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-container>
    <div class="center-container"
        *ngIf="!isTenant && !loggedIn && !isLoading && !signup && !superAdminLogin && !invitePage && !noActiveTenant">
        <div class="container d-flex justify-content-center align-items-center" style="height: 100vh;">
            <div class="border p-4" style="height:fit-content; width: 350px;">
                <h1 class="text-center">Log In As</h1>
                <div class="box-container">
                    <div class="box list-group">
                        <button [disabled]="isLoading" style="height: 50px; width: 300px; margin-bottom: 10px;"
                            class="list-group-item list-group-item-action list-group-item-xl" role="tabpanel"
                            *ngFor="let ten of tenant" (click)="onClickTenant(ten)">
                            {{ ten.name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container>
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh; text-align: center;"
    *ngIf="!isTenant && !loggedIn && !isLoading && !signup && !superAdminLogin && !invitePage && noActiveTenant">
 <div style="max-width: 420px; padding: 20px; border: 1px solid #ccc; border-radius: 8px; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
   <div style="margin-bottom: 20px;">
     <h3>Email: {{email}}</h3>
     <h3>Name: {{username}}</h3>
   </div>
   <p style="color: #888;">
     Your account is not linked to an active tenant at the moment. Please contact your administrator if you have any questions.
   </p>
 </div>
</div>
</ng-container>