const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_d1TPPrCk9",
    "aws_user_pools_web_client_id": "4i9lq45h34011vp7j0vi2fb5i2",
    "oauth": {
        "domain": "atlaslive.auth.eu-north-1.amazoncognito.com",
        "scope": ['openid', 'aws.cognito.signin.user.admin', 'profile'],
        "redirectSignIn": "https://atlas.kiskane.se",
        "redirectSignOut": "https://atlas.kiskane.se",
        "responseType": "code"
    }
};

export default awsmobile;
