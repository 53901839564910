import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { NotificationItem } from 'src/app/Common/Enums/Atlas-Enums';
import { DateHelper, transformDate } from 'src/app/Common/Helpers/date.helper';
import { NotificationModel } from 'src/app/Common/Helpers/notification-helper';
import { dateTimeFormats } from 'src/app/Common/consntants';
import { ApplicationTimeZone } from 'src/app/Models/application-timezone';
import { LogoutServices } from 'src/app/Services/LogoutServices';
import { NotificationsService } from 'src/app/Services/notification-service';
import { SignalRService } from 'src/app/Services/signalr-services';
import { UserService } from 'src/app/Services/user-services';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() increaseCount = 0;
  @Output() triggerClose = new EventEmitter<void>();
  @Input() onClose = new EventEmitter<any>();
  faBell = faBell;
  showNotifications = false;
  permanentTimeZone: any;
  utcTimeZone: ApplicationTimeZone;
  notifications: NotificationModel[] = [];
  page = 1;
  private audio = new Audio('src/assets/audio/NotificationSound.wav');
  itemsPerLoad = 10;
  notificationsFlag: boolean;
  controlDocumentUrlPattern = /\/control-document\/(\d+)$/;
  isLoading: boolean = false;
  hasMoreNotifications: boolean = false;
  toReadNotificationIds = [];
  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
    if (this.showNotifications) {
      this.loadMoreNotifications();
    }
  }
  loadMoreNotifications() {
    if (this.isLoading) {
      return;
    }
    if (this.hasMoreNotifications) {
      this.page++;
    }
    this.isLoading = true;

    this.notificationsService.get(this.page, this.itemsPerLoad).subscribe((res) => {
      res.data.forEach(item => {
        const offset = this.dateHelper.getOffsetForMassTransactions(this.utcTimeZone, this.permanentTimeZone, item.dateCreated);
        const dates = this.dateHelper.setOffset(item.dateCreated, item.dateCreated, offset);
        item.createdOn = transformDate(dates.start, dateTimeFormats.europeanTwelveHoursDateTime);
      });

      this.hasMoreNotifications = res.hasMoreNotifications;
      this.notifications = [...this.notifications, ...res.data]; // Append new notifications
      const uniqueIds = new Set();
      this.notifications = this.notifications.filter(notification => {
        if (uniqueIds.has(notification.id)) {
          return false;
        } else {
          uniqueIds.add(notification.id);
          return true;
        }
      });
      if (this.notifications.length != 0) {
        this.notificationsFlag = true;
        var seenNotificationIds = { Ids: res.data.map(d => { return d.id }) };
        seenNotificationIds.Ids = seenNotificationIds.Ids.concat(this.toReadNotificationIds);
        this.notificationsService.markAsSeen(seenNotificationIds).subscribe(data => {
          this.increaseCount = data.unSeen;
          this.toReadNotificationIds = [];
        });
      } else {
        this.notificationsFlag = false;
      }

      this.isLoading = false;
    });
  }
  constructor(public signalRService: SignalRService,
    public dateHelper: DateHelper,
    public notificationsService: NotificationsService,
    public logoutService: LogoutServices,
    public toastrService: ToastrService,
    private router: Router

  ) {
    this.permanentTimeZone = this.dateHelper.getPermanentTimeZone();
    this.utcTimeZone = {
      abbreviation: 'UTC',
      alias: 'UTC',
      daylightSavings: false,
      displayName: '(UTC) Coordinated Universal Time',
      id: 146,
      offset: 0,
      zoneId: 'UTC'
    };
  }
  clickNotifiation(item) {
    if (item.itemUrl == 'assigned-permissions' as NotificationItem.AssignedPermissions) {
      // this.logoutService.logout();
    }
    if(item.itemUrl.includes('publish-control-document/'))
    {
      var array  = item.itemUrl.split('/');
      var id = array[array.length-1];
      this.router.navigate([`Documentation/PublishedControlDocument/${id}`]);
    }
    if(item.itemUrl.includes('review-dpa/'))
    {
      var array = item.itemUrl.split('/');
      var id = array[array.length - 1];
      this.router.navigate([`/DataProtection/DPA/Edit/${id}`]);
    }
    this.showNotifications = false;
  }
  ngOnInit(): void {
    this.signalRService
      .startConnection(this.signalRService.notificationHubPath)
      .on('ReceiveNotification', (data: NotificationModel) => {
        const offset = this.dateHelper.getOffsetForMassTransactions(this.utcTimeZone, this.permanentTimeZone, data.createdOn);
        data.createdOn = transformDate(data.createdOn, dateTimeFormats.dateTimeTwelveHours);
        this.notifications = [data, ...this.notifications];
        this.notificationsFlag = true;
        this.increaseCount = this.increaseCount + 1;
        this.toReadNotificationIds.push(data.id);
        setTimeout(() => {
          this.toastrService.info(data.content, 'Notification', {
            positionClass: 'toast-top-right',
            timeOut: 15000, // show time in milliseconds
            closeButton: true,  // Displays the notification at the bottom right
          });
          this.playNotificationSound();
        }, 100);
      });


    if (this.onClose) {
      this.onClose.subscribe(() => {
        this.notifications = this.notifications.map(notification => {
          return { ...notification, seen: true };
        });
      })
    }
  }
  private playNotificationSound(): void {
    this.audio.load();
    this.audio.play();
  }
}
