import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  Validators,
  FormBuilder, FormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { fetchAuthSession, getCurrentUser, signIn } from "aws-amplify/auth";
import { FlagService } from "src/app/Services/flag.service";
import { SuperAdminServices } from "src/app/Services/super-admin-services";

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login-boxed.component.html",
  styleUrls: ["./login-component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginBoxedComponent implements OnInit {
  isLoading: boolean = false;
  loginForm: FormGroup;
  isRemembered = false;
  wrongCredentials = false;
  currentYear: number = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private flagService: FlagService,
    private superAdminService: SuperAdminServices
  ) {
    this.loginForm = this.formBuilder.group({
      Username: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      Remember: [false],
    });
  }

  ngOnInit() {
    console.log('======  =======',)
  }

  loginFailed() {
    this.wrongCredentials = true;
    setTimeout(function () { this.wrongCredentials = false; }, 2000);
    this.isLoading = false;
  }
  async authenticateUser({ username, password }): Promise<any> {
    try {
      const { isSignedIn, nextStep } = await signIn( { username, password });
      const user = await getCurrentUser();
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken;
      const subId = session.userSub;
      return { Token: token, SubId: subId };
    } catch (error) {
      this.loginFailed();
      throw error;
    }
  }
  get username() {
    return this.loginForm.get('Username');
  }

  get password() {
    return this.loginForm.get('Password');
  }

  rememberMe() {
    this.isRemembered = !this.isRemembered;
  }
  async sendForm() {
    this.isLoading = true;
    const authenticateObj = await this.authenticateUser({username: this.username.value, password: this.password.value});
    const token = authenticateObj.Token;
    const subId = authenticateObj.SubId;
    if (token != null) {
      this.superAdminService.LoginAdmin(subId).subscribe(() => {
        this.flagService.setSuperAdminFlag(true);
        this.flagService.setFlag(true);
        localStorage.setItem("roles", "SuperAdmin");
        localStorage.setItem("token", token);
        localStorage.setItem("user", this.username.value);
        localStorage.setItem("Tenant", "AtlasAdmin");
        localStorage.setItem("Name", "Atlas Internal");
        window.location.href = '/Tenants';
        this.isLoading = false;
      });
    }
  }
}

