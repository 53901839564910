<div class="Pop-Up-Box pop-up-box">
  <div class="Pop-Up-Header">
    <span (click)="onClose(false)" class="material-icons">highlight_off</span>
    <h1>Link Question</h1>
    <!-- <div class="Pop-Up-Edit-Button" >
			<button class="Edit-Button btn"  (click)="getEditUrl(false)">Edit</button>
		</div> -->
  </div>

  <hr class="HR">
  <div class="row">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Link Question</h5>

        <form [formGroup]="linkQuestionForm" (ngSubmit)="save()">
          <div class="row">
            <div class="position-relative mb-3 col-md-12">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Document</mat-label>
                <mat-select (selectionChange)="loadChapter()" formControlName="documentId"
                 [(ngModel)]="documentId" *ngIf="!addMultiSelect"
                  name="documentId">
                  <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
                    {{doc.name}}
                  </mat-option>
                </mat-select>

                <mat-select (focusout)="loadChapterForMultipleDocuments()" formControlName="documentId"
                [(ngModel)]="documnetIdList" *ngIf="addMultiSelect"
                 name="documentId" multiple>
                 <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
                   {{doc.name}}
                 </mat-option>
               </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="position-relative mb-3 col-md-12">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Chapter</mat-label>
                <mat-select [(ngModel)]="chaptersId" formControlName="chapterId" name="chaptersIds" *ngIf="!addMultiSelect"
                  (selectionChange)="loadControls()">
                  <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">{{chapter.name}}</mat-option>
                </mat-select>

                <mat-select [(ngModel)]="chaptersIdList" formControlName="chapterId" name="chaptersIds" *ngIf="addMultiSelect"
                (focusout)="loadControlsForMultipleChapters()" multiple>
                <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">{{chapter.name}}</mat-option>
              </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- controls type -->
          <div class="row">
            <div class="position-relative mb-3 col-md-12">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Controls</mat-label>
                <mat-select [(ngModel)]="controlId" formControlName="controlId" name="controlId" *ngIf="!addMultiSelect">
                  <mat-option *ngFor="let control of controllist" [value]="control.id">{{control.name}}</mat-option>
                </mat-select>

                <mat-select [(ngModel)]="controlIdList" formControlName="controlId" name="controlId" *ngIf="addMultiSelect" multiple>
                  <mat-option *ngFor="let control of controllist" [value]="control.id">{{control.name}}</mat-option>
                </mat-select>

              </mat-form-field>
            </div>
          </div>

          <button class="mt-1 btn btn-success" type="submit">Submit</button>
          <button class="mt-1 ml-2 btn btn-danger" (click)="onClose(false)" type="button">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</div>