<div class="Pop-Up-Box-RH pop-up-box-rh" *ngIf="revisionHistoryData">

    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"
                        style="margin-left: 6px;">
                        <path
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                </div>
                <div>
                    {{heading}}
                </div>
            </div>
            <div class="page-title-actions">
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas"
                    (click)="Edit()">
                    <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                    Edit
                </button>
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas ms-2"
                    (click)="onClose(false)">
                    <fa-icon [icon]="faWindowClose" class="me-2"></fa-icon>
                    Close
                </button>
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas ms-2"
                    (click)="printTableAsPDF()">
                    <fa-icon [icon]="faPrint" class="me-2"></fa-icon>
                    Print To Pdf
                </button>
                <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas ms-2"
                    (click)="saveTableAsPDF()">
                    <fa-icon [icon]="faSave" class="me-2"></fa-icon>
                    Save To Pdf
                </button>
                <div class="align-items-center inc-Draft">
                    <input type="checkbox" [(ngModel)]="isIncludeDraftChecked" (change)="includeDraft()">
                    <span class="ms-2">Include Draft</span>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="row">
            <div col-md-6>
                <p><strong>Version: </strong>{{revisionHistoryData.version}} <span>(Latest)</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p><strong>Categories: </strong>{{revisionHistoryData.category}}</p>
            </div>
            <div class="col-md-6">
                <p><strong>Status: </strong> {{revisionHistoryData.status}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <p><strong>Time/Date: </strong>{{ formatDate(revisionHistoryData.createdDate)}}</p>
            </div>
            <div class="col-md-6">
                <p><strong>Document Owner: </strong>{{revisionHistoryData.documentOwner}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <p><strong>Updated By: </strong>{{revisionHistoryData.updatedBy}}</p>
            </div>
        </div>
        <!-- <div>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success"
                style="float: right;" (click)="Toggle(revisionHistoryData)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Toggle
            </button>
        </div> -->
        <div s class="expandButton" (click)="getArrowIcon()">

            <mat-icon class="arrowIcon d-inline" *ngIf="!arrowClose">arrow_drop_down</mat-icon>
            <mat-icon class="arrowIcon d-inline" *ngIf="arrowClose">arrow_right</mat-icon>
            <h3 style="display: inline-block;"><strong> Revision History </strong></h3>
        </div>
        <div *ngIf="!arrowClose">
            <app-atlas-table [columns]="columns" [dataSource]="revisionHistoryData.revisionHistoryList"
                [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions" [hasDeleteIcon]="false"
                [hasPreviewIcon]="false" (VersionCompersion)="onVersionChange($event)"
                [onOpen]="onOpenDetails.bind(this)">
            </app-atlas-table>
        </div>

        <div>
            <h3> <strong>Description</strong></h3>
            <div [innerHTML]="getHtml(revisionHistoryData.descriptionHTMLFormate)" (click)="showControls($event)">
            </div>
        </div>
        <div>
            <h3><strong>Controls Associated With This Control Document </strong></h3>
            <ul *ngFor="let control of revisionHistoryData.associatedControls">
                <li>{{control.title}}</li>
            </ul>
        </div>
        <app-control-documents-details *ngIf="openDetails" [event]="selectedControlDocument"
            [onClose]="onCloseDetail.bind(this)"
            style="position: fixed;top: 15%;width: 100%;left: 10%; height:70vh; z-index: 1000000888;">
        </app-control-documents-details>
    </div>

</div>

<div *ngIf="!revisionHistoryData" class="login-spinner"
    style="position: fixed; top: 45%; left: 45%; z-index: 1000000889;">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%; z-index: 1000000889;">
    <mat-spinner></mat-spinner>
</div>

<app-compare-version-popup *ngIf="showComparisonPopup" [compareWith]="compareWith" [compareTo]="compareTo"
    [controldocumentId]="controlDocId" [onClose]="closeComparisonPopup.bind(this)"
    style=" z-index: 1000000;"></app-compare-version-popup>

<div #dropdown style="display:none;position: absolute; background-color: white;    z-index: 1000001;
border: 1px solid gray;
border-radius: 5px;">
    <button class="dropdown-item" type="button" (click)="ViewControl(LinkForLink)">View Liniked Controls</button>
    <!-- <button class="dropdown-item" type="button" (click)="EditLinkedControls(LinkForLink)">Edit Liniked Controls</button> -->
</div>

<!-- <app-app-control-details *ngIf="openDetails" [event]="controlDetails" [onClose]="onClose.bind(this)"
  style="position: fixed;top: 15%;width: 70%;z-index: 10000001;">
</app-app-control-details> -->

<div class="Pop-Up-Box-Control-List pop-up-box" *ngIf="showControlListPopUp"
    style="position: fixed;top: 15%;width: 70%;z-index: 10000001; height: unset; min-height: 100px;">
    <div class="Pop-Up-Header">
        <span (click)="closeControlListPopUp(false)" class="material-icons">highlight_off</span>
        <h1>Linked Controls</h1>
    </div>

    <hr class="HR">
    <div class="row mb-1" *ngFor="let control of linkedControlsList">
        <div class="col-md-9">
            <p>{{control.name}}</p>
        </div>
        <div class="col-md-3">
            <button class="btn btn-success btn-atlas" (click)="OpenControlDetails(control.id)">View Control</button>
        </div>
    </div>

</div>
<app-quill-control-list *ngIf="showControlListPopUpPopupLinked" [closePopup]="closePopup.bind(this)"
    [LinkControl]="controlsIds"></app-quill-control-list>
<!-- this component is for control  -->
<app-app-control-details *ngIf="openControlDetails" [event]="controlDetails" [onClose]="onCloseControlDetail.bind(this)"
    style="position: fixed;top: 15%;width: 70%; height:70vh; z-index: 1000001;">
</app-app-control-details>
<app-diff-viewer *ngIf="openDiffViewer" [closePopUp]="onCloseControlDetail.bind(this)" [mode]="'words'"
    [PreviouseHTML]="previouseHTML" [CurrentHTML]="CurrentHTML"
    style="position: fixed;top: 15%;width: 70%; height:70vh; z-index: 1000001;">
</app-diff-viewer>