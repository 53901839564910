<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Audits</h5>
                <form [formGroup]="auditsForm" (ngSubmit)="createAudits()" autocomplete="off">

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Select Customer</mat-label>
                            <mat-select formControlName="customerId">
                                <mat-option [value]="">None</mat-option>
                                <mat-option *ngFor="let food of customers" [value]="food.id">
                                    {{food.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div class="position-relative mb-3">
                        <label for="name" class="form-label">Audit Name</label>
                        <input name="name" id="name" formControlName="name" type="text" placeholder="Name"
                            class="form-control">
                    </div>

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Documents</mat-label>
                            <mat-select [(ngModel)]="documentsListId" [ngModelOptions]="{standalone: true}"
                                name="documentsListId" multiple style="background-color: none;" [disabled]="isReadOnly">
                                <mat-option *ngFor="let item of documentsList" [value]="item.id">
                                    {{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status"
                                name="status"  style="background-color: none;">
                                <mat-option *ngFor="let item of auditStatus" [value]="item">
                                    {{auditStatusValues[item]}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Choose a Start date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Choose a End date</mat-label>
                            <input matInput [matDatepicker]="picker1" placeholder="Choose a date"
                                formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1>
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <button type="submit" class="mt-1 btn btn-success">Submit</button>
                    <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>