import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenant-user-list',
  templateUrl: './tenant-user-list.component.html',
  styleUrls: ['./tenant-user-list.component.scss']
})
export class TenantUserListComponent implements OnInit {

  constructor() {
    console.log('======  TenantUserListComponent =======',)
  }

  ngOnInit(): void {
    console.log('======  TenantUserListComponent =======',)

  }

}
