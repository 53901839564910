import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { globalConstants } from "src/constants/global-constants";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AuditService } from "src/app/Services/audit-services";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { AuditStatus } from "src/app/Common/Enums/Atlas-Enums";
import { NotificationService } from "src/app/Services/notification.service";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { PageEvent } from "@angular/material/paginator";
import { FilterType } from "src/app/Shared/commen/Enums";
import { FilterService } from "src/app/Shared/Services/FilterService";
import { filter } from 'rxjs/operators'; // Import filter from rxjs/operators

@Component({
  selector: "app-audits",
  templateUrl: "./audits.component.html",
  styleUrls: [],
  providers: [HasPermissionPipe]
})
export class AuditsComponent implements OnInit {
  heading = "Audit";
  subheading = "List of Audits";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  isLoading = false;
  data = [];
  faPlus = faPlus;
  columns: Partial<Column>[] = [];
  route: any;
  filterTypeEnum: FilterType = FilterType.Audits;

  filterQuery: any = {};
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  pageSize: number = globalConstants.defaultPageSize;
  pageNumber: number = globalConstants.defaultPageNumber;
  totalItems: number;

  OpenAuditDetailsPopUp: boolean = false;
  auditDetails: any;
  filterListner: any;
  searchListner: any;
  favSaveListner: any;
  getEditUrl(Audit) {
    this.router.navigate(["/Audit/Edit/" + Audit.id]);
  }
  onDelete(audit) {
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this Record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.auditServices.delete(audit.id).subscribe((res) => {
          this.getAllAudit();
          Swal.fire("Deleted!", "Selected record has been deleted.", "success");
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
  constructor(
    private auditServices: AuditService,
    private router: Router,
    private datePipe: DatePipe,
    private notify: NotificationService,
    private activeRoute: ActivatedRoute,
    private filterService: FilterService,
    private hasPermissionPipe: HasPermissionPipe
  ) {

    this.filterListner = this.filterService.AuditFilterChangeSubject.subscribe(data => {
      if (data) {
        this.filterQuery = data;
        this.onFilter(this.filterQuery)
      }
    })
    this.searchListner = this.filterService.AuditSearchChangeSubject.subscribe(data => {
      if (data.isChanged) {
        this.filterQuery = { ...this.filterQuery, keyWords: data.keyWords };
        this.onFilter(this.filterQuery)
      }
    })

    this.favSaveListner = this.filterService.SaveFavoriteView.subscribe(data => {
      if (data.name && data.type) {
        this.saveFavoriteView(data.name, data.type);
      }
    })
  }
  saveFavoriteView(name, type) {
    this.filterService.saveAuditFavoriteView(this.filterQuery, name).subscribe(data => {
      this.filterService.CloseSignalFavorite.next(true);
    })
  }
  onFilter(filters) {
    this.isLoading = true;
    this.filterQuery.pageNumber = this.pageNumber = globalConstants.defaultPageNumber;
    this.getAllAudit()
  }
  ngOnDestroy() {
    if (this.filterListner) {
      this.filterListner.unsubscribe();
    }
    if (this.searchListner) {
      this.searchListner.unsubscribe();
    }
    if (this.favSaveListner) {
      this.favSaveListner.unsubscribe();
    }
    this.filterService.AuditFilterChangeSubject.next(null);
    this.filterService.AuditSearchChangeSubject.next({ isChanged: false, keyWords: null });
    this.filterService.SaveFavoriteView.next({ name: null, type: null });
    this.filterService.CloseSignalFavorite.next(false);
    this.filterService.updateFilters.next(this.filterQuery);
    this.filterService.clearFilterData(FilterType.Audits).subscribe(data => {
    })
  }
  initColumns() {
    this.columns = [
      {
        iconColumn: true,
        elementAttribute: "positionOpenIcon",
        columnWidth: "2%",
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
        elementAttribute: "positionDeleteIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
        elementAttribute: "positionEditIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
      },
      {
        label: "Customer",
        elementAttribute: "customer",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "200px",
      },
      {
        label: "Audit",
        elementAttribute: "name",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "200px",
      },
      {
        label: "Audit based on",
        elementAttribute: "document",
        columnClass: "matters-TypeWidth",
        columnWidth: "200px",
        formatFn: (document) => document.map((c) => c),
        isArray: true,
        isLink: false,
      },
      {
        label: "Scoring progress",
        elementAttribute: "progress",
        columnClass: "text-center",
        formatFn: (progress) => { return progress + "%" },
        isLink: false,
        columnWidth: "200px",

      },
      {
        label: "Audit progress",
        elementAttribute: "auditProgress",
        columnClass: "text-center",
        formatFn: (progress) => { return progress + "%" },
        isLink: false,
        columnWidth: "200px",

      },
      {
        label: "Start Date",
        elementAttribute: "startDate",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "200px",
        formatFn: (date: Date) => {
          let dateString = this.datePipe.transform(date, "MM/dd/yyyy");
          if (dateString) {
            return `${dateString[0]}${dateString[1]}/${dateString[3]}${dateString[4]}/${dateString[6]}${dateString[7]}${dateString[8]}${dateString[9]}`;
          } else {
            return "";
          }
        },
      },

      {
        label: "End Date",
        elementAttribute: "endDate",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "200px",
        formatFn: (date: Date) => {
          let dateString = this.datePipe.transform(date, "MM/dd/yyyy");
          if (dateString) {
            return `${dateString[0]}${dateString[1]}/${dateString[3]}${dateString[4]}/${dateString[6]}${dateString[7]}${dateString[8]}${dateString[9]}`;
          } else {
            return "";
          }
        },
      },
      {
        label: "Status",
        elementAttribute: "status",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "200px",
        formatFn: (status) => { return AuditStatus[status] }
      },
      {
        label: "Duplicate",
        isIconButtonColum: true,
        hasToolTip: true,
        isLink: false,
        elementAttribute: "duplicdate",
        columnClass: "matters-TypeWidth",
        toolTipText: "Duplicate Audit",
        tooltipPosition: "left",
        iconName: "file_copy",
        columnWidth: "200px",
      },
      {
        iconColumn: true,
        elementAttribute: "positionStartIcon",
        columnWidth: "2%",
      },
    ];
  }
  favoriteViews = [];
  ngOnInit(): void {
    this.route = this.activeRoute.snapshot;
    this.initColumns();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      console.log('NavigationEnd event detected:', event);
    });
    this.fetchDefaultConfigurations();

  }
  fetchDefaultConfigurations() {
    this.isLoading = true;
    this.filterService.getFavoriteViews(FilterType.AuditView).subscribe(data => {
      this.favoriteViews = data;
      var foundSelected = this.favoriteViews.find(d => d.isCurrent == true);
      if (foundSelected) {
        this.filterQuery = foundSelected.filters;
        this.filterService.updateFilters.next(this.filterQuery);
        this.filterService.save(this.filterQuery, FilterType[FilterType.Audits]).subscribe(data => { });
      }
      this.getAllAudit();
    })
  }
  controls = [];
  getAllAudit() {
    this.filterQuery = {
      ...this.filterQuery,
      pagingParamsDTO: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
    }
    this.auditServices.getAll(this.filterQuery).subscribe((Data) => {
      this.isLoading = false;
      this.data = Data.data;
      this.totalItems = Data.count;
    });
  }

  startAudit(event) {
    console.log(event);
    this.router.navigate(["/Audit/StartAudit/" + event.id]);
  }
  DuplicateAudit(event) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.auditServices.DuplicateAudit(event.id).subscribe(res => {
        this.isLoading = false;
        this.notify.showSuccess('Audit Duplicated successfully. Reloading', 'Duplicate Audit');
        this.getAllAudit();
      });
    }

  }

  OpenAuditDetails(event) {
    this.isLoading = true
    console.log(event)
    this.auditServices.get(event.id).subscribe(res => {
      console.log(res);
      this.auditDetails = res;
      this.isLoading = false;
      this.OpenAuditDetailsPopUp = true;
    },
      error => { this.isLoading = false; }
    )
  }

  onClose(isShow) {
    this.OpenAuditDetailsPopUp = isShow;
  }
  pageChangeHandler(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.previousPageIndex < event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.pageIndex + 1;
    } else if (event.previousPageIndex > event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.previousPageIndex;
    }
    this.isLoading = true;
    this.filterQuery = { ...this.filterQuery, pageNumber: this.pageNumber, pageSize: this.pageSize }
    this.getAllAudit();
  }
}
