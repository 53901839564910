import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import {
  MatDatepickerModule
} from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule, Routes } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PermissionAction, PermissionType } from "../Enum/permission-type";
import { PermissionsGuard } from "../Guard/permissions-guard";
import { SharedModule } from "../Shared/Shared.module";
import { CreateEditRolesComponent } from "./create-edit-roles/create-edit-roles.component";
import { CreateMenuComponent } from "./create-menu/create-menu.component";
import { CreatePermissionsComponent } from "./create-permissions/create-permissions.component";
import { CreateUsersComponent } from "./create-users/create-users.component";
import { RolesComponent } from "./roles/roles.component";
import { UsersComponent } from "./users/users.component";
import { TimezoneComponent } from './timezone/timezone.component';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { UserSettingComponent } from './user-setting/user-setting.component';
import { TenantListComponent } from './tenant/tenant-list/tenant-list.component';
import { CreateTenantComponent } from './tenant/create-tenant/create-tenant.component';
import { TenantUserCreateComponent } from './tenant/tenant-user-create/tenant-user-create.component';
import { TenantUserListComponent } from './tenant/tenant-user-list/tenant-user-list.component';
import { HasPermissionPipe } from "../Shared/pipes/HasPermissionPipe";

const routes: Routes = [
  {
    path: "Roles",
    component: RolesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Roles,
    },
  },
  {
    path: "create-role",
    component: CreateEditRolesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Roles,
      permissionAccess: PermissionAction.Create

    },
  },

  {
    path: "edit-role/:id",
    component: CreateEditRolesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Roles,
      permissionAccess: PermissionAction.Update

    },
  },
  {
    path: "Users",
    component: UsersComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Users,
    },
  },
  {
    path: "create-user",
    component: CreateUsersComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Users,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "create-user/:id",
    component: CreateUsersComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Users,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Permissions",
    component: CreatePermissionsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Permissions,
    },
  },
  {
    path: "create-menu",
    component: CreateMenuComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SideMenu,
      permissionAccess: PermissionAction.Create

    },
  },
  {
    path: "user-time",
    component: TimezoneComponent,
  }, {
    path: "user-setting",
    component: UserSettingComponent,
  },

  // {
  //   path : "tenant-list",
  //   component: TenantListComponent
  // }
];

@NgModule({
  declarations: [
    RolesComponent,
    CreateEditRolesComponent,
    UsersComponent,
    CreateUsersComponent,
    CreatePermissionsComponent,
    CreateMenuComponent,
    TimezoneComponent,
    UserSettingComponent,
    TenantListComponent,
    CreateTenantComponent,
    TenantUserCreateComponent,
    TenantUserListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    DragDropModule,
    MatCheckboxModule,
    
  ],
})
export class SettingsModule { }
