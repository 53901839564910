import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-detail-pop-up',
  templateUrl: './detail-pop-up.component.html',
  styleUrls: ['./detail-pop-up.component.scss']
})
export class DetailPopUpComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() HeaderText: string = "";
  @Output() closePopup = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
  closeFilterPopup(): void {
    this.isOpen = false;
    this.closePopup.emit();
  }
}
