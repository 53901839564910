<div *ngIf="showTenants">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon {{icon}}"></i>
        </div>
        <div>
          {{heading}}
          <div class="page-title-subheading">
            {{subheading}}
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <button type="button" style="margin-right: 5px;"
          class="btn-shadow d-inline-flex align-items-center btn btn-success" routerLink="/create-tenant">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Create New
        </button>
      </div>
    </div>
  </div>

  <div>
    <app-atlas-table [columns]="columns" [dataSource]="data" [onOpen]="ShowTenantUsers.bind(this)"
      [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions" [hasPreviewIcon]="true"
      [hasDeleteIcon]="true" [onEdit]="getTenantEditUrl.bind(this)" [onDelete]="onDelete.bind(this)">
    </app-atlas-table>
  </div>
</div>

<div *ngIf="showTenantUsers">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon {{icon}}"></i>
        </div>
        <div>
          {{tenantUsersHeading}}
          <div class="page-title-subheading">
            {{tenantUsersSubHeading}}
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <button type="button" style="margin-right: 5px;"
          class="btn-shadow d-inline-flex align-items-center btn btn-success"
          [queryParams]="{ tenantId: currentTenant }" routerLink="/create-tenant-user">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Create New
        </button>
        <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success"
          (click)="ShowTenants()">
          Back
        </button>
      </div>
    </div>
  </div>

  <div>
    <app-atlas-table [columns]="tenantUsersColumns" [dataSource]="tenantUsersData" [customColumnWidths]="true"
      [customPageSizeOptions]="customPageSizeOptions" [hasPreviewIcon]="true" [customColumnWidths]="true"
      [hasDeleteIcon]="true" [onOpen]="showUserDetail.bind(this)" [onEdit]="getEditUrl.bind(this)"
      [onDelete]="onDeleteUser.bind(this)">
      >
    </app-atlas-table>
  </div>
</div>

<div class="Pop-Up-Box pop-up-box" *ngIf="userDetailPopup">
  <div class="Pop-Up-Header">
    <span (click)="closeUserDetail(false)" class="material-icons">highlight_off</span>
    <h1>User Detail</h1>
    <div class="Pop-Up-Edit-Button">
      <button class="Edit-Button btn" (click)="getEditUrl(event)">Edit</button>
    </div>
  </div>

  <hr class="HR">

  <div class="container">
    <div class="Col-Lg-12 col-lg-12">
      <h4><strong>Role</strong></h4>
      <p>{{event.roleName}}</p>
    </div>
    <div class="Col-Lg-12 col-lg-12">
      <h4><strong>First Name</strong></h4>
      <p>{{event.firstName}}</p>
    </div>
    <div class="Col-Lg-12 col-lg-12">
      <h4><strong>Last Name</strong></h4>
      <p>{{event.lastName}}</p>
    </div>
    <div class="Col-Lg-12 col-lg-12">
      <h4><strong>Email</strong></h4>
      <p>{{event.userName}}</p>
    </div>
  </div>
</div>


<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>