<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <form [formGroup]="scfDomainForm" (ngSubmit)="createscfDomain()">
          <div class="position-relative mb-3">
            <label for="name" class="form-label">Name</label>
            <input name="name" formControlName="name" id="name" placeholder="SCF Domain" type="text"
              class="form-control">
          </div>
          <div class="position-relative mb-3">
            <label for="scfIdentifier" class="form-label">SCF Identifier</label>
            <input name="scfIdentifier" formControlName="scfIdentifier" id="scfIdentifier" placeholder="SCF Identifier"
              type="text" class="form-control">
          </div>

          <div class="position-relative mb-3">
            <label for="spdPrincipal" class="form-label">Security & Privacy by Design (S|P) Principles</label>
            <input name="spdPrincipal" formControlName="spdPrincipal" id="spdPrincipal"
              placeholder="Security & Privacy by Design (S|P) Principles" type="text" class="form-control">
          </div>

          <div class="position-relative mb-3">
            <label for="principalIntent" class="form-label">Principal Intent</label>
            <input name="principalIntent" formControlName="principalIntent" id="principalIntent"
              placeholder="Principal Intent" type="text" class="form-control">
          </div>

          <button class="mt-1 btn btn-success btn-atlas" type="submit">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel " type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>