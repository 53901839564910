
<ng-container *ngIf="isOpen" (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeFilterPopup();">
        <div class="pop-up-box" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header pt-3 pb-2">
                <h3 class="header-text">{{HeaderText}}</h3>
                <span class="spacer"></span>
                <svg (click)="closeFilterPopup()" class="cursor-pointer cross-button" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </div>
            <hr class="HR">
            <ng-container>
                <ng-content></ng-content>
            </ng-container>
        </div>
    </div>
</ng-container>