import { CommonModule, DatePipe } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CalendarModule } from "primeng/calendar";
import { QuestionDetailsComponent } from "../Audit/question-details/question-details.component";
import { DemoMaterialModule } from "../material-module";
import { AppControlDetailsComponent } from "./app-control-details/app-control-details.component";
import { AtlasFileUploadComponent } from "./atlas-file-upload/atlas-file-upload.component";
import { AtlasFilesListComponent } from "./atlas-files-list/atlas-files-list.component";
import { ControlsFilterComponent } from "./controls-filter/controls-filter.component";
import { DownloadFileDirective } from "./directives/download.file-directive";
import { AtlasTableComponent } from "./form-module/atlas-forms.component";
import { LinkQuestionComponent } from "./link-question/link-question.component";
import { SortingPopUpComponent } from "./sorting-pop-up/sorting-pop-up.component";
import { QuillControlListComponent } from './quill-control-list/quill-control-list.component';
import { CompareVersionPopupComponent } from './compare-version-popup/compare-version-popup.component';
import { MonacoEditorModule } from "ngx-monaco-editor";
import { AppCustomerDetailsComponent } from './app-customer-details/app-customer-details.component';
import { AppAuditDetailsComponent } from './app-audit-details/app-audit-details.component';
import { AtlasHtmlEditorComponent } from './atlas-html-editor/atlas-html-editor.component';
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { MatTableModule } from '@angular/material/table';
import { DiffViewerComponent } from './diff-viewer/diff-viewer.component';
import { HasPermissionPipe } from "./pipes/HasPermissionPipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FilterFunctionComponent } from './filter-function/filter-function.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FavoriteViewsComponent } from './favorite-views/favorite-views.component';
import { AppPopupComponent } from './app-popup/app-popup.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { TableSettingComponent } from './table-setting/table-setting.component';
import { AtlasInputComponent } from './atlas-input/atlas-input.component';
import { CheckBoxSelectionService, MultiSelectAllModule, DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";
import { DetailPopUpComponent } from './detail-pop-up/detail-pop-up.component';
import { DashboardUpcomingtasksComponent } from "../DemoPages/Dashboards/analytics/dashboard-upcomingtask/dashboard-upcomingtasks/dashboard-upcomingtasks.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { UserComplianceComponent } from "../DemoPages/Dashboards/user-compliance/user-compliance.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { QuestionFilterComponent } from "./question-filter/question-filter.component";

@NgModule({
    declarations: [
        AtlasTableComponent,
        AtlasFileUploadComponent,
        AtlasFilesListComponent,
        DownloadFileDirective,
        ControlsFilterComponent,
        QuestionDetailsComponent,
        SortingPopUpComponent,
        LinkQuestionComponent,
        AppControlDetailsComponent,
        QuillControlListComponent,
        CompareVersionPopupComponent,
        AppAuditDetailsComponent,
        AppCustomerDetailsComponent,
        AtlasHtmlEditorComponent,
        DiffViewerComponent,
        HasPermissionPipe,
        FilterFunctionComponent,
        FilterButtonComponent,
        FavoriteViewsComponent,
        AppPopupComponent,
        UserDetailsComponent,
        TableSettingComponent,
        AtlasInputComponent,
        DetailPopUpComponent,
        QuestionFilterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DemoMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDatepickerModule,
        MatInputModule,
        CalendarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        RichTextEditorAllModule,
        FontAwesomeModule,
        MonacoEditorModule.forRoot(),
        MatTableModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatRadioModule,
        MultiSelectAllModule,
        DropDownListModule
    ],
    exports: [
        AtlasTableComponent,
        AtlasFileUploadComponent,
        AtlasFilesListComponent,
        ControlsFilterComponent,
        QuestionDetailsComponent,
        SortingPopUpComponent,
        LinkQuestionComponent,
        AppControlDetailsComponent,
        CompareVersionPopupComponent,
        AppAuditDetailsComponent,
        AppCustomerDetailsComponent,
        MatMenuModule,
        MatRadioModule,
        AtlasHtmlEditorComponent,
        MatProgressSpinnerModule,
        DiffViewerComponent,
        HasPermissionPipe,
        FilterFunctionComponent,
        AppPopupComponent,
        UserDetailsComponent,
        TableSettingComponent,
        AtlasInputComponent,
        DetailPopUpComponent,
        QuestionFilterComponent,

    ],
    providers: [DatePipe, CheckBoxSelectionService],
    bootstrap: [FilterFunctionComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
