import { DatePipe, Location } from "@angular/common";
import { Component } from "@angular/core";
import {
  UntypedFormBuilder, UntypedFormGroup,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import FileInfo from "src/app/Common/models/files";
import { CategoriesService } from "src/app/Services/categoriesServices";
import { DocumentationService } from "src/app/Services/documentationServices";
import { FileService } from "src/app/Services/file.services";
import { NotificationService } from "src/app/Services/notification.service";

@Component({
  selector: "app-create-knowledge-data-base",
  templateUrl: "./create-knowledge-data-base.component.html",
  styleUrls: ["./create-knowledge-data-base.component.scss"],
})
export class CreateKnowledgeDataBaseComponent {
  documentForm: UntypedFormGroup;
  databaseId: string;
  CatName: string = "";
  isLoading = false;
  uploadedFileIds = [];
  uploadedFiles: FileInfo[] = [];
  selectedFile: any;
  description = "";
  addCat: boolean;
  lblDescription: string = "Description";
  constructor(
    private categoriesService: CategoriesService,
    private documentService: DocumentationService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private fileServices: FileService,
    private router: Router,
    private locationService: Location,
    private notify: NotificationService,
  ) {
    this.documentForm = this.formBuilder.group({
      name: ["", [Validators.required]],
    });
    this.route.params.subscribe((qp) => {
      let databaseId = qp.id;
      this.databaseId = databaseId;

      if (this.databaseId) {
        this.documentService
          .getKnowledgeDataBase(this.databaseId)
          .subscribe((data) => {
            this.documentForm.patchValue({
              name: data.document.name,
            });
            this.cats = data.document.categories.map((c) => c.id);
            this.description = data.document.description;
            this.uploadedFiles = data.files;
          });
      }
    });
    this.getCats();
  }
  getCats() {
    this.categoriesService.getAll().subscribe((result) => {
      this.categories = result;
    });
  }
  categories: any[] = [];
  descriptionChange($event) {
    this.description = $event;
  }


  MoveBack() {
    this.router.navigate(["/Documentation/KnowledgeDataBase"]);
  }

  isDeletable(): boolean {
    return true;
  }
  view(event) {
    console.log("");
  }

  onFileUpload(event: any) {
    const files = event.target.files;
    const fileLimit = 50;
    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        return;
      } else {
        this.isLoading = true;
        this.uploadFile(file);
      }
    }
  }
  uploadFile(file: any) {
    if (!this.databaseId) {
      this.fileServices.upload(file).subscribe((data) => {
        file.id = data;
        this.uploadedFiles.push(file);
        this.uploadedFileIds.push(data);
        this.isLoading = false;
      });
    } else {
      this.fileServices
        .uploadwithDataBase(file, this.databaseId)
        .subscribe((data) => {
          this.uploadedFileIds.push(data);
          file.id = data;
          this.uploadedFiles.push(file);
          this.isLoading = false;
        });
    }
  }
  deleteFile(file: FileInfo) {
    if (this.databaseId) {
      this.fileServices.delete(file.id, this.databaseId).subscribe((data) => {
        this.uploadedFiles.forEach((v, i) => {
          if (v.id == file.id) {
            this.uploadedFiles = this.uploadedFiles.splice(i, 1);

          }
        });
      });
    } else {
      this.fileServices.delete(file.id, "").subscribe((data) => {
        this.uploadedFiles.forEach((v, i) => {
          if (v.id == file.id) {
            this.uploadedFiles = this.uploadedFiles.splice(i, 1);

          }
        });
      });
    }
  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  cats = [];
  createDocument() {
    if (this.documentForm.invalid) {
      return;
    } else if (this.databaseId) {
      this.documentService
        .updateKnowledgeDataBase({
          name: this.documentForm.get("name").value,
          categoryIds: this.cats,
          id: this.databaseId,
          description: this.description,
        })
        .subscribe((data) => {
          this.notify.showInfo("Updated Successfully", "Knowledge Database");
          this.router.navigate(["/Documentation/KnowledgeDataBase"]);
        },
          (error) => {
            this.notify.showError("Error while updating", "Knowledge Database");
            this.router.navigate(["/Documentation/KnowledgeDataBase"]);
          });
    } else {
      this.documentService
        .createKnowledgeDataBase(
          {
            name: this.documentForm.get("name").value,
            categoryIds: this.cats,
            fileIds: this.uploadedFileIds,
            description: this.description,
          },
          this.selectedFile
        )
        .subscribe((data) => {
          this.notify.showSuccess("Created Successfully", "Knowledge Database");
          this.router.navigate(["/Documentation/KnowledgeDataBase"]);
        },
          (error) => {
            this.notify.showError("Error while creating", "Knowledge Database");
            this.router.navigate(["/Documentation/KnowledgeDataBase"]);
          });
    }

  }
  onCloseAdd() {
    this.addCat = false;
  }
  onOpenAdd() {
    this.addCat = true;
  }

  public onCreate(): void {
  }
  SaveCat() {
    this.categoriesService
      .create({
        name: this.CatName,
      })
      .subscribe((d) => {
        this.CatName = "";
        this.addCat = false;
        this.getCats();
      });
  }
}
