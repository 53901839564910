import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { TimeZoneIANAName } from 'src/app/Models/TImeZonesInAName';
import { ApplicationTimeZone } from 'src/app/Models/application-timezone';
import { DateRange } from 'src/app/Models/data-range';
import { UserServices } from 'src/app/Services/user-service';
import { UserService } from 'src/app/Services/user-services';

interface OffsetModel {
	hours: number;
	minutes: number;
}

@Injectable()
export class DateHelper {

	private DATE_FORMAT = 'YYYY-MM-DD';

	from: string;
	to: string;
	fromDateControl: UntypedFormControl;
	toDateControl: UntypedFormControl;

	constructor(private userService: UserService, private userServices: UserServices) {
	}

	getPermanentTimeZone(): ApplicationTimeZone {
		return this.userServices.getTimeZone();
	}

	initFromDateControl() {
		this.fromDateControl = new UntypedFormControl(this.getCurrentLocalDate());
	}

	initToDateControl() {
		this.toDateControl = new UntypedFormControl(this.getCurrentLocalDate());
	}

	getFromWithNoOffset() {
		return this.getStringWithNoOffset(this.from);
	}

	getToWithNoOffset() {
		return this.getStringWithNoOffset(this.to);
	}

	getStringWithNoOffset(offsetDate: string) {
		let offsetSymbolIndex = offsetDate.indexOf('GMT') + 3;
		const symbol = offsetDate.substring(offsetSymbolIndex, offsetSymbolIndex + 1);
		if (symbol === '+' || symbol === '-') {
			let result = offsetDate.substring(0, offsetSymbolIndex);
			result = result.concat('+00:00');
			return result;
		}
		return offsetDate;
	}

	//
	getWideRangeWithNoOffset(width: number = 1): DateRange {
		const from = new Date(this.getFromWithNoOffset());
		from.setDate(from.getDate() - width);
		const to = new Date(this.getToWithNoOffset());
		to.setDate(to.getDate() + width);
		const range: DateRange = { from: from, to: to }
		return range
	}

	setDates(from: string, to: string) {
		this.from = from;
		this.to = to;
		const dateFrom = this.getDatePortionOfDateTimeString(from);
		const dateTo = this.getDatePortionOfDateTimeString(to);
		this.fromDateControl.patchValue(dateFrom);
		this.toDateControl.patchValue(dateTo);
	}

	getTimeZoneIANAName(): string {
		const permanentTimeZone = this.userServices.getTimeZone();
		if (permanentTimeZone) {
			return TimeZoneIANAName[permanentTimeZone.abbreviation];
		} else {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
	}

	getCurrentLocalDateTime(): string {
		return this.getCurrentLocalMoment().format();
	}
	getCurrentDateTimeForTimeZone() {
		let permanentTimeZone = this.getPermanentTimeZone();
		let date = permanentTimeZone ? moment().tz(permanentTimeZone.zoneId).format('YYYY-MM-DD') : new Date();
		return date;
	}
	getCurrentLocalDate(): string {
		return this.getCurrentLocalMoment().format(this.DATE_FORMAT);
	}
	getCalenderLocalTime(): string {
		return this.getCurrentLocalMoment().format();
	}

	getCurrentLocalMoment(): moment.Moment {
		const permanentTimeZone = this.userServices.getTimeZone();
		if (permanentTimeZone) {
			return momentTz().tz(TimeZoneIANAName[permanentTimeZone.abbreviation], false);
		}
		return moment();
	}

	calculateDateOffset(date: Date): string {
		const dateMoment = moment(date);
		return momentTz(date.getTime() - dateMoment.utcOffset() * 60 * 1000).format();
	}

	getDateDiff(date1: string, date2: string) {
		return Math.ceil(Math.abs(+date1 - +date2) / (1000 * 60 * 60 * 24));
	}

	getDatePortionOfDateTimeString(dateTime: string) {
		return dateTime.substring(0, 10);
	}


	setOffset(start: string, end: string, offset: OffsetModel) {
		const startDate = (new Date(start));
		const startHours = startDate.getHours();
		const startMinutes = startDate.getMinutes();
		startDate.setHours(startHours + offset.hours);
		startDate.setMinutes(startMinutes + offset.minutes);
		const endDate = (new Date(end));
		const endHours = endDate.getHours();
		const endMinutes = endDate.getMinutes();
		if (!((endHours === 23) && (endMinutes === 59))) {
			endDate.setHours(endHours + offset.hours);
			endDate.setMinutes(endMinutes + offset.minutes);
		}

		return {
			start: startDate,
			end: endDate
		};
	}
	IsDayLightAddedDay(newDate, timeZoneP) {
		let date = new Date(newDate);
		let date2 = new Date(newDate);
		date2.setHours(12, 0, 0, 0);
		// Get the time zone offset for the given date
		let offset1 = moment(date).utcOffset();
		let offset3 = moment(date2).utcOffset();

		// Increment the date by one day and get the new offset
		date.setDate(date.getDate() - 1);
		date2.setDate(date2.getDate() - 1);
		let offset2 = moment(date).utcOffset();
		let offset4 = moment(date2).utcOffset();

		if (timeZoneP) {
			return (offset1 > offset2) && (offset3 > offset4) && moment(new Date(newDate)).tz(timeZoneP.zoneId).isDST()
		}
		else {
			return offset1 > offset2 && (offset3 > offset4) && moment(new Date(newDate)).isDST();
		}

	}
	IsDayLightRemovedDay(newDate, timeZoneP) {
		let date = new Date(newDate);
		let date2 = new Date(newDate);
		date2.setHours(12, 0, 0, 0);
		// Get the time zone offset for the given date
		let offset1 = moment(date).utcOffset();
		let offset3 = moment(date2).utcOffset();

		// Increment the date by one day and get the new offset
		date.setDate(date.getDate() - 1);
		date2.setDate(date2.getDate() - 1);
		let offset2 = moment(date).utcOffset();
		let offset4 = moment(date2).utcOffset();

		if (timeZoneP) {
			return (offset1 < offset2) && (offset3 < offset4) && !moment(new Date(newDate)).tz(timeZoneP.zoneId).isDST()
		}
		else {
			return offset1 < offset2 && (offset3 < offset4) && !moment(new Date(newDate)).isDST();
		}
	}
	getOffset(timeZone: ApplicationTimeZone, permanentTimeZone: ApplicationTimeZone, eventDate: string): OffsetModel {
		if (!timeZone) {
			return {
				hours: 0,
				minutes: 0
			};
		}
		const currentZoneOffset = permanentTimeZone ? permanentTimeZone.offset * 60 : moment(new Date(eventDate)).utcOffset();
		const currentDaylight = permanentTimeZone ? moment(new Date(eventDate)).tz(permanentTimeZone.zoneId).isDST() : moment(new Date(eventDate)).isDST();
		const isPermanentTimeZone = permanentTimeZone ? true : !moment(new Date(eventDate)).isDST();
		let minSum = this.getMinSum(currentZoneOffset, timeZone, currentDaylight, isPermanentTimeZone, eventDate);
		minSum += moment(new Date(eventDate)).utcOffset();
		return {
			hours: minSum / 60,
			minutes: minSum % 60
		};
	}
	getUTCTImeZone(): ApplicationTimeZone {
		return {
			alias: "UTC",
			displayName: "(UTC) Coordinated Universal Time",
			offset: 0,
			daylightSavings: false,
			abbreviation: 'UTC',
			zoneId: 'UTC',
			id: 1
		}
	}
	getOffsetForMassTransactions(timeZone: ApplicationTimeZone, permanentTimeZone: ApplicationTimeZone, eventDate: string): OffsetModel {
		if (!timeZone) {
			return {
				hours: 0,
				minutes: 0
			};
		}
		let IsSameZone = !permanentTimeZone && timeZone.zoneId == TimeZoneIANAName[moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneName()];
		if (!timeZone || (permanentTimeZone && timeZone.id == permanentTimeZone.id) || IsSameZone) {
			return {
				hours: 0,
				minutes: 0
			};
		}
		const currentZoneOffset = permanentTimeZone ? permanentTimeZone.offset * 60 : moment(new Date(eventDate)).utcOffset();
		const currentDaylight = permanentTimeZone ? moment.tz(eventDate, permanentTimeZone.zoneId).isDST() : moment(new Date(eventDate)).isDST();
		const isPermanentTimeZone = permanentTimeZone ? true : !moment(new Date(eventDate)).isDST();
		const minSum = this.getMinSum(currentZoneOffset, timeZone, currentDaylight, isPermanentTimeZone, eventDate);
		return {
			hours: minSum / 60,
			minutes: minSum % 60
		};
	}
	setOffsetCopy(start, end, offset) {
		let startDate = moment(start);
		let utcOffSet = startDate.utcOffset();
		let startHours = startDate.hours();
		let startMinutes = startDate.minutes();
		let hoursToSet = startHours + offset.hours;
		startDate.hours(hoursToSet);
		startDate.minutes(startMinutes + offset.minutes);
		let utcOffSet1 = startDate.utcOffset();
		if (utcOffSet != utcOffSet1 && utcOffSet > utcOffSet1) {
			startHours = startDate.hours();
			startHours = startHours - 1;
			startDate.hours(startHours);
		}
		else if (utcOffSet != utcOffSet1 && utcOffSet < utcOffSet1) {
			startHours = startDate.hours();
			startHours = startHours + 1;
			startDate.hours(startHours)
		}
		let endDate = moment(end);
		let endHours = endDate.hours();
		let endMinutes = endDate.minutes();

		if (!((endHours === 23) && (endMinutes === 59))) {
			let endHoursToSet = endHours + offset.hours;
			endDate.hours(endHoursToSet);
			endDate.minutes(endMinutes + offset.minutes);
		}
		return {
			start: startDate.toDate(),
			end: endDate.toDate()
		};
	}
	getMinSum(currentZoneOffset: number, timeZone: ApplicationTimeZone, currentZoneDayLight: boolean, isPermanentTimeZone, eventDate: string): number {
		const offset = timeZone.offset * 60;
		let minSum = -(offset - currentZoneOffset);
		if (isPermanentTimeZone) {
			if ((currentZoneDayLight && !moment.tz(eventDate, timeZone.zoneId).isDST())) {
				minSum += 60;
			}
			else if (moment.tz(eventDate, timeZone.zoneId).isDST() && !currentZoneDayLight) {
				minSum -= 60;
			}

		}
		else if (moment.tz(eventDate, timeZone.zoneId).isDST() && currentZoneDayLight) {
			minSum -= 60;
		}
		return minSum;
	}

}
