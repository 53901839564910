<div class="row">
  <div class="col-md-4">
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Document</mat-label>
      <mat-select (focusout)="loadChapterForMultipleDocuments()" [(ngModel)]="documnetIdList" name="documentId"
        multiple>
        <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
          {{doc.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Chapter</mat-label>
      <mat-select [(ngModel)]="chaptersIdList" name="chaptersIds" multiple>
        <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">{{chapter.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>



</div>
<div class="row">
  <div class="col-md-6 mt-1">
    <label style="padding-right: 10px;">Include Draft</label>
    <input type="checkbox" class="paddingleft" name="Include Draft" [(ngModel)]="isDraft" value="1">
    <label class="paddingleft">Include Minor</label>
    <input type="checkbox" name="Include Minor" class="paddingleft" [(ngModel)]="isMinor" value="2">
    <label class="paddingleft">Include Major</label>
    <input type="checkbox" name="Include Major" class="paddingleft" [(ngModel)]="isManjor" value="3">
  </div>
</div>
<div class="row mt-2">
  <div class="col-md-4">
    <button [disabled]="isButtonDisabled" class="btn btn-atlas" (click)="ShowControlDocuments()">Show Control
      Documents</button>
  </div>
</div>

<hr>

<app-atlas-table *ngIf="showControlDocumentsSection" [columns]="columns" [dataSource]="controlDocumentsList"
  [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions" [hasDeleteIcon]="false"
  [hasPreviewIcon]="false" [hasCustomIcon]="true" [onCustomIconClick]="showControlDocumentDetailPopup.bind(this)">
</app-atlas-table>

<div class="row">
  <div class="col-md-8">
    <app-app-control-details class="controlDetails" *ngIf="openControlDetails" [event]="controlDetails"
      [onClose]="onClose.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;">
    </app-app-control-details>

    <div *ngIf="openControlDetails" style="position: fixed;top: 15%;width: 100%;left: 10%;">
      <p>this is for testing purpouse</p>
    </div>
  </div>
</div>



<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>