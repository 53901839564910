import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { FormsSection, ROPAFileTypes } from "../Enum/permission-type";

@Injectable({
  providedIn: "root",
})
export class FileService {
  filePath = environment.apiUrl + "Files";

  constructor(private http: HttpClient) { }

  public downloadFile(url: string): Observable<HttpEvent<Blob>> {
    return this.http.request(
      new HttpRequest("GET", url, null, {
        reportProgress: true,
        responseType: "blob",
      })
    );
  }

  public downloadFileAsText(url: string): Observable<HttpEvent<string>> {
    return this.http.request(
      new HttpRequest("GET", url, null, {
        reportProgress: true,
        responseType: "text",
      })
    );
  }

  public getDocFile(url: string): Observable<HttpEvent<string>> {

    return this.http.get<any>(url);
  }
  public upload(file: any): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(this.filePath + "/Upload", formData);
  }
  public UploadForSection(file: any, Section: FormsSection, recordId: number = 0, ropaFileType: ROPAFileTypes = ROPAFileTypes.None): any {
    let formData = new FormData();
    formData.append("file", file);
    let url = '';
    if (Section === FormsSection.ROPA)
      url = `${this.filePath}/UploadForSection/${Section}/${recordId}/${ropaFileType}`;
    else if ((ropaFileType !== ROPAFileTypes.None || !ropaFileType) && recordId != 0)
      url = `${this.filePath}/UploadForSection/${Section}/${recordId}`;
    else
      url = `${this.filePath}/UploadForSection/${Section}`;

    return this.http.post<any>(url, formData);
  }
  public delete(fileId, docId = ""): any {
    if (docId == "") {
      return this.http.post<any>(this.filePath + "/Delete/" + fileId, {});
    } else {
      return this.http.post<any>(
        this.filePath + "/Delete/" + fileId + "/" + docId,
        {}
      );
    }
  }
  public deleteSectionFile(fileId, Section: FormsSection, recordId = null): any {
    if (!recordId) {
      return this.http.post<any>(this.filePath + "/DeleteForSection/" + Section + `/` + fileId, {});
    } else {
      return this.http.post<any>(
        this.filePath + "/DeleteForSection/" + fileId + "/" + recordId,
        {}
      );
    }
  }
  public DeleteAuditingControlAttachment(fileId): any {
    return this.http.post<any>(this.filePath + "/DeleteAuditingControlAttachment/" + fileId, {});
  }
  public uploadwithDoc(file: any, docId: any): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(this.filePath + "/Upload/" + docId, formData);
  }
  public uploadwithDataBase(file: any, databaseId: any): any {
    let formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(
      this.filePath + "/UploadWithDataBase/" + databaseId,
      formData
    );
  }

}
