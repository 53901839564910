import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { markFormGroupTouched } from 'src/app/Helpers/FormHelper';
import { InvitationService } from 'src/app/Services/invitation-service.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { UserService } from 'src/app/Services/user-services';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-acceptance',
  templateUrl: './user-acceptance.component.html',
  styleUrls: ['./user-acceptance.component.scss'],

})
export class UserAcceptanceComponent implements OnInit {

  userId: number;
  userForm: UntypedFormGroup;
  DOB?: string = null;
  password: string;
  cpassword: string;
  hasSpecialCharacter: boolean = false;
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumeric: boolean = false;
  displayPasswordValidation: boolean = false;
  roles: any;
  checkPassword: boolean = false;
  duplicateUser: boolean = false;
  userName: string;
  isLoading = false;
  isDisabled = true;
  isError: boolean = false;
  errorMessage: string;
  userData: any;
  token: any;
  existingUser: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private notify: NotificationService,
    private inivitationService: InvitationService,
    private userService: UserService,
  ) {

    this.userForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
      confirmPassword: [Validators.required],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      roleId: ["", [Validators.required]],
    });
    this.isLoading = true;
    this.route.params.subscribe((qp) => {
      this.token = qp.id;
      this.userForm = this.formBuilder.group({
        password: ["", [Validators.required]],
        confirmPassword: ["", [Validators.required]],
        email: ["", [Validators.required]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        roleId: ["", [Validators.required]],
      });
      this.inivitationService.CheckInvitation(this.token).subscribe(res => {
        if (res.isError) {
          this.isError = res.isError;
          this.errorMessage = res.errorMessage;
          this.isLoading = false;
        } else {
          let data = res.user;
          this.existingUser = res.existingUser;
          console.log(data)
          this.userForm.patchValue({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName
          });
          this.userForm.get('email').disable();
          this.fetchRoles(data.roleId);
          this.isLoading = false;
          if (this.existingUser) {
            this.userForm.get('password').clearValidators();
            this.userForm.get('password').updateValueAndValidity();
            this.userForm.get('confirmPassword').clearValidators();
            this.userForm.get('confirmPassword').updateValueAndValidity();
          }
        }

        this.isLoading = false;
      },
        (error) => {
          if (error.error = "You have already accepted the invitation") {
            this.duplicateUserPopUp(true);
          }
          this.notify.showError(error.error, "error")
          window.location.href = '/';
        })

    });
  }

  ngOnInit(): void {

  }
  MoveBack() {
    window.location.href = '/';
  }
  fetchRoles(id) {
    this.inivitationService.GetUserRole(id).subscribe((data) => {
      this.roles = data;
      this.userForm.get('roleId').setValue(data.id);
      this.userForm.get('roleId').disable();
    });
  }

  duplicateUserPopUp(isShow: boolean) {
    this.duplicateUser = isShow;
  }
  createUser() {
    if (this.checkPassword) {
      return;
    }
    if (this.userForm.invalid) {
      markFormGroupTouched(this.userForm)
      return
    }
    this.isLoading = true;
    this.inivitationService
      .create({
        password: this.userForm.get("password").value,
        firstName: this.userForm.get("firstName").value,
        lastName: this.userForm.get("lastName").value,
        token: this.token,
        RoleName: this.roles.name
      })
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.notify.showSuccess("IUser created successfully", "Invitation accepted!")
          const logoutUrl = environment.logoutUrl;
          window.location.href = logoutUrl;
        },
        (error) => {
          console.log(error)
          if (error.error) {

          }
        }
      );
  }
  setValidation() {
    this.displayPasswordValidation = true;
  }

  // Logic to check if the password meets each bullet point's criteria
  isPasswordLengthValid() {
    const password = this.userForm.controls.password.value;
    return password.length >= 12;
  }

  isPasswordSpecialCharactersValid() {
    const password = this.userForm.controls.password.value;
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  }

  isPasswordCaseValid() {
    const password = this.userForm.controls.password.value;
    return /[a-z]/.test(password) && /[A-Z]/.test(password);
  }

  isPasswordNumericValid() {
    const password = this.userForm.controls.password.value;
    return /[0-9]/.test(password);
  }
  checkValidation(event: any) {
    // Logic to validate the password on focusout
  }


  // Logic to check if all criteria are met for enabling the Next button
  areAllCriteriaMet() {
    return this.isPasswordLengthValid() && this.isPasswordSpecialCharactersValid() &&
      this.isPasswordCaseValid() && this.isPasswordNumericValid() &&
      this.userForm.get('password').value === this.userForm.get('confirmPassword').value;
  }

  ComparePassword(event) {
    this.cpassword = event.target.value;
    if (this.password !== this.cpassword) {
      this.checkPassword = true;
      return this.notify.showError(
        "",
        "Comfirm Password Does't Match With Password"
      );
    } else {
      this.checkPassword = false;
    }
  }

  showValidation() {
    this.displayPasswordValidation = true;
  }
}