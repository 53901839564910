<div class="app-page-title">

    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="p-4"
                style="border: 1px solid lightgray;min-width: 400px;height:100px ; border-radius: 5px;background-color: white;">
                <i class="icon {{icon}}">
                    <h1 class="" style="margin-bottom: 0px;padding-bottom: 0px;">
                        {{heading}} ({{controlsCount}})
                        <div class="page-title-subheading">
                        </div>
                    </h1>
                </i>
            </div>

        </div>
        <div class="page-title-actions">
            <button *ngIf="route | hasPermission:'canCreate'"  type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success"
                (click)="Navigate()">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>

<label>Select Document</label>
<select [formControl]="docu" class="form-control mb-4" (change)="changeDocument($event)" [(ngModel)]="documentId">
    <option *ngFor="let doc of documents" value="{{doc.id}}">{{doc.name}}</option>
</select>
<p-treeTable [value]="data" [columns]="cols" *ngIf="data !== undefined && controlsOpened==false ">
    <ng-template pTemplate="header" let-columns>
        <tr [ttRow]="rowNode">
            <th *ngFor="let col of columns" style="width: 600px;">
                {{col.header}}
            </th>
            <th>Action</th>
            <th>Count</th>
            <th>Description</th>
        </tr>
    </ng-template>
    <!-- <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                {{rowData[col.field]}}
            </td>
            <td>
                <i>
                	<mat-icon class="Delete-Icon material-icons delete-icon mt-1 blue-icon" matTooltip="Delete"
                          (click)="onDelete(rowData.Id)">delete
                     </mat-icon>
                </i>
             </td>
        </tr>
    </ng-template> -->
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{rowData.title}}
            </td>

            <td>
                <button style="border: none; background-color: transparent; color: inherit;"
                    (click)="onDelete(rowData.id)">
                    <i>
                        <mat-icon class="Delete-Icon material-icons delete-icon mt-1 blue-icon" matTooltip="Delete">
                            delete
                        </mat-icon>
                    </i>
                </button>
                <button style="border: none; background-color: transparent; color: inherit;" (click)="Edit(rowData.id)">
                    <i>
                        <mat-icon>edit</mat-icon>
                    </i>
                </button>

            </td>
            <td>
                <ng-container *ngIf="rowData.controlsCount > 0" style="color:green">
                    Controls ({{rowData.controlsCount}})
                    <button class="btn btn-success" (click)="getChapterControls(rowData.id,rowData.title)">View
                        Controls</button>
                </ng-container>
            </td>
            <td [innerHTML]="rowData.description"></td>
            <!-- <td>{{rowData.size}}</td>
            <td>{{rowData.type}}</td> -->
        </tr>
    </ng-template>
</p-treeTable>
<ng-container *ngIf="controlsOpened==true ">
    <p *ngIf="chapterControlsShow==true">{{ViewedChapter}}</p>
    <button class="btn btn-primary" (click)="closeChapterControls()"> Back</button>
    <app-atlas-table [columns]="columns" [dataSource]="controls" [customColumnWidths]="true"
        [customPageSizeOptions]="customPageSizeOptions" [hasPreviewIcon]="true" [onEdit]="getEditUrl.bind(this)"
        [onOpen]="OpenControlDetails.bind(this)">



    </app-atlas-table>
</ng-container>
<app-app-control-details *ngIf="openDetails" [event]="controlDetails" [onClose]="onClose.bind(this)"
    style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-app-control-details>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
    <mat-spinner></mat-spinner>
</div>