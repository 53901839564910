import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ControlService {
  constructor(private http: HttpClient) { }
  controlTypebasePath = environment.apiUrl + "Controls";
  getAll(): any {
    return this.http.get<any>(this.controlTypebasePath + "/All");
  }

  get(id): any {
    return this.http.get<any>(this.controlTypebasePath + "/Get/" + id);
  }
  All(body): any {
    return this.http.post<any>(this.controlTypebasePath + "/All", body);
  }

  ApplyFilters(controlfilters: any): any {
    return this.http.post<any>(
      this.controlTypebasePath + "/ApplyFilters",
      controlfilters
    );
  }
  AllForChapters(chId: any): any {
    return this.http.get<any>(
      this.controlTypebasePath + "/AllForChapters/" + chId
    );
  }
  create(body): any {
    return this.http.post<any>(this.controlTypebasePath + "/Create", body, body);
  }

  update(body): any {
    return this.http.post<any>(this.controlTypebasePath + "/Update", body);
  }

  delete(id): any {
    return this.http.delete<any>(this.controlTypebasePath + "/Delete/" + id);
  }

  getControlByChapterId(chId) {
    return this.http.get<any>(
      this.controlTypebasePath + "/GetControlsByChapterId/" + chId
    );
  }

  getControlForMultipleChapters(body): any {
    return this.http.post<any>(this.controlTypebasePath + "/GetControlsForMultipleChapters", body);
  }

  getControlsTitle(body): any {
    return this.http.post<any>(this.controlTypebasePath + "/GetControlsTitle", body);
  }
}
