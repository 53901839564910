import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  importbasePath = environment.apiUrl + "Import";
  constructor(private http: HttpClient) { }




  ImportFile(file: any): any {
    return this.http.post<any>(this.importbasePath + "/ImportExeclFile", file);
  }

  saveImportData(body: any): any {
    return this.http.post<any>(this.importbasePath + "/SaveImportData", body);
  }
  saveImportQuestions(body: any): any {
    return this.http.post<any>(this.importbasePath + "/SaveImportQuestions", body);
  }
  ImportQuestions(file: any): any {
    return this.http.post<any>(this.importbasePath + "/ImportQuestions", file);
  }
}


