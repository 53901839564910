import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { CisAssetTypeService } from "src/app/Services/CIS-AssetType.services";
import { CisLevelService } from "src/app/Services/cis-level.service";
import { ControlFunctions } from "src/app/Services/control-functions.service";
import { ControlTypeService } from "src/app/Services/control-type.service";
import { DocumentTypeService } from "src/app/Services/document-type.services";
import { DocumentService } from "src/app/Services/document.service";
import { InternalControlTypeService } from "src/app/Services/internal-Control-Type.service";
import { PrimaryObjectivesService } from "src/app/Services/primary-objectives.service";
import { ScfDomainsService } from "src/app/Services/scfDomains.service";
import { SuggestedControlService } from "src/app/Services/suggested-controls.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ListHelper } from "src/app/ListHelperCLasses/ListHelper";
import { ControlModel } from "src/app/Models/ControlModel";
import { ChapterService } from "src/app/Services/chapter.service";
import { ControlService } from "src/app/Services/control.service";
import { NotificationService } from "src/app/Services/notification.service";
import { SuggestedControlOwnershipService } from "src/app/Services/suggested-control-ownership.service";
import { TitleService } from "src/app/Services/title.service";

@Component({
  selector: "app-create-or-edit-control",
  templateUrl: "./create-or-edit-control.component.html",
  styles: [
    `
      .example-chip-list {
        width: 100%;
      }
    `,
  ],

  styleUrls: ["./create-or-edit-control.component.sass"],
})
export class CreateOrEditControlComponent implements OnInit {
  documentId: string;
  chapterId: string;
  chaptersList: ListHelper[] = [];
  objectives: string;
  auditingGuideLines: string;
  otherInformation: string;
  title: string;
  description: string;
  isLoading = false;
  control: ControlModel = new ControlModel();
  // document type arrays for chip list
  filterddocumentTypeList: Observable<string[]>;
  documentTypeList: string[] = [];
  addDocType: any = [];
  doctypelist: any = [];
  lblObjective: string = "Objective";
  lblDescription: string = "Description";
  lblOtherInformation: string = "Other Information";
  lblAuditingGuidelines: string = "Auditing Guidelines";

  // Control type arrays for chip list
  controlTypelist: any[] = [];

  // cis asset type arrays for chip list
  cisAssetTypelist: any[] = [];

  // cis level arrays for chip list
  cisLevellist: any[] = [];

  // control funcion arrays for chip input
  controlFunctionslist: any[] = [];

  // primary objective  arrays for chip input
  primaryObjectiveslist: any[] = [];

  // suggestedControls arrays for chip input
  suggestedControlslist: any[] = [];

  // suggestedControlOwnership arrays for chip input
  suggestedControlOwnershiplist: any[] = [];

  // scfDomainNamesList arrays for chip input
  scfDomainsList: any[] = [];

  // internal controls arrrays for chip input
  internalControllist: any[] = [];

  // Documents arrays for chip input
  documentlist: any[] = [];
  documentDisplaylist: any = [];
  documentNameslist: string[] = [];

  controlId: string;
  controlForm: UntypedFormGroup;

  //#region documentchip-inputlist
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // ng Model Arrays
  SelectedControlTypes = [];
  SelectedPrimaryObjectives = [];
  SelectedControlFunction = [];
  SelectedSuggestedControls = [];
  SelectedSuggestedControlOwnerShips = [];
  SelectedScfDomains = [];
  SelectedCISLevels = [];
  SelecteCISAssetsTypes = [];
  DocumentTypeFormControl = new UntypedFormControl("");
  @ViewChild("DocumentTypeInput")
  DocumentTypeInput: ElementRef<HTMLInputElement>;
  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.addDocType.push({
        id: 0,
        value: value,
      });
    }
    event.input.value = "";
    this.DocumentTypeFormControl.setValue(null);
  }

  remove(index: number): void {
    this.addDocType.splice(index, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let option = this.doctypelist.filter((x) =>
      x.name.toLowerCase().includes(event.option.viewValue.toLowerCase())
    );
    this.addDocType.push({
      id: option[0].id,
      value: option[0].name,
    });
    this.DocumentTypeInput.nativeElement.value = "";
    this.DocumentTypeFormControl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.documentTypeList.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  DocumentFormControl = new UntypedFormControl();
  @ViewChild("DocumentInput") DocumentInput: ElementRef<HTMLInputElement>;

  addDocument(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.documentDisplaylist.push({
        id: 0,
        value: value,
      });
    }
    event.input.value = "";
    this.DocumentFormControl.setValue(null);
  }
  onCreate(event) {

  }
  removeDocument(index: number): void {
    let valueToBeRemoved = this.documentDisplaylist[index].value;
    this.documentNameslist.push(valueToBeRemoved);
    this.documentDisplaylist.splice(index, 1);
  }

  selectedDocument(event: MatAutocompleteSelectedEvent): void {
    let option = this.documentlist.filter((x) =>
      x.name.toLowerCase().includes(event.option.viewValue.toLowerCase())
    );

    if (this.documentNameslist.length > 0) {
      this.documentNameslist.forEach((row, i) => {
        if (row.toLowerCase() == event.option.viewValue.toLowerCase()) {
          this.documentNameslist.splice(i, 1);
        }
      });
    }

    this.documentDisplaylist.push({
      id: option[0].id,
      value: option[0].name,
    });
    this.DocumentInput.nativeElement.value = "";
    this.DocumentFormControl.setValue(null);
  }

  private _filterDocument(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.documentNameslist.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }
  //#endregion

  //#endregion
  constructor(
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private controlTypeService: ControlTypeService,
    private cisAssetTypeService: CisAssetTypeService,
    private cisLeveService: CisLevelService,
    private controlFunctionService: ControlFunctions,
    private primayObjectiveService: PrimaryObjectivesService,
    private suggestedControlService: SuggestedControlService,
    private scfDomainService: ScfDomainsService,
    private internalControlService: InternalControlTypeService,
    private chapterService: ChapterService,
    private controlService: ControlService,
    private router: Router,
    private route: ActivatedRoute,
    private suggestedControlOwnershipService: SuggestedControlOwnershipService,
    private locationService: Location,
    private notify: NotificationService,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("New Control");
    this.titleService.setBackButtonUrl("/Dashboard/Setup/controls");
    this.route.params.subscribe((qp) => {
      this.controlId = qp.id;
      if (this.controlId) {
        this.titleService.setTitle("Edit Control");
        this.controlService.get(this.controlId).subscribe((data) => {
          console.log(data)
          this.title = data.title;
          this.description = data.description;
          this.objectives = data.objective;
          this.otherInformation = data.otherInformation;
          this.auditingGuideLines = data.auditingGuideLines;
          this.documentId = data.documentId;
          this.loadChapter();
          this.chapterId = data.chapterId;
          this.SelecteCISAssetsTypes = data.cisAssetTypeIds.map((d) => {
            return d.id;
          });
          this.SelectedCISLevels = data.cisLevelIds.map((d) => {
            return d.id;
          });
          this.SelectedControlFunction = data.controlFunctionIds.map((d) => {
            return d.id;
          });
          this.SelectedControlTypes = data.controlTypeIds.map((d) => {
            return d.id;
          });
          this.SelectedPrimaryObjectives = data.primaryObjectiveIds.map((d) => {
            return d.id;
          });
          // scfDomainIds
          this.SelectedScfDomains = data.scfDomainIds.map((d) => {
            return d.id;
          });
          this.SelectedSuggestedControls = data.suggestedControlIds.map((d) => {
            return d.id;
          });
          this.SelectedSuggestedControlOwnerShips = data.scoIds.map((d) => {
            return d.id;
          });

        });
      }
    });

    this.loadInitialData();
  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((data) => {
      if (data.get("docId")) {
        this.documentId = data.get("docId");
      }
    });
    if (this.documentId != null) {
      this.loadChapter();
    }
  }
  ngOnDestroy() {
    this.titleService.setTitle("");
    this.titleService.setBackButtonUrl("");
  }
  //load data for dropdowns
  loadInitialData() {
    this.isLoading = true;
    this.documentTypeService.getAllDocumentTypes().subscribe((result) => {
      this.doctypelist = result;
      this.documentTypeList = result.map((d) => d.name);
    });
    // document dropdown
    this.documentService.getList().subscribe((result) => {
      this.documentlist = result;
      this.documentNameslist = result.map((d) => d.name);
    });
    // control type ddp
    this.controlTypeService.getAll().subscribe((result) => {
      this.controlTypelist = result;
    });

    // cis asset type ddp
    this.cisAssetTypeService.getAll().subscribe((result) => {
      this.cisAssetTypelist = result;
    });

    // cis level ddp
    this.cisLeveService.getAllCISLevels().subscribe((result) => {
      this.cisLevellist = result;
    });

    this.controlFunctionService.getAll().subscribe((result) => {
      this.controlFunctionslist = result;
    });

    this.primayObjectiveService.getAll().subscribe((result) => {
      this.primaryObjectiveslist = result;
    });

    this.suggestedControlService.getAll().subscribe((result) => {
      this.suggestedControlslist = result;
    });

    this.suggestedControlOwnershipService.getAll().subscribe((result) => {
      this.suggestedControlOwnershiplist = result;
    });

    // scf domains ddp

    this.scfDomainService.getAll().subscribe((result) => {
      this.isLoading = false;
      this.scfDomainsList = result;
    });
  }

  createControl() {
    this.control.documentId = this.documentId;
    this.control.chapterId = this.chapterId;
    this.control.title = this.title;
    this.control.auditingGuideLines = this.auditingGuideLines;
    this.control.objective = this.objectives;
    this.control.otherInformation = this.otherInformation;
    this.control.cisAssetTypeIds = this.SelecteCISAssetsTypes;
    this.control.controlTypeIds = this.SelectedControlTypes;
    this.control.cisLevelIds = this.SelectedCISLevels;
    this.control.controlFunctionIds = this.SelectedControlFunction;
    this.control.PrimaryObjectiveIds = this.SelectedPrimaryObjectives;
    this.control.suggestedControlIds = this.SelectedSuggestedControls;
    this.control.scfDomainIds = this.SelectedScfDomains;
    this.control.internalControlIds = this.internalControllist;

    this.control.ScoIds = this.SelectedSuggestedControlOwnerShips;
    this.control.description = this.description;
    this.isLoading = true;
    if (this.controlId) {
      this.control.Id = this.controlId;
      this.controlService.update(this.control).subscribe(
        (res) => {
          this.isLoading = false;
          this.notify.showInfo("Update Control", "Successfully");
          this.router.navigate(["/Dashboard/Setup/controls"]);
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Update Control", "Failed");
        }
      );
    } else {
      this.controlService.create(this.control).subscribe(
        (res) => {
          this.isLoading = false;
          this.notify.showSuccess("Create Control", "Successfully created");
          this.router.navigate(["/Dashboard/Setup/controls"]);
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Create Control", "Failed");
        }
      );
    }
  }

  loadChapter() {
    this.chapterService.getLeafChilds(this.documentId).subscribe((res) => {
      this.chaptersList = res;
    });
  }

  MoveBack() {
    this.locationService.back();
  }
  createobjective(event: any) {
    let html = this.objectives;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }
  createdescription(event: any) {
    let html = this.description;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }
  createotherInformationChange(event: any) {
    let html = this.otherInformation;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }
  createauditingGuideLines(event: any) {
    let html = this.auditingGuideLines;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }

  objectiveChange($event) {
    this.objectives = $event;
  }
  descriptionChange($event) {
    this.description = $event;
  }
  otherInformationChange($event) {
    this.otherInformation = $event;
  }
  auditingGuideLinesChange($event) {
    this.auditingGuideLines = $event;
  }
}
