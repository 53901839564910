import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterService } from '../Services/FilterService';
import { FilterType } from '../commen/Enums';

@Component({
  selector: 'app-favorite-views',
  templateUrl: './favorite-views.component.html',
  styleUrls: ['./favorite-views.component.scss']
})
export class FavoriteViewsComponent implements OnInit {
  @Input() filterType: FilterType;
  @Input() favoriteFilters: any[] = ['Default', 'Filter 2', 'Filter 3'];
  activeFilterIndex: number = 0;
  addingNewFilter: boolean = false;
  newFilterName: string = '';
  favName: string = null;
  @Input() onSaveFilter: (name: string) => void;
  @Input() applyFavFilters: (filters: any) => void;
  isSaveOpen = false;
  loading: boolean = false;
  closeListener: any;
  constructor(private http: HttpClient,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.favName = null;
    // this.getFavoriteFilters();
    this.closeListener = this.filterService.CloseSignalFavorite.subscribe(data => {
      if (data) {
        this.isSaveOpen = false;
        this.loading = false;
        this.favName = null;
      }
    })

  }
  ngOnDestroy() {
    if (this.closeListener) {
      this.closeListener.unsubscribe();
    }
  }


  selectFilter(obj: any): void {
    var type = 0;
    if (this.filterType == FilterType.Audits) {
      type = FilterType.AuditView;
    }
    if (this.filterType == FilterType.ControlDocuments) {
      type = FilterType.ControlDocumentsView;
    }
    if (this.filterType == FilterType.DPA) {
      type = FilterType.DPAView;
    }
    if (this.filterType == FilterType.TPM) {
      type = FilterType.TPMView;
    }
    if (this.filterType == FilterType.Questions) {
      type = FilterType.QuestionsView;
    }
    if (this.filterType == FilterType.ROPA) {
      type = FilterType.ROPAView;
    }
    if (this.filterType == FilterType.DataRight) {
      type = FilterType.DataRightView;
    }
    if (this.filterType == FilterType.IncidentRegistry) {
      type = FilterType.IncidentRegistryView;
    }
    this.filterService.setSelected(type, obj.id).subscribe(data => {
      this.applyFavFilters(obj);
    })
  }

  deleteFilter(filter: any): void {
    if (filter.id) {
      this.filterService.delete(filter.id).subscribe(data => {
        this.filterService.CloseSignalFavorite.next(true);
      })
    }
  }

  showAddFilterInput(): void {
    this.addingNewFilter = true;
  }
  closeFilterPopup() {
    this.favName = null;
    this.isSaveOpen = false;
  }
  save(): void {
    if (this.favName.trim()) {
      this.loading = true;
      this.onSaveFilter(this.favName);
    }
  }
}
