import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from "@angular/router";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { PermissionType } from "../Enum/permission-type";
@Injectable({
  providedIn: "root",
})
export class PermissionsGuard implements CanActivate {
  status = false;
  permissions: { [key: number]: { canCreate: boolean, canUpdate: boolean, canDelete: boolean } } = {};
  constructor(private router: Router, private toasterService: ToastrService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let currentUrl: string = state.url;
    if (localStorage.getItem("roles") == "SuperAdmin") {
      this.navigateToLogin()
    }
    if (localStorage.getItem("permission")) {
      return this.checkPermissions(route, currentUrl);
    }
    else if (route.data.permissionType == PermissionType.None) {
      return true;
    } else {
      this.navigateToLogin()
    }
  }

  private navigateToLogin() {
    localStorage.clear();
    const logoutUrl = environment.logoutUrl;
    window.location.href = logoutUrl;
  }

  hasPermission(action: 'canCreate' | 'canUpdate' | 'canDelete', permissionType: number): boolean {
    const permissionsList = JSON.parse(localStorage.getItem("permissionObject") || "[]");
    const permission = permissionsList.find(x => x.type === permissionType);
    return permission && permission[action];
  }
  // hasPermission(action: 'canCreate' | 'canUpdate' | 'canDelete', permissionType: number): boolean {
  //   const permissionsList = JSON.parse(localStorage.getItem("permissionObject") || "[]");
  //   permissionsList.forEach(permission => {
  //     this.permissions[permission.type] = {
  //       canCreate: permission.canCreate,
  //       canUpdate: permission.canUpdate,
  //       canDelete: permission.canDelete
  //     };
  //   });
  //   const parsedPermissionsList: number[] = permissionsList.map(permission => permission.type);

  //   if (permissionType && parsedPermissionsList.includes(permissionType)) {
  //     return this.permissions[permissionType] && this.permissions[permissionType][action];
  //   } else {
  //     return false;
  //   }
  // }


  checkPermissions(route, currentUrl) {
    let permissionsList = localStorage
      .getItem("permission")
      .replace(/[[\]']+/g, "")
      .split(",");
    let permissionAccess = true;
    if (route.data.permissionAccess) {
      permissionAccess = this.hasPermission(route.data.permissionAccess, route.data.permissionType);
    }
    if ((route.data.permissionType !== PermissionType.None && !permissionsList.includes(route.data.permissionType.toString())) || !permissionAccess) {
      this.navigateToLogin();
      this.status = false;
    } else {
      this.status = true;
    }
    return this.status;
  }
}
