import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";



@Injectable({
    providedIn: "root"
})
export class DocumentTypeService {

    constructor(private http: HttpClient) { }
    docTypebasePath = environment.apiUrl + 'DocumentTypes'

    getAllDocumentTypes(): any {
        return this.http.get<any>(this.docTypebasePath + '/All')
    }

    getDocumentType(id) : any {
        return this.http.get<any>(this.docTypebasePath + '/Get/' + id);
    }

    create(body): any {
        return this.http.post<any>(this.docTypebasePath + '/Create', body);
    }

    update(body): any {
        return this.http.post<any>(this.docTypebasePath + '/Update', body);
    }

    delete(id): any {
        return this.http.delete<any>(this.docTypebasePath + '/Delete/'+id);
    }
}