<div>
  <div class="Pop-Up-Box-CV pop-up-box-cv">
    <div class="Pop-Up-Header">
      <span (click)="onClose()" class="material-icons">highlight_off</span>
      <h1>Audit Trails</h1>
    </div>

    <hr class="HR">
    <div class="row" [formGroup]="compareVersionForm">

      <div class="position-relative mb-3 col-md-6">
        <mat-form-field appearance="fill" style="width: 100%;" [ngClass]="{'mat-form-field-invalid': compareVersionForm.controls['compareVersionIdFirst'].invalid && compareVersionForm.controls['compareVersionIdFirst'].touched}">
          <mat-label>Control Document Version</mat-label>
          <mat-select formControlName="compareVersionIdFirst">
            <mat-option *ngFor="let catedocTyoe of AllAuditTrailsList" [value]="catedocTyoe.id">
              {{catedocTyoe.version}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="compareVersionForm.controls['compareVersionIdFirst'].invalid && compareVersionForm.controls['compareVersionIdFirst'].touched">
            Please select a version.
          </mat-error>
        </mat-form-field>
      </div>
      
      <div class="position-relative mb-3 col-md-6">
        <mat-form-field appearance="fill" style="width: 100%;" [ngClass]="{'mat-form-field-invalid': compareVersionForm.controls['compareVersionIdSecond'].invalid && compareVersionForm.controls['compareVersionIdSecond'].touched}">
          <mat-label>Control Document Version</mat-label>
          <mat-select formControlName="compareVersionIdSecond">
            <mat-option *ngFor="let catedocTyoe of AllAuditTrailsList" [value]="catedocTyoe.id">
              {{catedocTyoe.version}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="compareVersionForm.controls['compareVersionIdSecond'].invalid && compareVersionForm.controls['compareVersionIdSecond'].touched">
            Please select a version.
          </mat-error>
        </mat-form-field>
      </div>
      <button class="mt-1 btn btn-success btn-atlas" (click)="CompareVersion()">Compare</button>

    </div>

  </div>


  <div class="Pop-Up-Box-CV pop-up-box-cv" *ngIf="versionModelPopUp">
    <div class="Pop-Up-Header">
      <span (click)="versionModelPopUpShow(false)" class="material-icons">highlight_off</span>
      <h1>Compare</h1>

    </div>

    <hr class="HR">
    <div class="row">
      <div class="col-md-12">
        <form class="form-inline" role="form">

          <label for="theme" class="mb-2 me-sm-2">Theme:</label>
          <select id="theme" name="theme" class="form-control mb-2 me-sm-2" [(ngModel)]="selectedTheme"
            (change)="onChangeTheme($event.target.value)">
            <option *ngFor="let option of themes" [value]="option.value">{{option.name}}</option>
          </select>
          <div class="form-check mb-2 me-sm-2">
            <label class="form-check-label">
              <input class="form-check-input " type="checkbox" (change)="onChangeInline($event.target.checked)">
              Inline Diff
            </label>
          </div>
        </form>
      </div>
      <div *ngIf="isCompared" class="row">
        <div class="col-md-12 editor">
          <ngx-monaco-diff-editor id="diffeditor" [options]="diffOptions" [originalModel]="originalModel"
            [modifiedModel]="modifiedModel"></ngx-monaco-diff-editor>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 1000001;">
    <mat-spinner></mat-spinner>
  </div>

</div>
<div [innerHTML]="text1" #contentDiv></div>
<div [innerHTML]="text2" #contentDiv1></div>