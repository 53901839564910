import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
// import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserService } from './user-services';
import { toQueryString } from '../Common/Helpers/notification-helper';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private basePath = environment.apiUrl + 'Account/';

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    get(page: number, itemsPerPage: number): Observable<any> {
        return this.http.get(this.basePath + 'Notifications' + toQueryString({
            page,
            itemsPerPage
        }));
    }

    markAsSeen(body): Observable<any> {
        return this.http.post(this.basePath + `Notifications/MarkAsRead`, body);
    }

    getNotSeenCount(): Observable<number> {
        return this.http.get<number>(this.basePath + 'Notifications/New/Count');
    }

    markNotificationsAsSeen(): Observable<any> {
        return this.http.put(this.basePath + 'Notifications/MarkAsSeen', {});
    }

    getRelatedItem(itemType: string, id: any): Observable<any> {
        switch (itemType) {
            // case 'Event': {
            //     return this.calendarService.get(Number(id));
            // }
            // case 'Case': {
            //     return this.caseService.get(Number(id));
            // }
            // case 'MailItem': {
            //     return this.mailItemService.getById(Number(id));
            // }
            default: {
                return of(null);
            }
        }
    }
}