import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ControlFunctions } from "src/app/Services/control-functions.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-control-functions",
  templateUrl: "./control-functions.component.html",
  styleUrls: ["./control-functions.component.sass"],
  providers:[HasPermissionPipe]
})
export class ControlFunctionsComponent implements OnInit {
  heading = "Control Functions";
  subheading = "Functions of Control inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  constructor(
    private controlFunctionsService: ControlFunctions,
    private router: Router,
    private modalService: NgbModal,
    private hasPermissionPipe: HasPermissionPipe,
    private activeRoute: ActivatedRoute
  ) {}

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  controlTypeId: any;
  route: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  columns: Partial<Column>[] = [];

  
  initColumns() {
    this.columns = [
      {
        iconColumn: true,
        elementAttribute: "positionOpenIcon",
        columnWidth: "2%",
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
        elementAttribute: "positionDeleteIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
        elementAttribute: "positionEditIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
      },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];
  }
  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }

  getAll() {
    this.controlFunctionsService.getAll().subscribe((res) => {
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Dashboard/Setup/CreateControlFunctions/" + event.id,
    ]);
  }

  onDelete(event) {
    this.controlTypeId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.controlFunctionsService
          .delete(this.controlTypeId)
          .subscribe((data) => {
            this.getAll();
            this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.controlFunctionsService
      .delete(this.controlTypeId)
      .subscribe((data) => {
        this.getAll();
        this.modalService.dismissAll();
      });
  }
}
