import { Location } from '@angular/common';
import { Component, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTypeService } from 'src/app/Services/document-type.services';
import { NotificationService } from 'src/app/Services/notification.service';

@Component({
  selector: 'app-create-document-types',
  templateUrl: './create-document-types.component.html',
  styleUrls: ['./create-document-types.component.sass']
})
export class CreateDocumentTypesComponent {
  heading = 'Document Types';
  subheading = 'Document Types that you will be uploading inside system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  documentTypeForm: UntypedFormGroup;
  docTypeId: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private docTypeService: DocumentTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location
  ) {

    this.documentTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],



    });


    this.route.params.subscribe(qp => {
      let docTypeid = qp.id;
      this.docTypeId = docTypeid;

      if (this.docTypeId) {
        this.docTypeService.getDocumentType(this.docTypeId).subscribe(data => {
          this.documentTypeForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }


  MoveBack() {
    this.locationService.back();
  }

  createDocumentType() {
    if (this.documentTypeForm.invalid) {
      return
    }
    else if (this.docTypeId) {
      this.docTypeService.update({
        name: this.documentTypeForm.get('name').value,
        id: this.docTypeId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Document Type');
        this.router.navigate(['/Dashboard/Setup/documentTypes']);
      },
        error => {
          this.notify.showError('Error while updating', 'Document Type');
          this.router.navigate(['/Dashboard/Setup/documentTypes']);
        }
      )
    }
    else {
      this.docTypeService.create({
        name: this.documentTypeForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Document Type');
        this.router.navigate(['/Dashboard/Setup/documentTypes'])
      },
        error => {
          this.notify.showError('Error while creating', 'Document Type');
          this.router.navigate(['/Dashboard/Setup/documentTypes']);
        })
    }

  }
}
