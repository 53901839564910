import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification.service';
import { SuggestedControlService } from 'src/app/Services/suggested-controls.service';
import { TitleService } from 'src/app/Services/title.service';

@Component({
  selector: 'app-create-suggested-control',
  templateUrl: './create-suggested-control.component.html',
  styleUrls: ['./create-suggested-control.component.sass']
})
export class CreateSuggestedControlComponent {


  heading = 'Suggested Control';
  subheading = 'Suggested Controls of Control inside the system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  suggestedControlForm: UntypedFormGroup;
  suggestedControlId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private suggestedControlService: SuggestedControlService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location,
    private titleService: TitleService
  ) {
    this.titleService.setTitle('New Suggested Control');
    this.titleService.setBackButtonUrl('/Dashboard/Setup/suggestedControls');
    this.suggestedControlForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });


    this.route.params.subscribe(qp => {
      let suggestedControlid = qp.id;
      this.suggestedControlId = suggestedControlid;

      if (this.suggestedControlId) {
        this.titleService.setTitle('Edit Suggested Control');
        this.suggestedControlService.get(this.suggestedControlId).subscribe(data => {
          this.suggestedControlForm.patchValue({
            name: data.name,
          })
        })
      }
    })
  }

  MoveBack() {
    this.locationService.back();
  }

  createSuggestedControl() {
    if (this.suggestedControlForm.invalid) {
      return
    }
    else if (this.suggestedControlId) {
      this.suggestedControlService.update({
        name: this.suggestedControlForm.get('name').value,
        id: this.suggestedControlId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Suggested Control');
        this.router.navigate(['/Dashboard/Setup/suggestedControls']);
      },
        error => {
          this.notify.showError('Error while updating', 'Suggested Control');
          this.router.navigate(['/Dashboard/Setup/suggestedControls']);
        })
    }
    else {
      this.suggestedControlService.create({
        name: this.suggestedControlForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Suggested Control');
        this.router.navigate(['/Dashboard/Setup/suggestedControls'])
      },
        error => {
          this.notify.showError('Error while creating', 'Suggested Control');
          this.router.navigate(['/Dashboard/Setup/suggestedControls']);
        }
      )
    }

  }
  ngOnDestroy() {
    this.titleService.setTitle("");
    this.titleService.setBackButtonUrl('');
  }

}
