<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="modal-dialog w-100 mx-auto">
        <form [formGroup]="loginForm" (ngSubmit)="sendForm()" class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <img class="mb-3" src="./../../../../assets/images/Atlas_Button.png" height="80" width="80">
                <div>Welcome </div>
                <span>Please sign in to your account below.</span>
              </h4>
            </div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
              class="mb-3">
              <div>
                <input id="userName" formControlName="Username" type="email" placeholder="Enter email..."
                  required="required" aria-required="true" class="form-control"
                  aria-describedby="exampleInputGroup1__BV_description_">
              </div>
            </div>
            <div id="exampleInputGroup2" role="group" class="mb-3">
              <div><input id="passsword" formControlName="Password" type="password" placeholder="Enter password..."
                  required="required" aria-required="true" class="form-control" #passwordField>
              </div>
            </div>
            <small tabindex="-1" *ngIf="wrongCredentials" id="exampleInputGroup1__BV_description_"
              class=" mt-3 mb-4 text text-danger">Invalid Login
            </small>
            <div class="custom-control custom-checkbox form-check">
              <input type="checkbox" name="check" autocomplete="off" class="form-check-input" [value]="isRemembered"
                (change)="rememberMe()" id="rememberMe" formControlName="Remember">
              <label for="exampleCheck" class="form-label form-check-label">
                Keep me logged in
              </label>
            </div>
            <!-- <div class="divider"></div> -->
            <!-- <h6 class="mb-0">
              No account?
              <a [routerLink]="" class="text-primary">Sign up now</a>
            </h6> -->
          </div>
          <div class="modal-footer clearfix">
            <!-- <div class="float-start"><a [routerLink]="" class="btn-lg btn btn-link">Recover
                Password</a></div> -->
            <div class="float-end">
              <button type="submit" class="btn btn-success btn-lg">Login to Dashboard</button>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Atlas {{currentYear}}
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>