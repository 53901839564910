<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Menu</h5>
                <form [formGroup]="MenuForm" (ngSubmit)="submitChildMenu()" autocomplete="off">

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Select Parent Menu <span class="ml-1"> *</span></mat-label>
                            <mat-select formControlName="parentMenuId" (selectionChange)="ParentMenuChange($event)">
                                <mat-option *ngFor="let item of menus" [value]="item.menuId">
                                    {{item.name}}
                                    <span class="ml-auto" style="text-align: right;">
                                        <mat-icon style="cursor: pointer;" class=" edit" (click)="setParentMenu(item.menuId)">edit
                                        </mat-icon>
                                        <mat-icon style="cursor: pointer;" class="delete" (click)="onOpenDeleteMenuModel(item.menuId,true)">delete
                                        </mat-icon>
                                    </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <p class="btn btn-primary" (click)="onOpenAdd()">
                            <mat-icon style="vertical-align:middle;font: size 19px;">add</mat-icon>
                            Add Parent Menu
                        </p>
                        <br>
                        <small *ngIf="submitForm &&  MenuForm.invalid" class="text text-danger">Required</small>
                        <br>
                        <div class=" mb-3">
                            <label for="name" class="form-label">Name<span class="ml-1"> *</span></label>
                            <input name="name" id="name" formControlName="name" type="text" placeholder="Name"
                                class="form-control">
                            <small *ngIf="submitForm && MenuForm.invalid" class="text text-danger">Required</small>
                        </div>
                        <br>
                        <div class=" mb-3">
                            <label for="route" class="form-label">URL<small class="ml-2">  (Example:/Main-Manu/Sub-Menu)</small><span class="ml-1"> *</span></label>
                            <input name="route" id="route" formControlName="route" type="text" placeholder="/Main-Manu/Sub-Menu"
                                class="form-control">
                            <small *ngIf="submitForm && MenuForm.invalid" class="text text-danger">Required</small>
                        </div>
                        <div class=" mb-3">
                            <label for="route" class="form-label">Icon</label>
                            <input name="route" id="route" formControlName="icon" type="text" placeholder="Icon"
                                class="form-control">
                        </div>
                        <br>
                        <!-- <label *ngIf="selectedMenu.length==0" class="text text-danger">Must Select Menu Item</label> -->
                        <br>
                    </div>

                    <button type="submit" [disabled]="isLoading"
                        class="mt-1 btn btn-success">{{this.MenuForm.get("menuId").value && this.MenuForm.get("menuId").value != ""?'Update':'Save'}}</button>
                    <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="resetChildMenuForm()">Clear</button>
                    <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>
                </form>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Menu Order</h5>
                <div cdkDropList class="example-list" (cdkDropListDropped)="dropMenu($event)">
                    <div class="example-box" *ngFor="let item of chiledMenus" cdkDrag>{{item?.name}}
                        <span class="ml-auto">
                            <mat-icon style="cursor: pointer;" class="edit" (click)="setChildMenuValues(item.menuId)">
                                edit
                            </mat-icon>
                            <mat-icon style="cursor: pointer;" class="delete" (click)="onOpenDeleteMenuModel(item.menuId)">delete
                            </mat-icon>
                        </span>
                    </div>
                </div>
                <button type="bitton" [disabled]="isLoading" class="mt-1 btn btn-success"
                    (click)="updateMenuOrder()">Update</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>
<div class="Pop-Up-Box pop-up-box" *ngIf="addCat">
    <div class="Pop-Up-Header">
        <span (click)="onCloseAdd(false)" class="material-icons">highlight_off</span>
        <h1>{{this.parentMenuId != "" ? 'Update':'Add'}} Parent Menu</h1>
    </div>

    <hr class="HR">
    <div class="row">
        <div class="col-sm-6">
            <input name="name" [(ngModel)]="parentMenuName" id="name" placeholder="Name" type="text"
                class="form-control">
            <small class="text text-danger">Required</small>
        </div>
        <div class="col-sm-6">
            <input name="name" [(ngModel)]="parentMenuIcon" id="name" placeholder="Icon" type="text"
                class="form-control">
        </div>
    </div>
    <p class="mt-2">
        <button class="btn btn-success" (click)="submitPatentMenu()" type="button">{{this.parentMenuId != "" ? 'Update':'Save'}}</button>
    </p>
</div>

<div class="Pop-Up-Box pop-up-box" *ngIf="deleteChildConfirmation">
    <div class="Pop-Up-Header">
        <span (click)="onCloseDeleteMenuModel(false)" class="material-icons">highlight_off</span>
        <h1>confirmation</h1>
    </div>

    <hr class="HR">
    <div class="row">
       <label>Are you sure delete menu</label>
    </div>
    <p class="mt-2">
        <button class="btn btn-success" (click)="onDeleteMenu(this.selectedChalidMenuId)" type="button">Yes</button>
        <button class="btn btn-success" (click)="onCloseDeleteMenuModel(false)" type="button">No</button>
    </p>
</div>