<div class="row" *ngIf="!IsPreviewDataLoaded">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Import Documents</h5>
        <form (ngSubmit)="createDocument()" enctype="multipart/form-data">
          <div class="position-relative mb-3">
            <app-atlas-files-list [filesList]="uploadedFiles" [visible]="false" [visibleView]="false"
              [visibleDownload]="false" [visibleLink]="false" [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
              [isDeletable]="isDeletable.bind(this)"></app-atlas-files-list>
            <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"></app-atlas-file-upload>
          </div>
          <button class="mt-1 btn btn-success">Submit</button>
          <button class="mt-1 ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="IsPreviewDataLoaded">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon {{icon}}"></i>
        </div>
        <div>
          {{heading}}
          <div class="page-title-subheading">
            {{subheading}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="table-container custom-table-container">
      <table mat-table [dataSource]="dataSource" class="custom-table">
        <ng-container *ngFor="let column of displayedColumns; let i = index">
          <ng-container matColumnDef="{{ column }}">
            <th mat-header-cell *matHeaderCellDef class="header-cell"
              style="text-align: center; margin-right: 5px; min-width: 300px;">{{
              getHumanReadableColumnName(i) }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="!column.includes('dataLists')">
                <div class="p-2">
                  <textarea type="text" [(ngModel)]="element[column]" class="input-field form-control"
                    style="text-align: center; margin-right: 5px;"></textarea>
                </div>
              </ng-container>
              <ng-container *ngIf="column.includes('dataLists')">
                <div class="p-2">
                  <textarea type="text" [(ngModel)]="element[column]" class="input-field form-control"
                    style="text-align: center; margin-right: 5px;"></textarea>
                </div>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="first-row-after-header"></tr>
      </table>
    </div>

    <div class="button-container">
      <button [disabled]="isLoading || submitted" class="btn btn-success ml-2" mat-button
        (click)="saveData()">Save</button>
      <button class="ml-2 btn btn-warning" type="button" (click)="MoveBack()">Cancel</button>
    </div>

  </div>
</div>