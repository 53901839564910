import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesServices } from 'src/app/Services/roles-services';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.sass'],
  providers: [
    HasPermissionPipe,
  ],
})
export class RolesComponent implements OnInit {
  isLoading: boolean = false;
  route :any;
  columns: Partial<Column>[] = [];

  constructor(private roleServices: RolesServices, private router: Router,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private modalService: NgbModal) {
  }
  closeResult: string;

  initColumns() {
    this.columns = [
      {
        iconColumn: true,
        elementAttribute: "positionOpenIcon",
        columnWidth: '2%',
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
        elementAttribute: "positionDeleteIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
        elementAttribute: "positionEditIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
      },
      {
        label: 'Role Name',
        elementAttribute: 'name',
        columnClass: 'matters-TypeWidth',
        isLink: false,
        columnWidth: '200px',
      },
    ];
  }

  roles = [];
  faStar = faStar;
  faPlus = faPlus;
  roleid: any;
  heading = 'Roles';
  subheading = 'Roles Inside System';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  ngOnInit(): void {
    this.fetchRoles();
    this.route = this.activeRoute.snapshot;
    this.initColumns();

  }
  rolesListPreview(event: any) {
    console.log("");
  }
  fetchRoles() {
    this.isLoading = true;
    return this.roleServices.getAllRoles().subscribe(data => {
      this.roles = data
      this.isLoading = false;

    })
  }
  getEditUrl(event: any) {
    this.router.navigate(['/Settings/edit-role/' + event.id])
  }
  onDelete(event) {
    this.roleid = event.id;
    // (document.getElementById('btnDelete') as HTMLInputElement).click();
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.roleServices.delete({
          id: this.roleid
        }).subscribe((data) => {
          this.fetchRoles();
          this.modalService.dismissAll();
          Swal.fire("Deleted!", "Selected record has been deleted.", "success");
        }, (error) => {
          if (error.error) {
            if (
              error.error.detail.includes("RoleInUse")) {
              Swal.fire("Cancelled", "Role is already in use", "error");
            }
          }
          else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your record is safe :)", "error");
          }
        })
      }
    });
  }


  // open(content) {
  //   this.modalService.open(content).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  // delete(){
  //   this.roleServices.delete({
  //     id:this.roleid
  //   }).subscribe(data=>{
  //     this.fetchRoles();
  //     this.modalService.dismissAll();
  //   })
  // }
}
