import { DatePipe, Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import FileInfo from "src/app/Common/models/files";
import { markFormGroupTouched } from "src/app/Helpers/FormHelper";
import { DocumentTypeService } from "src/app/Services/document-type.services";
import { DocumentService } from "src/app/Services/document.service";
import { FileService } from "src/app/Services/file.services";
import { TitleService } from "src/app/Services/title.service";

@Component({
  selector: "app-create-document",
  templateUrl: "./create-document.component.html",
  styleUrls: ["./create-document.component.sass"],
})
export class CreateDocumentComponent {
  documentForm: UntypedFormGroup;
  documentId: string;
  isLoading = false;
  uploadedFileIds = [];
  uploadedFiles: FileInfo[] = [];
  selectedFile: any;
  constructor(
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private fileServices: FileService,
    private router: Router,
    private locationService: Location,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("Create Document");
    this.titleService.setBackButtonUrl("/Dashboard/Setup/documents");
    this.documentForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      documentType: ["", [Validators.required]],
      documentFile: [""],
      releaseDate: ["", [Validators.required]],
      notes: [""],
    });

    this.route.params.subscribe((qp) => {
      let documentid = qp.id;
      this.documentId = documentid;

      if (this.documentId) {
        this.titleService.setTitle("Edit Document");
        this.documentService.get(this.documentId).subscribe((data) => {
          this.documentForm.patchValue({
            name: data.document.name,
            releaseDate: this.datePipe.transform(
              data.document.releaseDate,
              "yyyy-MM-dd"
            ),
            documentType: data.document.documentTypeId,
            notes: data.document.notes
          });
          this.uploadedFiles = data.files;
        });
      }
    });

    this.documentTypeService.getAllDocumentTypes().subscribe((result) => {
      this.documentTypeList = result;
    });
  }

  documentTypeList: any[] = [];


  MoveBack() {
    this.locationService.back();
  }

  isDeletable(): boolean {
    return true;
  }
  view(event) {
    console.log("");
  }

  onFileUpload(event: any) {
    const files = event.target.files;
    const fileLimit = 50;
    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        return;
      } else {
        this.isLoading = true;
        this.uploadFile(file);
      }
    }
  }
  uploadFile(file: any) {
    if (!this.documentId) {
      this.fileServices.upload(file).subscribe((data) => {
        file.id = data;
        this.uploadedFiles.push(file);
        this.uploadedFileIds.push(data);
        this.isLoading = false;
      });
    } else {
      this.fileServices
        .uploadwithDoc(file, this.documentId)
        .subscribe((data) => {
          this.uploadedFileIds.push(data);
          file.id = data;
          this.uploadedFiles.push(file);
          this.isLoading = false;
        });
    }
  }
  deleteFile(file: FileInfo) {
    if (this.documentId) {
      this.fileServices.delete(file.id, this.documentId).subscribe((data) => {
        this.uploadedFiles.forEach((v, i) => {
          if (v.id == file.id) {
            this.uploadedFiles = this.uploadedFiles.splice(i, 1);
          }
        });
      });
    } else {
      this.fileServices.delete(file.id, "").subscribe((data) => {
        this.uploadedFiles.forEach((v, i) => {
          if (v.id == file.id) {
            this.uploadedFiles = this.uploadedFiles.splice(i, 1);
          }
        });
      });
    }
  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  createDocument() {
    if (this.documentForm.invalid) {
      markFormGroupTouched(this.documentForm);
    } else {
      this.isLoading = true;
      if (this.documentId) {
        this.documentService
          .update({
            name: this.documentForm.get("name").value,
            releaseDate: this.documentForm.get("releaseDate").value,
            documentTypeId: this.documentForm.get("documentType").value,
            id: this.documentId,
            notes: this.documentForm.get("notes").value,
          })
          .subscribe((data) => {
            this.isLoading = true;
            this.router.navigate(["/Dashboard/Setup/documents"]);
          });
      } else {
        this.documentService
          .create(
            {
              name: this.documentForm.get("name").value,
              releaseDate: this.documentForm.get("releaseDate").value,
              documentTypeId: this.documentForm.get("documentType").value,
              fileIds: this.uploadedFileIds,
              notes: this.documentForm.get("notes").value,
            },
            this.selectedFile
          )
          .subscribe((data) => {
            this.isLoading = true;
            this.router.navigate(["/Dashboard/Setup/documents"]);
          });
      }
    }
  }
  ngOnDestroy() {
    this.titleService.setBackButtonUrl("");
    this.titleService.setTitle("");
  }
}
