
<ng-container (click)="$event.stopPropagation()">
  <div class="popup-overlay" (click)="onClose(false)">
      <div class="pop-up-box ps-3 pe-3 pb-4" (click)="$event.stopPropagation()">
          <div class="Pop-Up-Header  pt-4 ">
            <h1>Link Question</h1>
            <span class="spacer"></span>
            <svg (click)="onClose(false)" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          </div>
          <hr class="HR">
          <div class="row">
                <form [formGroup]="linkQuestionForm">
                  <div class="row">
                    <div class="position-relative mb-3 col-md-12">
                      <app-atlas-input *ngIf="!addMultiSelect" controlName="documentId" placeholder="Select Document" label="Document" [Options]="documentlist"
                        [onChange]="loadChapter.bind(this)" type="selectSingle" [removeFirstOption]="true">
                      </app-atlas-input>
                      <mat-form-field appearance="fill" style="width: 100%;" *ngIf="addMultiSelect">
                        <mat-label>Document</mat-label>
                        <mat-select (focusout)="loadChapterForMultipleDocuments()" formControlName="documentId"
                        [(ngModel)]="documnetIdList" 
                         name="documentId" multiple>
                         <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
                           {{doc.name}}
                         </mat-option>
                       </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="position-relative mb-3 col-md-12">
                      <app-atlas-input *ngIf="!addMultiSelect" controlName="chapterId" placeholder="Select Chapter" label="Chapter" [Options]="chaptersList"
                              [onChange]="loadControls.bind(this)" type="selectSingle">
                       </app-atlas-input>
                      <mat-form-field appearance="fill" style="width: 100%;" *ngIf="addMultiSelect">
                        <mat-label>Chapter</mat-label>
                        <mat-select [(ngModel)]="chaptersIdList" formControlName="chapterId" name="chaptersIds" 
                        (focusout)="loadControlsForMultipleChapters()" multiple>
                        <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">{{chapter.name}}</mat-option>
                      </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- controls type -->
                  <div class="row">
                    <div class="position-relative mb-3 col-md-12">
                      <app-atlas-input *ngIf="!addMultiSelect" controlName="controlId" placeholder="Select Control" label="Controls" [Options]="controllist"
                          type="selectSingle">
                      </app-atlas-input>
                      <mat-form-field appearance="fill" style="width: 100%;" *ngIf="addMultiSelect">
                        <mat-label>Controls</mat-label>
                        <!-- <mat-select [(ngModel)]="controlId" formControlName="controlId" name="controlId" >
                          <mat-option *ngFor="let control of controllist" [value]="control.id">{{control.name}}</mat-option>
                        </mat-select> -->
        
                        <mat-select [(ngModel)]="controlIdList" formControlName="controlId" name="controlId"  multiple>
                          <mat-option *ngFor="let control of controllist" [value]="control.id">{{control.name}}</mat-option>
                        </mat-select>
        
                      </mat-form-field>
                    </div>
                  </div>
                  <ng-container>
                    <div class="buttons d-flex ">
                        <button class="btn btn-atlas-cancel btn-table-settings-reset" mat-button (click)="onClose(false)">Cancel</button>
                        <button class="btn btn-success btn-atlas ms-auto btn-table-settings-Apply" mat-button (click)="save()">Submit</button>
                    </div>
                </ng-container>
                </form>
          </div>
      </div>
  </div>
</ng-container>